import { YMaps } from '@pbe/react-yandex-maps';
import type { FC } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StepWizardForm } from '@src/features';
import { useResetFlatSteps } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { useAnalyticsPets } from './hooks';
import { PetsAdditionalRisks } from './pets-additional-risks';
import { PetsData } from './pets-data';
import { PetsInsuranceSum } from './pets-insurance-sum';
import { PetsMainRisks } from './pets-main-risks';
import { PetsPaymentPeriod } from './pets-payment-period';

export const PetsForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();

  useAnalyticsPets();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/new-login');
    } else {
      navigate(-1);
    }
  };

  return (
    <YMaps>
      <StepWizardForm
        maxStep={5}
        useResetSteps={useResetFlatSteps}
        goBack={goBack}
      >
        {currentStep === 1 && <PetsData />}
        {currentStep === 2 && <PetsMainRisks />}
        {currentStep === 3 && <PetsAdditionalRisks />}
        {currentStep === 4 && <PetsInsuranceSum />}
        {currentStep === 5 && <PetsPaymentPeriod />}
      </StepWizardForm>
    </YMaps>
  );
};
