import Axios from 'axios';
import { useCallback, useContext, useMemo } from 'react';
import { useQueries } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { AuthActionTypes, Store } from '@src/store';
import { refreshToken } from '@src/utils';

type Method = 'post' | 'get' | 'patch' | 'put';
type Url = string;
type RequestParams = {
  key: string;
  method: Method;
  url: Url;
  request?: any;
  deps?: any[];
  accessToken?: string;
};

const headers = {
  'X-Process-ID': uuidv4(),
  'Content-type': 'application/json',
};

export const useRequests = (queriesProp: RequestParams[]) => {
  const ProcessId = uuidv4();

  const {
    state: {
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  Axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired

        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          authTokens
        ) {
          originalConfig._retry = true;

          dispatch({
            type: AuthActionTypes.SetAuthTokens,
            payload: undefined,
          });

          try {
            const rs = await refreshToken(authTokens);

            if (rs?.data) {
              return dispatch({
                type: AuthActionTypes.SetAuthTokens,
                payload: rs.data,
              });
            }

            return Promise.reject(err);
          } catch (_error: any) {
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 403 && err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );

  const queryResults = useQueries(
    queriesProp.map((query) => {
      let authHeaders: Record<string, any> = headers;

      if (query.accessToken) {
        authHeaders = {
          ...headers,
          Authorization: `Bearer ${query.accessToken}`,
        };
      }

      return {
        queryKey: [query.key, query.deps],
        queryFn: () =>
          Axios[query.method](
            `${window.envUrls.BASEURL}${query.url}`,
            query.request,
            {
              headers: authHeaders,
            }
          ),
      };
    })
  );

  const res = useMemo(
    () => queryResults.map((query) => ({ ...query, data: query?.data?.data })),
    [queryResults]
  );
  const isLoading = useMemo(
    () => queryResults.some((query) => query.isLoading),
    [queryResults]
  );
  const isFetching = useMemo(
    () => queryResults.some((query) => query.isFetching),
    [queryResults]
  );
  const isError = useMemo(
    () => queryResults.some((query) => query.isError),
    [queryResults]
  );
  const refetchAll = useCallback(() => {
    queryResults.forEach((query) => query.refetch());
  }, [queryResults]);

  return { res, isLoading, isFetching, isError, refetchAll };
};
