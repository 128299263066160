import styled from 'styled-components';

import { colors, media } from '@pulse-web-ui/theme';

import { HeaderProgressItemProps } from './step-wizard-form.types';

export const NavHeader = styled.div``;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const NavFooter = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: space-between;

  ${media.desktop} {
    padding-top: 24px;
  }
`;

export const HeaderProgressWrapper = styled.div`
  display: flex;
  height: 4px;
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const HeaderProgressItem = styled.div<HeaderProgressItemProps>`
  // TODO: перенести цвет в палитру
  background-color: #ff542b;
  border-radius: 2px;
  height: 4px;
  flex-grow: 1;
`;
export const HeaderProgressEmptyItem = styled.div`
  background-color: ${colors.secondaryLightGray2};
  border-radius: 2px;
  height: 4px;
  flex-grow: 1;
`;
