import type { FC } from 'react';

import { useIsDesktop } from '@src/hooks';
import { currencyRuLocaleWithTwoFractionDigits } from '@src/utils';

import {
  StyledSpinner,
  SumPerMonthSubtitle,
  SumPerMonthValue,
  SumPerMonthWrapper,
} from './sum-per-month.styles';
import type { SumPerMonthProps } from './sum-per-month.types';

export const SumPerMonth: FC<SumPerMonthProps> = ({
  isLoading,
  disabled,
  sumPerMonth,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <SumPerMonthWrapper disabled={disabled}>
      {isLoading || !sumPerMonth ? (
        <StyledSpinner inverse />
      ) : (
        <SumPerMonthValue>
          {currencyRuLocaleWithTwoFractionDigits(sumPerMonth)} &#8381;
        </SumPerMonthValue>
      )}
      <SumPerMonthSubtitle>
        {isDesktop ? 'в месяц' : 'стоимость в месяц'}
      </SumPerMonthSubtitle>
    </SumPerMonthWrapper>
  );
};
