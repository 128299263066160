import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';

import { Button } from '@pulse-web-ui/button';
import { colors } from '@pulse-web-ui/theme';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { useTimer } from '@src/hooks';

import {
  Resend as StyledResend,
  ResentText as StyledResendText,
} from './resend.style';
import { ResendProps } from './resend.types';

const Resend: FC<ResendProps> = ({
  expiryTimestamp,
  onResend,
  onAbleResendOtp,
  isExpiryTimestampText,
}) => {
  const { seconds, restart } = useTimer({
    expiryTimestamp,
  });

  const handleResend = useCallback(() => {
    onResend(restart);
    sendAnalyticEvent(analyticEvents.requestNewOtp);
  }, [onResend, restart]);

  useEffect(() => {
    if (seconds <= 0 && onAbleResendOtp) {
      onAbleResendOtp();
    }
  }, [seconds, onAbleResendOtp]);

  useEffect(() => restart(expiryTimestamp), [expiryTimestamp]);

  const secondsRemaining = (
    <span>{`Получить новый код${
      seconds > 0 ? ` через 0:${seconds < 10 ? '0' + seconds : seconds}` : ''
    }`}</span>
  );

  const handleResetClick = () => {
    if (seconds > 0) {
      return null;
    } else {
      return handleResend();
    }
  };

  return (
    <>
      {isExpiryTimestampText ? (
        <StyledResendText seconds={seconds} onClick={handleResetClick}>
          {secondsRemaining}
        </StyledResendText>
      ) : (
        <StyledResend>
          <Button
            style={{ backgroundColor: colors.gray, color: colors.primary }}
            themeColorAccent={colors.darkBlue2}
            onClick={handleResend}
            disabled={seconds > 0}
          >
            {secondsRemaining}
          </Button>
        </StyledResend>
      )}
    </>
  );
};

export default memo(Resend);
