export const MAX_MONTH_AGE = 11;
export const YEAR_DECLENSIONS = ['год', 'года', 'лет'];
export const MONTH_DECLENSIONS = ['месяц', 'месяца', 'месяцев'];

export const plural = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
export const isMonth = (age: string) => age.includes('M');
export const getNumberFromString = (str: string) =>
  Number(str.replace(/[^0-9]/g, ''));
export const getAgeMeasure = (age: string) => (isMonth(age) ? 'M' : 'Y');
export const getFormattedAge = (age: string) => {
  const ageNumber = getNumberFromString(age);

  if (isMonth(age)) {
    return `${ageNumber} ${plural(ageNumber, MONTH_DECLENSIONS)}`;
  } else {
    return `${ageNumber} ${plural(ageNumber, YEAR_DECLENSIONS)}`;
  }
};
