import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseLayout } from '@src/layouts';
import { Store } from '@src/store';

import { IFLFlatForm } from './ifl-flat-form';

export const IFLFlatPage: FC = () => {
  const {
    state: {
      stateAuth: { phoneNumber, authTokens },
      stateUser: {
        isSubscriptionExists,
        selectedProduct,
        userRegistrationData,
        isPureSubscription,
      },
    },
  } = useContext(Store);

  const navigate = useNavigate();
  useEffect(() => {
    // анкеты на нашем сайте
    if (!isPureSubscription) {
      if (isSubscriptionExists === undefined) {
        navigate('/');
      } else if (!selectedProduct) {
        navigate('/subscription-choice');
      } else if (!userRegistrationData && isSubscriptionExists === false) {
        navigate('/personal-info');
      } else if (!phoneNumber) {
        navigate('/new-login');
      }
    } else {
      if (!userRegistrationData) {
        navigate('/personal-info');
      } else if (!phoneNumber) {
        navigate('/new-login');
      }
    }
  }, []);

  return <BaseLayout>{phoneNumber && <IFLFlatForm />}</BaseLayout>;
};
