import { useContext } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { colors } from '@pulse-web-ui/theme';

import {
  ArrowLeftIcon,
  CenteredWrapper,
  Container,
  FormLabel,
  FormSub,
  GridColumnContainer,
} from '@src/components';
import { SupportBlock } from '@src/features';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store } from '@src/store';

export const AuthorizeFailPage: FC = () => {
  const navigate = useNavigate();

  const {
    state: {
      stateAuth: { authorizeFailState },
    },
  } = useContext(Store);

  const pageTitle = authorizeFailState?.title || 'Ошибка авторизации';
  const pageSubTitle =
    authorizeFailState?.subtitle || 'Попробуйте авторизоваться еще раз';

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowLeftIcon />
      </Button>
    </>
  );

  return (
    <BaseLayout verticalAlignment={true} footer={<Footer />}>
      <CenteredWrapper>
        <GridColumnContainer gridStep={24}>
          <Container>
            <FormLabel adaptiveCentered={true}>{pageTitle}</FormLabel>
            <FormSub>{pageSubTitle}</FormSub>
          </Container>
          <Button
            style={{ backgroundColor: colors.main, color: colors.white }}
            themeColorAccent={colors.main}
            onClick={() => {
              navigate(authorizeFailState?.refRoute || '/registration');
            }}
          >
            <strong>Попробовать ещё раз</strong>
          </Button>
          <SupportBlock />
        </GridColumnContainer>
      </CenteredWrapper>
    </BaseLayout>
  );
};
