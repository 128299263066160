import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { colors } from '@pulse-web-ui/theme';

import {
  AdaptiveColumns,
  ArrowLeftIcon,
  CheckboxGridItemWrapperWithoutTopPadding,
  FullWidthWrapper,
  MiddleNameWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  ClientCategory,
  USER_PERSONAL_DATA_AGREEMENTS,
  USER_RECL_AGREEMENTS_URL,
  analyticEvents,
} from '@src/constants';
import { BaseLayout } from '@src/layouts/base-layout';
import { clientPersonalInfoSchema } from '@src/schemas';
import { PersonalInfoDataProps, Store, UserActionTypes } from '@src/store';
import { UserRegistrationDataTypes } from '@src/types';
import { calculateAge } from '@src/utils';

import {
  AuthPageTitle,
  AuthPageWrapper,
  AuthSub,
  StyledCheckboxContainer,
  StyledDatePicker,
  StyledFooter,
} from './authorization-pages.styles';

const maxDate: Date | null | undefined = new Date(
  new Date().setFullYear(new Date().getFullYear() - 18)
);

export const PersonalInfoPage: FC = () => {
  const {
    state: {
      stateUser: {
        regUtm,
        isSubscriptionExists,
        selectedProduct,
        cachedPersonalInfoData,
        isPureSubscription,
      },
    },
    dispatch,
  } = useContext(Store);

  const defaultValues = useMemo(
    () => ({
      lastName: cachedPersonalInfoData?.lastName || '',
      firstName: cachedPersonalInfoData?.firstName || '',
      middleName: cachedPersonalInfoData?.middleName || '',
      useMiddleName: cachedPersonalInfoData?.useMiddleName || false,
      userPoliciesCode003: cachedPersonalInfoData?.userPoliciesCode003 || false,
      userPoliciesCode002: cachedPersonalInfoData?.userPoliciesCode002 || false,
      birthDay: cachedPersonalInfoData?.birthDay || undefined,
    }),
    [cachedPersonalInfoData]
  );
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const navigate = useNavigate();
  const approvedAt = new Date().toISOString();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    clearErrors,
  } = useForm<PersonalInfoDataProps>({
    resolver: yupResolver(clientPersonalInfoSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues,
  });

  const userPolicies = [
    {
      approvedAt,
      code: '003',
    },
    {
      approvedAt,
      code: '005',
    },
  ];

  let userRegistrationData: UserRegistrationDataTypes = {
    extendedIntermediaryChannelCode: extendedIntermediaryChannelCode,
    firstName: cachedPersonalInfoData?.firstName || '',
    lastName: cachedPersonalInfoData?.lastName || '',
    middleName: cachedPersonalInfoData?.middleName || '',
    birthDay:
      cachedPersonalInfoData?.birthDay &&
      format(new Date(cachedPersonalInfoData.birthDay), 'yyyy-MM-dd'),
    clientCategory: ClientCategory.STANDART,
    userPolicies,
  };

  if (regUtm?.utm_source) {
    userRegistrationData.utmSource = regUtm.utm_source;
  }

  if (regUtm?.utm_campaign) {
    userRegistrationData.utmCampaign = regUtm.utm_campaign;
  }

  if (regUtm?.utm_medium) {
    userRegistrationData.utmMedium = regUtm.utm_medium;
  }

  if (regUtm?.utm_content) {
    userRegistrationData.utmContent = regUtm.utm_content;
  }

  if (regUtm?.wm_id) {
    userRegistrationData.wmId = regUtm.wm_id;
  }

  const goBack = () => {
    if (selectedProduct) {
      navigate('/subscription-choice');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.signupStart);

    // анкеты на нашем сайте
    if (!isPureSubscription) {
      if (isSubscriptionExists === undefined) {
        navigate('/');
      }

      if (!selectedProduct) {
        navigate('/subscription-choice');
      }
    }
  }, []);

  const submitPage = handleSubmit(() => {
    navigate('/new-login');
  });

  const middleNameStateOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue('middleName', '');
      clearErrors('middleName');
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.userPoliciesCode002) {
        userPolicies.push({
          approvedAt,
          code: '002',
        });
        userRegistrationData?.userPolicies?.push({
          approvedAt,
          code: '002',
        });
      }

      userRegistrationData = {
        ...userRegistrationData,
        lastName: value.lastName,
        firstName: value.firstName,
        middleName: value.useMiddleName ? '' : value.middleName,
        birthDay:
          value.birthDay && format(new Date(value.birthDay), 'yyyy-MM-dd'),
      };

      dispatch({
        type: UserActionTypes.SetCachedPersonalInfoData,
        payload: {
          ...value,
          middleName: value.useMiddleName ? '' : value.middleName,
        },
      });

      dispatch({
        type: UserActionTypes.SetUserRegistrationData,
        payload: userRegistrationData,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const Footer = () => (
    <StyledFooter>
      {!isPureSubscription ? (
        <Button themeColor="gray" variant="circle" onClick={goBack}>
          <ArrowLeftIcon />
        </Button>
      ) : (
        <div />
      )}
      <Button themeColor="primary" label="Продолжить" onClick={submitPage} />
    </StyledFooter>
  );

  return (
    <BaseLayout>
      <AuthPageWrapper>
        <FullWidthWrapper>
          <AuthPageTitle mb={0} color={colors.darkBlue2}>
            Давайте знакомиться
          </AuthPageTitle>
          <AuthSub color={colors.secondaryGray}>
            Мы сделаем вам персональное предложение
          </AuthSub>
          <AdaptiveColumns>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={errors.lastName?.message}
                >
                  <Input
                    label="Фамилия"
                    name="lastName"
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    error={!!errors.lastName}
                  />
                </HelperText>
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={errors.firstName?.message}
                >
                  <Input
                    label="Имя"
                    name="firstName"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!errors.firstName}
                  />
                </HelperText>
              )}
            />
            <MiddleNameWrapper>
              <FullWidthWrapper>
                <Controller
                  control={control}
                  name="middleName"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState,
                  }) => (
                    <HelperText
                      status={fieldState.error ? 'error' : 'default'}
                      message={
                        errors.middleName?.message
                          ? errors.middleName?.message
                          : !cachedPersonalInfoData?.useMiddleName &&
                            'Укажите точно как в паспорте'
                      }
                    >
                      <Input
                        label="Отчество"
                        name="middleName"
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                        error={!!errors.middleName}
                        disabled={cachedPersonalInfoData?.useMiddleName}
                      />
                    </HelperText>
                  )}
                />
              </FullWidthWrapper>
              <CheckboxGridItemWrapperWithoutTopPadding height={36}>
                <Controller
                  control={control}
                  name="useMiddleName"
                  render={({ field: { onChange }, fieldState }) => (
                    <Checkbox
                      checked={cachedPersonalInfoData?.useMiddleName || false}
                      label="У меня нет отчества"
                      name="useMiddleName"
                      onChange={(e) => {
                        onChange(e);
                        middleNameStateOnChange(e);
                      }}
                      message={errors.useMiddleName?.message}
                      status={fieldState.error && 'error'}
                    />
                  )}
                />
              </CheckboxGridItemWrapperWithoutTopPadding>
            </MiddleNameWrapper>
            <Controller
              control={control}
              name="birthDay"
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <HelperText
                    status={fieldState.error ? 'error' : 'default'}
                    message={errors.birthDay?.message}
                  >
                    <StyledDatePicker
                      error={!!errors.birthDay}
                      label="Дата рождения"
                      selected={value as any}
                      onBlur={onBlur}
                      showYearDropdown
                      showMonthDropdown
                      showInput
                      onChange={onChange}
                      maxDate={maxDate}
                    />
                  </HelperText>
                );
              }}
            />
          </AdaptiveColumns>
          <StyledCheckboxContainer>
            <Controller
              control={control}
              name="userPoliciesCode003"
              render={({ field: { onChange }, fieldState }) => (
                <Checkbox
                  checked={cachedPersonalInfoData?.userPoliciesCode003 || false}
                  label={
                    <>
                      Соглашаюсь на обработку{' '}
                      <a href={USER_PERSONAL_DATA_AGREEMENTS} target="_blank">
                        персональных данных
                      </a>
                    </>
                  }
                  name="userPoliciesCode003"
                  onChange={onChange}
                  message={errors.userPoliciesCode003?.message}
                  status={fieldState.error && 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name="userPoliciesCode002"
              render={({ field: { onChange }, fieldState }) => (
                <Checkbox
                  checked={cachedPersonalInfoData?.userPoliciesCode002 || false}
                  label={
                    <>
                      Соглашаюсь на получение{' '}
                      <a href={USER_RECL_AGREEMENTS_URL} target="_blank">
                        специальных предложений, скидок и полезных рекомендаций
                      </a>
                    </>
                  }
                  name="userPoliciesCode002"
                  onChange={onChange}
                  message={errors.userPoliciesCode002?.message}
                  status={fieldState.error && 'error'}
                />
              )}
            />
          </StyledCheckboxContainer>
          <Footer />
        </FullWidthWrapper>
      </AuthPageWrapper>
    </BaseLayout>
  );
};
