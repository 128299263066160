import type { FC } from 'react';
import { AddressSuggestions } from 'react-dadata';

import { TextInput } from '@pulse-web-ui/text-input';

import { StyledDaDataContainer } from './styled-dadata-address-suggestions.styles';
import type { StyledDaDataAddressSuggestionProps } from './styled-dadata-address-suggestions.types';

export const StyledDaDataAddressSuggestion: FC<
  StyledDaDataAddressSuggestionProps
> = (props) => (
  <StyledDaDataContainer error={props.error}>
    <AddressSuggestions
      {...props}
      token={`${window.envKeys.DADATA_KEY}`}
      customInput={TextInput}
    />
  </StyledDaDataContainer>
);
