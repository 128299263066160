import { useContext } from 'react';

import { Store } from '@src/store';
import { OrderRequestData } from '@src/types';

export const useNsOrderData = () => {
  const {
    state: { stateFormNS },
  } = useContext(Store);

  const orderData: OrderRequestData = {
    productCode: stateFormNS.insuranceProduct?.code,
    contractDuration: stateFormNS.selectedDuration,
    risks: [
      ...(stateFormNS.selectedMainRisks || []),
      ...(stateFormNS.selectedAdditionalRisks || []),
    ],
    effectiveSince:
      stateFormNS.selectedDate !== null
        ? String(stateFormNS.selectedDate.toISOString())
        : '',
    insuranceSum: String(stateFormNS.insuranceSum),
    promoCode: stateFormNS.promoCode || '',
  };

  return {
    orderData,
  };
};
