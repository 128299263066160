import styled from 'styled-components';

import { Spinner } from '@pulse-web-ui/spinner';
import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

import type { SumPerMonthWrapperProps } from './sum-per-month.types';

export const SumPerMonthWrapper = styled.div<SumPerMonthWrapperProps>`
  background: ${colors.active};
  color: ${(props) => props.theme.colors.white};
  padding: 16px;
  border-radius: 12px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  min-height: 81px;
  box-sizing: border-box;

  ${mixins.flex({ horizontal: false, justifyContent: 'space-between' })}
  ${media.desktop} {
    align-items: center;
    border-radius: 16px;
    padding: 9px 34px;
    min-height: 68px;
  }
`;

export const SumPerMonthValue = styled.div`
  ${typography.bold22}
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const SumPerMonthSubtitle = styled.div`
  ${typography.regular16}
  white-space: nowrap;
`;

export const StyledSpinner = styled(Spinner)``;

export const SumPerMonthErrorMessage = styled.div`
  ${typography.regular14}
  letter-spacing: 0.02em;
  color: ${colors.main};
  padding-top: 16px;

  ${media.desktop} {
    padding-top: 13px;
  }
`;

SumPerMonthWrapper.defaultProps = {
  theme: {
    colors: colors,
  },
};
