import {
  OrderItemType,
  OrderItemValueType,
  UserProfileTypes,
} from '@src/types';

export const updateOrderProfileData = (
  orderArray: OrderItemType[],
  profile: UserProfileTypes
): OrderItemType[] => {
  const newOrderArray = [...orderArray];

  const name = [
    profile.lastName,
    profile.firstName,
    profile.middleName !== '' ? profile.middleName : null,
  ].join(' ');

  const user = {
    type: OrderItemValueType.SIMPLE,
    title: 'Страхователь',
    value: name,
  };

  newOrderArray.splice(5, 0, user);

  return newOrderArray;
};
