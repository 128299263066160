import styled from 'styled-components';

import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

export const Title = styled.h3`
  ${typography.bold20}
  margin-bottom: 8px;
  color: ${colors.darkBlue2};
`;

export const MaterialsWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
  margin-bottom: 24px;
`;
