import { formPeriodLabels } from '@src/constants';
import type { Price } from '@src/types';
import { currencyRuLocaleWithTwoFractionDigits } from '@src/utils';

import {
  BaseValue,
  Label,
  PromoValue,
  SubLabel,
} from './radio-label-render.styles';

export const RadioLabelRender = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
  duration,
}: Price) => {
  if (!!premiumAndDeltaPromo) {
    return (
      <Label>
        <BaseValue>
          {currencyRuLocaleWithTwoFractionDigits(Number(premiumAndDelta))} ₽
        </BaseValue>{' '}
        <PromoValue>{` ${currencyRuLocaleWithTwoFractionDigits(
          Number(premiumAndDeltaPromo)
        )} ${formPeriodLabels.get(duration)?.label}`}</PromoValue>
        <br />
        <SubLabel>
          {`${
            formPeriodLabels.get(duration)?.promoSubLabel
          }${currencyRuLocaleWithTwoFractionDigits(Number(premiumAndDelta))}`}
          ₽
        </SubLabel>
      </Label>
    );
  }
  return (
    <>{`${currencyRuLocaleWithTwoFractionDigits(Number(premiumAndDelta))} ${
      formPeriodLabels.get(duration)?.label
    }`}</>
  );
};
