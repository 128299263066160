import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Container, FormLabel, Skeleton } from '@src/components';
import { insurancePersonDefaultData } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { InsurancePersonForm } from '@src/features/insurance-person-form';
import { useRequest } from '@src/hooks';
import {
  useNsDetailsInsured,
  useNsOrderData,
  useOrderDataFormatting,
} from '@src/pages/ns-form/hooks';
import { orderRequestPersonsData } from '@src/pages/ns-form/utils';
import { updateOrderProfileData } from '@src/pages/ns-form/utils/update-order-profile-data';
import { personsFormSchema } from '@src/schemas';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { InsurancePersonsForm, InsurePerson } from '@src/types';
import { setPaymentPageTexts } from '@src/utils';

export const FormNsDetailsInsured = () => {
  const [changedProfileData, setChangedProfileData] = useState<null | Record<
    string,
    string
  >>(null);
  const {
    state: {
      stateFormNS: { insurePersons, selectedDate },
      stateWizard: { wantNextStep },
      stateUser: { profile: stateProfile, profile },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [submitPersonsData, setSubmitPersonsData] = useState<
    undefined | InsurePerson[]
  >();
  const { orderData } = useNsOrderData();
  const navigate = useNavigate();
  const { storeWantNextStep, storeInsurePersons, profileDataForForm } =
    useNsDetailsInsured();
  const { orderArray } = useOrderDataFormatting();

  const methods = useForm<InsurancePersonsForm>({
    mode: 'all',
    resolver: yupResolver(personsFormSchema),
    defaultValues: {
      persons: insurePersons,
    },
  });

  const { handleSubmit, control, getValues, reset, watch } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'persons',
  });

  const handleSelectionMe = (personsIndex: number) => {
    const formData = getValues();
    const newData = formData.persons.map((item, idx) => {
      if (idx === personsIndex) {
        if (item.isMe) {
          return {
            ...item,
            ...profileDataForForm,
            useMiddleName: !profileDataForForm.middleName,
            isMe: true,
            isDisabledForm: stateProfile?.hasSubscriptions,
          };
        }

        return { ...insurancePersonDefaultData, ageMin: item.ageMin };
      }

      if (item.isMe) {
        return { ...insurancePersonDefaultData, ageMin: item.ageMin };
      }

      return { ...item, isMe: false };
    });

    reset({
      persons: newData,
    });
  };

  const {
    isLoading: profileIsLoading,
    error: profileError,
    res: profileRes,
    refetch: profileRefetch,
  } = useRequest(
    'userGetProfile',
    'post',
    '/v1/user/get-profile',
    {},
    // [authTokens?.authorization?.accessToken, profile], // TODO: после тестирования
    [profile],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    error: updateProfileError,
    isLoading: updateProfileIsLoading,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileNSRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        ...changedProfileData,
        birthDate:
          changedProfileData?.birthDate &&
          new Date(changedProfileData.birthDate).toISOString(),
        email: stateProfile?.profile?.email,
        phone: stateProfile?.profile?.phone,
      },
    },
    [changedProfileData, profile],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (profileIsLoading || updateProfileIsLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [profileIsLoading, updateProfileIsLoading]);

  useEffect(() => {
    const subscription = watch((value) =>
      storeInsurePersons(value.persons as InsurePerson[])
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (wantNextStep) {
      storeWantNextStep(false);

      handleSubmit((data) => {
        let isEqual = true;
        const changedUserData = data.persons.filter((item) => item.isMe);
        if (changedUserData?.length) {
          for (const key in profileDataForForm) {
            if (
              profileDataForForm[key as keyof typeof profileDataForForm] !==
              changedUserData[0][key as keyof typeof profileDataForForm]
            ) {
              isEqual = false;
              break;
            }
          }
        }

        setPaymentPageTexts(
          'Если что-то случится, свяжитесь с нами в приложении «Пульс Жизни»',
          selectedDate
        );

        if (isEqual) {
          if (stateProfile?.profile) {
            dispatch({
              type: OrderActionTypes.SetOrder,
              payload: updateOrderProfileData(orderArray, stateProfile.profile),
            });
          }

          orderData.accidents = {
            persons: orderRequestPersonsData(data.persons),
          };

          dispatch({
            type: OrderActionTypes.SetOrderRequestData,
            payload: orderData,
          });
          navigate('/new-order-detail');
        } else if (changedUserData?.length) {
          const { firstName, lastName, middleName, birthDate } =
            changedUserData[0];

          const updateUserProfileData: Record<string, string> = {};
          if (!stateProfile?.lockedFields.firstName && firstName) {
            updateUserProfileData.firstName = firstName;
          }
          if (!stateProfile?.lockedFields.middleName && middleName) {
            updateUserProfileData.middleName = middleName;
          }
          if (!stateProfile?.lockedFields.lastName && lastName) {
            updateUserProfileData.lastName = lastName;
          }
          if (!stateProfile?.lockedFields.birthDate && birthDate) {
            updateUserProfileData.birthDate = String(
              new Date(new Date(birthDate).setUTCHours(+24))
            );
          }

          setChangedProfileData(updateUserProfileData);
          setSubmitPersonsData(data.persons);
        }
      })();
    }
  }, [wantNextStep]);

  useEffect(() => {
    if (!profileIsLoading && profileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: profileRes,
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [profileRes, profileIsLoading]);

  useEffect(() => {
    if (
      !updateProfileIsLoading &&
      updateProfileRes &&
      submitPersonsData &&
      changedProfileData
    ) {
      const newData = [...submitPersonsData];

      const myIndex = newData.findIndex((item) => item.isMe);
      newData[myIndex].primaryRecordId =
        updateProfileRes.profile.primaryRecordId;

      orderData.accidents = {
        persons: orderRequestPersonsData(newData),
      };

      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      navigate('/new-order-detail');
    }
  }, [
    updateProfileIsLoading,
    updateProfileRes,
    submitPersonsData,
    changedProfileData,
  ]);

  useEffect(() => {
    if (changedProfileData) {
      updateProfileRefetch();
    }
  }, [changedProfileData]);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      dispatch({
        type: OrderActionTypes.SetOrder,
        payload: updateOrderProfileData(orderArray, updateProfileRes.profile),
      });
    }
  }, [updateProfileRes]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: 'Пульс. Движение',
    });
  }, []);

  if (profileIsLoading || updateProfileIsLoading) return <Skeleton />;

  if (profileError) {
    const e = (profileError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }

    return <GlobalErrorInfo retrayHandler={profileRefetch} />;
  }

  if (updateProfileError) {
    const e = (updateProfileError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }

    return (
      <GlobalErrorInfo
        pending={updateProfileIsLoading}
        retrayHandler={updateProfileRefetch}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <FormLabel>Введите данные застрахованных</FormLabel>
        {fields.map((field, fieldIndex) => (
          <InsurancePersonForm
            key={field.id}
            personsIndex={fieldIndex}
            ageMin={field.ageMin}
            effectiveSince={selectedDate || new Date()}
            profileData={stateProfile?.profile}
            handleSelectionMe={handleSelectionMe}
            lockedFields={stateProfile?.lockedFields}
          />
        ))}
        <div style={{ height: 180 }} />
      </Container>
    </FormProvider>
  );
};
