/* eslint-disable quotes */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import {
  PageBackButton,
  PageBackButtonText,
} from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageTitle } from '@src/common-components/headers';
import { Skeleton } from '@src/components';
import { ArrowLeftIcon2 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  ClientCategory,
  DONT_RECOGNIZE_YOU_TITLE,
  analyticEvents,
} from '@src/constants';
import { useRequest } from '@src/hooks';
import { lastNameFormSchema } from '@src/schemas';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';
import { IdentificationPageInput } from './authorization-pages.styles';
import type { AuthConfirmFormProps } from './authorization.types';

export const NewAuthorizationConfirmPage: FC = () => {
  const {
    state: {
      stateAuth: { authorizationToken, phoneNumber },
      stateUser: {
        selectedProduct,
        isSubscriptionExists,
        isPureSubscription,
        userRegistrationData,
      },
    },
    dispatch,
  } = useContext(Store);

  const [lastNameValue, setLastNameValue] = useState<string>('');
  const formProps = useMemo(
    () => ({
      resolver: yupResolver(lastNameFormSchema),
      shouldFocusError: true,
      defaultValues: {
        lastName: '',
      },
    }),
    [lastNameFormSchema]
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AuthConfirmFormProps>(formProps);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'authRequest',
    'post',
    '/v1/user/authorization',
    {
      lastName: lastNameValue,
    },
    [lastNameValue, authorizationToken, phoneNumber],
    true,
    authorizationToken
  );

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.enterSurname);

    // анкеты на нашем сайте
    if (!isPureSubscription) {
      if (!phoneNumber) {
        if (isSubscriptionExists) {
          navigate('/new-login');
        } else if (!userRegistrationData) {
          navigate('/personal-info');
        } else {
          navigate('/new-login');
        }
      }
    } else {
      if (!userRegistrationData) {
        navigate('/personal-info');
      } else if (!phoneNumber) {
        navigate('/new-login');
      }
    }
  }, []);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: res,
      });

      if (res.isVip) {
        localStorage.setItem('clientCategory', ClientCategory.VIP);
        sendAnalyticEvent(analyticEvents.signupCompleted, {
          registration: 'n',
          VIP: 'y',
        });

        navigate('/vip');
      } else {
        localStorage.setItem('clientCategory', ClientCategory.STANDART);
        sendAnalyticEvent(analyticEvents.signupCompleted, {
          registration: 'n',
        });

        navigate(selectedProduct ? `/${selectedProduct}` : '/');
      }
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (lastNameValue) {
      refetch();
    }
  }, [lastNameValue]);

  const submitPage = handleSubmit((data) => {
    setLastNameValue(data.lastName);
  });

  useEffect(() => {
    if (error) {
      const e = (error as any).response.status;
      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: DONT_RECOGNIZE_YOU_TITLE,
            subtitle: 'Введите фамилию, которую вы указали при регистрации',
            refRoute: '/new-authorize-confirm',
          },
        });

        navigate('/new-authorize-fail');
      }

      if (
        e === 400 &&
        (error as any).response.data.code === 'RATE_LIMIT_EXCEEDED'
      ) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetCachedPhoneNumber,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetAuthenticationRefetchUid,
          payload: uuidv4(),
        });

        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Возьмите паузу',
            subtitle:
              'Превышено количество запросов, попробуйте позже или обратитесь в поддержку',
            refRoute: '/new-login',
          },
        });

        navigate('/new-authorize-fail');
      }
      const msg =
        !!error || error !== null ? 'Ошибка отправки, попробуйте еще раз' : '';

      setError('lastName', { type: 'string', message: msg });
    }
  }, [error]);

  const goBack = () => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });

    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetAuthenticationRefetchUid,
      payload: uuidv4(),
    });

    navigate('/new-login');
  };

  if (isLoading) return <Skeleton />;

  return (
    <BaseLayout>
      {phoneNumber && (
        <PageContent>
          <PageBackButton onClick={goBack}>
            <ArrowLeftIcon2 />
            <PageBackButtonText>Назад</PageBackButtonText>
          </PageBackButton>
          <PageTitle>
            <span>Начинаем вас узнавать,</span>
            <br />
            <span>напомните свою фамилию</span>
          </PageTitle>
          <IdentificationPageInput style={{ marginTop: 32, maxWidth: 765 }}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={errors.lastName?.message}
                >
                  <Input
                    label="Фамилия"
                    name="lastName"
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    error={!!errors.lastName}
                  />
                </HelperText>
              )}
            />
          </IdentificationPageInput>
          <Button
            disabled={
              isLoading ||
              (!!errors.lastName &&
                errors.lastName.message ===
                  'Превышен лимит попыток авторизации')
            }
            themeColor="primary"
            label="Продолжить"
            onClick={submitPage}
          />
        </PageContent>
      )}
    </BaseLayout>
  );
};
