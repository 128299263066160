/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import type { FC } from 'react';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';

import {
  Container,
  FormLabel,
  FormSubTitle,
  InfoWrapper,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  WarnigText,
  WarnigWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, durationDescription } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep } from '@src/hooks';
import { useRequests } from '@src/hooks/use-requests';
import {
  formInsurancePeriodDefaultValues,
  formInsurancePeriodSchema,
} from '@src/schemas';
import { AuthActionTypes, Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { FormPromoProps, SelectedDuration } from '@src/types';

import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-ns-period.styles';

export const FormNsPeriod: FC = () => {
  const {
    state: {
      stateFormNS: {
        insuranceSum,
        insuranceProduct,
        selectedMainRisks = [],
        selectedAdditionalRisks = [],
        numberInsurePersons: { numberChildren, numberAdults, numberElderly },
        getPrices,
        selectedDate,
        selectedDuration,
        subscriptionDateLimit,
        promoCode: statePromoCode,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormPromoProps>(
    formInsurancePeriodDefaultValues
  );
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: { promoCode: statePromoCode || '' },
  });

  const persons = useMemo(
    () => [
      ...Array(numberChildren).fill({ ageMin: '1' }),
      ...Array(numberAdults).fill({ ageMin: '18' }),
      ...Array(numberElderly).fill({ ageMin: '70' }),
    ],
    [numberChildren, numberAdults, numberElderly]
  );

  const risks = useMemo(
    () => [...selectedMainRisks, ...selectedAdditionalRisks],
    [selectedMainRisks, selectedAdditionalRisks]
  );

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    return true;
  }, []);
  useNextStep(validatePage);

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: NSActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    sendAnalyticEvent(analyticEvents.dateStartAccident);
  };

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: NSActionTypes.SetSelectedDuration,
      payload: val,
    });

    sendAnalyticEvent(analyticEvents.periodSelectAccident, {
      period: durationDescription[val],
    });
  };

  const {
    res: [getPricesData, getInsuranceLimitStartData],
    isFetching,
    isError,
    refetchAll,
  } = useRequests([
    {
      key: 'formPeriodNSGetPrices',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        risks,
        productCode: insuranceProduct?.code,
        insuranceSum: Number(insuranceSum),
        returnMinDuration: false,
        accidents: {
          persons,
        },
        promoCode: authTokens?.authorization?.accessToken && statePromoCode,
      },
      deps: [
        insuranceSum,
        persons,
        risks,
        formData.promoCode,
        statePromoCode,
        authTokens?.authorization?.accessToken,
      ],
      accessToken: authTokens?.authorization?.accessToken,
    },
    {
      key: 'formNSGetInsuranceLimitStart',
      method: 'post',
      url: '/v1/references/get-insurance-limit-start',
      request: {
        productCode: insuranceProduct?.code,
      },
      deps: [insuranceSum, persons, risks],
    },
  ]);

  useEffect(() => {
    if (!isFetching && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: NSActionTypes.SetGetPrices,
        payload: getPricesData.data,
      });
      if (selectedDuration === null) {
        dispatch({
          type: NSActionTypes.SetSelectedDuration,
          payload: getPricesData?.data?.prices[0]?.duration,
        });
      }
      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        formData.promoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'Такого промокода нет или он больше не действует',
        });
        dispatch({
          type: NSActionTypes.SetPromoCode,
          payload: undefined,
        });
      }
    }
  }, [isFetching, getPricesData.data]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (authTokens) {
      refetchAll();
    }
  }, [authTokens]);

  useEffect(() => {
    if (
      !isFetching &&
      getInsuranceLimitStartData &&
      getInsuranceLimitStartData.data?.startDate
    ) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: NSActionTypes.SetSubscriptionDateLimit,
        payload: getInsuranceLimitStartData.data,
      });
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate ?? new Date(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [isFetching, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (authTokens) {
      refetchAll();
    }
  }, [authTokens]);

  useEffect(() => {
    if (formData.promoCode) {
      dispatch({
        type: NSActionTypes.SetPromoCode,
        payload: formData.promoCode,
      });
      refetchAll();
    }
  }, [formData.promoCode]);

  useEffect(() => {
    setSubmitPromoDisabled(
      getPricesData.data?.isSuccessfulPromo && statePromoCode !== ''
    );
  }, [getPricesData.data, statePromoCode]);

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === statePromoCode);
  };

  const submitPage = handleSubmit((data) => {
    dispatch({
      type: NSActionTypes.SetPromoCode,
      payload: data.promoCode,
    });
    setFormData(data);
  });

  if (isFetching) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }
    return <GlobalErrorInfo pending={isFetching} retrayHandler={refetchAll} />;
  }

  return (
    <Container>
      <FormLabel marginBottom={24}>Периодичность оплаты</FormLabel>
      {getPrices && subscriptionDateLimit && selectedDate && (
        <>
          <ContentWrapper>
            {getPrices?.prices && (
              <NewRadioGroup
                name="duration"
                onChange={onRadioSelector}
                selectedValue={selectedDuration}
              >
                {getPrices?.prices &&
                  getPrices?.prices.map((item) => (
                    <NewRadio
                      key={`${item.duration}-key`}
                      value={item.duration}
                      label={RadioLabelRender(item)}
                      description={RadioDescriptionRender(item)}
                    />
                  ))}
              </NewRadioGroup>
            )}
          </ContentWrapper>
          <ContentWrapper>
            {authTokens?.authorization?.accessToken ? (
              <PromoWrapper>
                <PromoTextInput>
                  <Controller
                    control={control}
                    name="promoCode"
                    render={({ field: { onChange, value }, fieldState }) => (
                      <HelperText
                        status={fieldState.error ? 'error' : 'success'}
                        message={
                          errors.promoCode?.message ||
                          (getPricesData.data?.isSuccessfulPromo &&
                          statePromoCode !== ''
                            ? 'Скидка на первый период подписки'
                            : '')
                        }
                      >
                        <Input
                          label="Промокод"
                          value={value}
                          onChange={(val) => {
                            onChange(val);
                            checkSubmitState(val);
                          }}
                          error={!!errors.promoCode}
                          disabled={isFetching}
                        />
                      </HelperText>
                    )}
                  />
                </PromoTextInput>
                <PromoSubmitButton>
                  <Button
                    label="Применить"
                    onClick={submitPage}
                    disabled={submitPromoDisabled}
                    themeColor="white"
                  />
                </PromoSubmitButton>
              </PromoWrapper>
            ) : (
              <WarnigWrapper>
                <Info width={24} />
                <WarnigText>
                  Промокод можно ввести после авторизации на следующем шаге
                </WarnigText>
              </WarnigWrapper>
            )}
          </ContentWrapper>

          <FormSubTitle>Подписка станет активна</FormSubTitle>
          <HelperText message="" status="default" messagePosition="up">
            {selectedDate &&
            subscriptionDateLimit.startDate &&
            subscriptionDateLimit.endDate ? (
              dateEqual ? (
                <InfoWrapper>
                  <Info width={24} />
                  <WarnigText>
                    Подписка начнет действовать{' '}
                    {subscriptionDateLimit.startDate.toLocaleDateString(
                      'ru-RU',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                  </WarnigText>
                </InfoWrapper>
              ) : (
                <Datepicker
                  onChange={setSelectedDate}
                  selected={selectedDate}
                  minDate={new Date(subscriptionDateLimit.startDate)}
                  maxDate={new Date(subscriptionDateLimit.endDate)}
                />
              )
            ) : null}
          </HelperText>
        </>
      )}
    </Container>
  );
};
