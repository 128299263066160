import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import {
  PageBackButton,
  PageBackButtonText,
  StyledButton,
} from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers/header';
import { ArrowLeftIcon2 } from '@src/components';
import { Skeleton } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { useRequest } from '@src/hooks';
import { identificationPageSchema } from '@src/schemas';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';
import { IdentificationPageInput } from './authorization-pages.styles';
import type { LoginFormProps } from './authorization.types';

const phoneValueCasting = (value: string) =>
  `+7${value.replace(/[\(\)\- ]/g, '')}`;

const phoneDisplayValueCasting = (value: string) =>
  // eslint-disable-next-line
  value.replace(/(.{2})(\d{3})(\d{3})(\d{2})(\d{2})/g, `$1 ($2) $3-$4-$5`);

export const NewIdentificationPage: FC = () => {
  const {
    state: {
      stateAuth: {
        phoneNumber,
        displayPhoneNumber,
        authenticationToken,
        confirmationId,
      },
      stateUser: {
        cachedPhoneNumber,
        isSubscriptionExists,
        selectedProduct,
        userRegistrationData,
        isPureSubscription,
        authenticationRefetchUid,
      },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();

  const [phoneValue, setPhoneValue] = useState<string>(
    cachedPhoneNumber || phoneNumber || ''
  );

  const [displayPhoneValue, setDisplayPhoneValue] = useState<string>(
    displayPhoneNumber ||
      phoneDisplayValueCasting(cachedPhoneNumber || phoneNumber || '') ||
      ''
  );
  const [shouldNavigateToSMS, setShouldNavigateToSMS] =
    useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<LoginFormProps>({
    resolver: yupResolver(identificationPageSchema),
    shouldFocusError: true,
    defaultValues: {
      phoneNumber: (cachedPhoneNumber || phoneNumber)?.replace('+7', ''),
    },
  });

  const { isLoading, error, res, refetch } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber: phoneValue,
      },
    },
    [
      phoneValue,
      cachedPhoneNumber,
      displayPhoneValue,
      confirmationId,
      phoneNumber,
      authenticationToken,
      authenticationRefetchUid,
    ],
    true
  );

  const goBack = () => {
    if (userRegistrationData) {
      navigate('/personal-info');
    } else if (selectedProduct) {
      navigate('/subscription-choice');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });

    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: phoneValueCasting(value.phoneNumber!),
      });

      setDisplayPhoneValue(`+7 ${value.phoneNumber}`);
      setPhoneValue(phoneValueCasting(value.phoneNumber || ''));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: res?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: res?.authenticationToken,
      });
      dispatch({
        type: AuthActionTypes.SetPhoneNumber,
        payload: phoneValue,
      });
      dispatch({
        type: AuthActionTypes.SetDisplayPhoneNumber,
        payload: displayPhoneValue,
      });
    }
  }, [
    res?.confirmationId,
    res?.authenticationToken,
    isLoading,
    phoneValue,
    displayPhoneValue,
    cachedPhoneNumber,
    phoneNumber,
  ]);

  useEffect(() => {
    if (error) {
      const isRateLimitExceededError =
        (error as any)?.response.data?.code === 'RATE_LIMIT_EXCEEDED';

      const msg = !!error
        ? isRateLimitExceededError
          ? 'Превышен лимит попыток авторизации'
          : 'Ошибка отправки, попробуйте еще раз'
        : '';

      if (isRateLimitExceededError) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });
      }

      setError('phoneNumber', { type: 'string', message: msg });
    }
  }, [error]);

  const submitPage = handleSubmit((data) => {
    setShouldNavigateToSMS(true);

    if (
      (!phoneValue.includes('_') && displayPhoneValue !== '') ||
      phoneValue != cachedPhoneNumber
    ) {
      refetch();
    }
  });

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.signupPhone);

    // анкеты на нашем сайте
    if (!isPureSubscription) {
      if (isSubscriptionExists === undefined) {
        navigate('/');
      } else if (!selectedProduct) {
        navigate('/subscription-choice');
      } else if (!userRegistrationData && isSubscriptionExists === false) {
        navigate('/personal-info');
      }
    } else {
      if (!userRegistrationData) {
        navigate('/personal-info');
      }
    }
  }, []);

  useEffect(() => {
    if (shouldNavigateToSMS && authenticationToken && confirmationId) {
      setShouldNavigateToSMS(false);
      navigate('/new-authorize');
    }
  }, [shouldNavigateToSMS, authenticationToken, confirmationId]);

  if (isLoading) return <Skeleton />;

  return (
    <BaseLayout>
      {(userRegistrationData || isSubscriptionExists) && (
        <PageContent>
          <PageBackButton onClick={goBack}>
            <ArrowLeftIcon2 />
            <PageBackButtonText>Назад</PageBackButtonText>
          </PageBackButton>
          <PageTitle>Введите телефон</PageTitle>
          <PageSubTitle>
            {isSubscriptionExists ? (
              <div>
                Отправим на него СМС с проверочным кодом <br />и подберем вам
                индивидуальные условия
              </div>
            ) : (
              'Отправим проверочный код на этот номер'
            )}
          </PageSubTitle>
          <IdentificationPageInput>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange, value }, fieldState }) => (
                <HelperText
                  status={!!fieldState.error ? 'error' : 'default'}
                  message={errors.phoneNumber?.message}
                >
                  <Input
                    label="Телефон"
                    name="phoneNumber"
                    value={value}
                    onChange={onChange}
                    type="tel"
                    error={!!errors.phoneNumber || !!error}
                  />
                </HelperText>
              )}
            />
          </IdentificationPageInput>
          <StyledButton
            disabled={
              isLoading ||
              (!!errors.phoneNumber &&
                errors.phoneNumber.message ===
                  'Превышен лимит попыток авторизации')
            }
            themeColor="primary"
            label="Получить код"
            onClick={submitPage}
          />
        </PageContent>
      )}
    </BaseLayout>
  );
};
