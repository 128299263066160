import type { AgeRatio } from '@src/store/ns';

type AgeRatiosFormatted = {
  [key: string]: AgeRatio;
};

type AgeRatiosLabels = {
  children: string;
  adults: string;
  elderly: string;
};

export const ageCategory = {
  children: 'Ребенок',
  adults: 'Взрослый',
  elderly: 'Пожилой',
};

export const ageCategorySubTitle = {
  children: '1 – 17 лет',
  adults: '18 – 69 лет',
  elderly: '70 лет и старше',
};

export const ageRatiosFormat = (
  ageRatios: AgeRatio[]
): AgeRatiosLabels | undefined => {
  if (!ageRatios.length) return;

  const ageRatiosFormatted: AgeRatiosFormatted = {};

  ageRatios.forEach((ageRatio: AgeRatio) => {
    ageRatiosFormatted[ageRatio.ageCategory] = ageRatio;
  });

  return {
    children: `${ageRatiosFormatted[ageCategory.children].ageMin} – ${
      ageRatiosFormatted[ageCategory.children].ageMax
    } лет`,
    adults: `${ageRatiosFormatted[ageCategory.adults].ageMin} – ${
      ageRatiosFormatted[ageCategory.adults].ageMax
    } лет`,
    elderly: `${ageRatiosFormatted[ageCategory.elderly].ageMin} лет и старше`,
  };
};
