/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { OrderStoreState } from './index';
import { OrderActionTypes } from './index';

export type OrderStoreReducer = (
  state: OrderStoreState,
  action: StoreActions
) => OrderStoreState;

export const orderStoreReducer: OrderStoreReducer = (state, action) => {
  switch (action?.type) {
    case OrderActionTypes.SetOrder:
      return {
        ...state,
        order: action.payload,
      };
    case OrderActionTypes.SetOrderSubmitData:
      return {
        ...state,
        orderSubmitData: action.payload,
      };
    case OrderActionTypes.SetOrderInitData:
      return {
        ...state,
        orderInitData: action.payload,
      };
    case OrderActionTypes.SetOrderPageTitle:
      return {
        ...state,
        orderPageTitle: action.payload,
      };
    case OrderActionTypes.SetOrderRequestData:
      return {
        ...state,
        orderRequestData: action.payload,
      };
    case OrderActionTypes.SetCachedOrderRequestData:
      return {
        ...state,
        cachedOrderRequestData: action.payload,
      };
    default:
      return state;
  }
};
