/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { OrderActionTypes, Store } from '@src/store';
import { OrderRequestData } from '@src/types';

import { sublimitRisksConverter } from '../utils';

export const usePetsOrderData = () => {
  const {
    state: {
      stateFormPets: {
        selectedIProduct,
        selectedDuration,
        risks,
        selectedDate,
        insuranceSum,
        promoCode,
        selectedPetData,
        sublimits,
      },
    },
    dispatch,
  } = useContext(Store);

  const orderData: OrderRequestData = {
    productCode: selectedIProduct?.code,
    insuranceSum: String(insuranceSum),
    contractDuration: selectedDuration,
    risks: risks
      ?.filter((item) => item.active === true)
      .map((item) => item.code),
    effectiveSince:
      selectedDate !== null ? String(selectedDate.toISOString()) : '',
    ...(promoCode && { promoCode: promoCode }),
    pets: selectedPetData
      ? {
          ...selectedPetData,
          coverages: sublimits?.risks
            ? sublimitRisksConverter(sublimits?.risks)
            : [],
        }
      : undefined,
  };

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderRequestData,
      payload: orderData,
    });
  }, [
    selectedIProduct,
    selectedDate,
    promoCode,
    insuranceSum,
    selectedDuration,
    risks,
    sublimits,
    selectedPetData,
  ]);
};
