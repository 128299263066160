import { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { Container, FormHead } from '@src/components';
import type { OrderItemType, OrderItemsBlockType } from '@src/types';

import {
  OrderBlockItemTitle,
  OrderBlockItemTitleButton,
  OrderBlockItemWrapper,
} from './components.styles';
import { OrderItem } from './order-item';

export const OrderBlockItem: FC<OrderItemsBlockType> = (item) => (
  <Container>
    <OrderBlockItemWrapper>
      <OrderBlockItemTitle>
        <FormHead>{item.title}</FormHead>
        {item.onClick ? (
          <OrderBlockItemTitleButton onClick={item.onClick}>
            Изменить
          </OrderBlockItemTitleButton>
        ) : null}
      </OrderBlockItemTitle>
      {item.value
        ?.filter((subItem) => subItem.value)
        ?.map((filteredSubItem: OrderItemType) => (
          <OrderItem key={uuid()} {...filteredSubItem} />
        ))}
    </OrderBlockItemWrapper>
  </Container>
);
