/* eslint-disable indent */
import { Map, Placemark, useYMaps } from '@pbe/react-yandex-maps';
import type { ChangeEvent, FC } from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { DaDataAddressSuggestion } from 'react-dadata';
import { useNavigate } from 'react-router-dom';

import { HelperText } from '@pulse-web-ui/helper-text';
import { StyledListBox } from '@pulse-web-ui/list-box';
import type { OptionsMap } from '@pulse-web-ui/list-box/lib/list-box.types';

import { FilterLocations, SiteAgreements } from '@src/components';
import {
  AdaptiveContainerInverse,
  Container,
  FormLabel,
  FormSub,
  Skeleton,
  StyledDaDataAddressSuggestion,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  DEFAULT_FIAS_ID,
  USER_AGREEMENTS_URL,
  UseQueryStatus,
  analyticEvents,
  insuranceProductsCode,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useDadataRequest, useNextStep, useRequest } from '@src/hooks';
import {
  AuthActionTypes,
  IFLHouseActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import type { InsuranceProductType, Region, Status } from '@src/types';

const initialStatus: Status = {
  status: 'default',
  message: '',
};

const yaMapOptions = { suppressMapOpenBlock: true };

const modules = [
  'control.ZoomControl',
  'control.FullscreenControl',
  'borders',
  'util.bounds',
];

const modulesConfig = ['geoObject.addon.balloon', 'geoObject.addon.hint'];
type Lat = number;
type Lon = number;

type Center = [Lat, Lon];

type MapCenter = {
  center: Center;
  zoom: number;
};

const initialMapCenter: MapCenter = {
  center: [55.75, 37.57],
  zoom: 11,
};

export const FormInsuranceAddress: FC = () => {
  const {
    state: {
      stateFormIFLHouse: {
        selectedIProduct,
        dadaValue,
        selectedRegion,
        regions,
      },
      stateUser: { cachedDadaValue },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();

  const ymaps = useYMaps(modules);

  const ContainerRef = useRef<null | HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [dadaValueStatus, setDadaValueStatus] = useState<Status>(initialStatus);
  const [regionStatus, setRegionStatus] = useState<Status>(initialStatus);
  const [yaMapCoords, setYaMapCoords] = useState<[Lat, Lon] | []>([]);
  const [latLon, setLatLon] = useState<Center | []>([]);
  const [mapCenter, setMapCenter] = useState<MapCenter>(initialMapCenter);
  const [regionCenter, setRegionCenter] = useState<[Lat, Lon]>([0, 0]);

  const filterLocationsData = useMemo(
    () => [{ region_fias_id: selectedRegion?.region }],
    [selectedRegion]
  );

  const handleViewAgreement = () => {
    sendAnalyticEvent(analyticEvents.userAgreement);
  };

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formIFLHouseGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.house,
    }
  );

  const regionRestrict: FilterLocations[] = [];

  const setDadaValue = (value: undefined | DaDataAddressSuggestion) => {
    dispatch({
      type: IFLHouseActionTypes.SetDadaValue,
      payload: value,
    });
    sendAnalyticEvent(analyticEvents.addressSelectedRealty);
  };

  const {
    error,
    res: dadataRes,
    isLoading,
  } = useDadataRequest(
    'getHouseYaCoords',
    'post',
    '/geolocate/address',
    {
      lat: (yaMapCoords?.length > 1 && Number(yaMapCoords[0])) || 0,
      lon: (yaMapCoords?.length > 1 && Number(yaMapCoords[1])) || 0,
      radius_meters: 10_000,
    },
    [yaMapCoords]
  );

  const {
    isLoading: scoringIsLoading,
    error: scoringError,
    refetch: scoringRefetch,
    status: scoringStatus,
  } = useRequest(
    'scoringRequest',
    'post',
    '/v1/scoring',
    {
      productCode: selectedIProduct?.code,
      address: {
        address: dadaValue?.value || '',
        addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
      },
    },
    [cachedDadaValue?.value],
    true,
    authTokens?.authorization?.accessToken
  );

  const validatePage = useCallback(() => {
    if (!selectedRegion) {
      setRegionStatus({
        status: 'error',
        message: 'Укажите регион',
      });
    }
    if (!dadaValue && dadaValueStatus.status !== 'error') {
      setDadaValueStatus({
        status: 'error',
        message: 'Укажите объект в выбранном регионе',
      });
    }

    const isError = !dadaValue || dadaValueStatus.status === 'error';

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !dadaValue || dadaValueStatus.status === 'error',
    });

    if (!isError) {
      if (cachedDadaValue?.value !== dadaValue.value) {
        scoringRefetch();

        return false;
      }
    }

    return !isError;
  }, [selectedRegion, dadaValue, dadaValueStatus, cachedDadaValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMapClick = (e: any) => {
    if (!selectedRegion) {
      setRegionStatus({
        status: 'error',
        message: 'Укажите регион',
      });
    } else {
      setLatLon([]);
      setDadaValue(undefined);
      setMapCenter({
        center: e.get('coords'),
        zoom: 11,
      });
      return setYaMapCoords(e.get('coords'));
    }
  };

  const dadaValueValidation = (val: string | DaDataAddressSuggestion) => {
    if (!selectedRegion) {
      setDadaValueStatus({
        status: 'error',
        message: 'Выберите регион',
      });
    } else if (
      typeof val === 'string' &&
      val.length > 0 &&
      val !== dadaValue?.value
    ) {
      setDadaValueStatus({
        status: 'error',
        message: 'Нет совпадений по выбираемому объекту',
      });
    } else if (dadaValue?.data.house === null) {
      setDadaValueStatus({
        status: 'error',
        message: 'Укажите объект',
      });
    } else if (
      (typeof val === 'string' && val.length === 0) ||
      (typeof val === 'string' && val.length > 0) ||
      (typeof val !== 'string' && val.value === dadaValue?.value)
    ) {
      setDadaValueStatus(initialStatus);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  };

  const yaMapLoadGetPosition = useCallback(
    (ymaps: any) => {
      ymaps.borders
        .load('RU', {
          lang: 'ru',
          quality: 3,
        })
        .then((res: any) => {
          const filteredRegion = res.features.filter(
            (region: any) =>
              region.properties.name ===
              selectedRegion?.regionName.replace(/город /, '')
          );

          const newMapState = ymaps.util.bounds.getCenterAndZoom(
            ymaps.util.bounds.fromPoints([
              ...filteredRegion[0].geometry.coordinates.flat(),
            ]),
            [containerWidth, 320]
          );
          if (!dadaValue) {
            setRegionCenter(newMapState.center);
            setMapCenter(newMapState);
          }
        });
    },
    [selectedRegion]
  );

  const inputDadaValueValidation = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || null) {
      setDadaValue(undefined);
      setYaMapCoords([]);
    }
    dadaValueValidation(e.target.value);
  };

  const storeSelectedRegion = useCallback((data: Region | undefined) => {
    setLatLon([]);
    setDadaValue(undefined);

    dispatch({
      type: IFLHouseActionTypes.SetSelectedRegion,
      payload: data,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleRegionChange = useCallback(
    (val: string) => {
      setRegionStatus(initialStatus);
      setStepUpdated();

      selectedIProduct?.regions.map((item) => {
        if (item.region === val) {
          storeSelectedRegion(item);
          sendAnalyticEvent(analyticEvents.regionSelectedRealty);
        }
      });
    },
    [selectedIProduct, selectedRegion]
  );

  useNextStep(validatePage);

  useEffect(() => {
    if (regions) {
      Object.keys(regions).map((item) => {
        const el = { region_fias_id: item };
        regionRestrict.push(el);
      });
    }
  }, [regions]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setContainerWidth(ContainerRef.current?.clientWidth || 0);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.startRealty);
    sendAnalyticEvent(analyticEvents.iflStep1Address);
    sendAnalyticEvent(analyticEvents.iflHouseStep1Address);
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/house',
    });
  }, []);

  useLayoutEffect(() => {
    const even = (item: DaDataAddressSuggestion) =>
      regionRestrict.map(
        (regionItem: FilterLocations) =>
          regionItem.region_fias_id === item.data.region_fias_id
      );

    if (dadataRes) {
      const hasInRegionArray: boolean = dadataRes.suggestions.some(even);
      const regionFiasIdArray = dadataRes.suggestions.map(
        (item: DaDataAddressSuggestion) => item.data.region_fias_id
      );
      const unique = [...new Set(regionFiasIdArray)];

      if (
        unique.length > 0 &&
        hasInRegionArray &&
        dadataRes.suggestions[0]?.data?.region_fias_id ===
          selectedRegion?.region
      ) {
        setDadaValue(dadataRes.suggestions[0]);
      } else if (!dadaValue) {
        setYaMapCoords([]);
      }
    }
  }, [dadataRes]);

  useLayoutEffect(() => {
    if (dadaValue) {
      const newLatLon: Center =
        dadaValue.data.geo_lat && dadaValue.data.geo_lon
          ? [Number(dadaValue.data.geo_lat), Number(dadaValue.data.geo_lon)]
          : regionCenter;

      setLatLon(newLatLon);
      setMapCenter({
        center: [
          Number(dadaValue.data.geo_lat),
          Number(dadaValue.data.geo_lon),
        ],
        zoom: 16,
      });
      dadaValueValidation(dadaValue);
    } else if (yaMapCoords) {
      setDadaValueStatus(initialStatus);
      setLatLon(yaMapCoords);
    }
  }, [dadaValue, yaMapCoords]);

  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      setContainerWidth(ContainerRef.current?.clientWidth || 0);

      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: IFLHouseActionTypes.SetSelectedIProduct,
        payload: data,
      });
    }
  }, [productIsLoading, resProduct]);

  useLayoutEffect(() => {
    if (selectedIProduct) {
      const filteredRegions: OptionsMap | undefined = {};

      selectedIProduct?.regions.map(
        (el) => (filteredRegions[`${el.region}`] = el.regionName)
      );

      dispatch({
        type: IFLHouseActionTypes.SetRegions,
        payload: filteredRegions,
      });
    }
  }, [selectedIProduct]);

  useLayoutEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !!productError,
    });
  }, [productError]);

  useEffect(() => {
    if (selectedRegion && ymaps && !dadaValue) {
      yaMapLoadGetPosition(ymaps);
    }
  }, [selectedRegion, ymaps, dadaValue]);

  useLayoutEffect(() => {
    if (!scoringIsLoading && scoringStatus === UseQueryStatus.SUCCESS) {
      dispatch({
        type: UserActionTypes.SetCachedDadaValue,
        payload: dadaValue,
      });

      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  }, [scoringStatus, scoringIsLoading]);

  if (scoringError) {
    dispatch({
      type: UserActionTypes.SetCachedDadaValue,
      payload: undefined,
    });

    const e = (scoringError as any).response?.status;

    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: false,
    });

    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: false,
    });

    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/new-login',
        },
      });

      navigate('/new-authorize-fail');
    }
  }

  if (productIsLoading || scoringIsLoading) return <Skeleton />;

  if (productError) {
    return (
      <GlobalErrorInfo
        pending={productIsLoading}
        retrayHandler={productRefetch}
      />
    );
  }

  return (
    <HelperText
      status={error ? 'error' : 'default'}
      message={error ? 'ошибка сопоставления адреса' : ''}
    >
      <AdaptiveContainerInverse ref={ContainerRef}>
        <Container>
          <Map
            onLoad={yaMapLoadGetPosition}
            width={containerWidth}
            height="320px"
            defaultState={mapCenter}
            onClick={onMapClick}
            options={yaMapOptions}
            state={mapCenter}
            modules={modules}
          >
            <Placemark
              modules={modulesConfig}
              key={'index'}
              geometry={(latLon.length > 0 && latLon) || [0, 0]}
            />
          </Map>
        </Container>
        <Container>
          <FormLabel>Укажите адрес недвижимости</FormLabel>
          <FormSub>
            Мы доверяем нашим клиентам и готовы предложить индивидуальные
            условия без осмотра дома
          </FormSub>
          <HelperText
            status={regionStatus.status}
            message={regionStatus.message}
          >
            <StyledListBox
              options={regions}
              value={selectedRegion?.region || ''}
              placeholder={'Выберите регион'}
              onChange={(val) => {
                handleRegionChange(val);
              }}
              status={
                regionStatus.status === 'error'
                  ? regionStatus.status
                  : undefined
              }
            />
          </HelperText>
          <HelperText
            status={dadaValueStatus.status}
            message={dadaValueStatus.message}
          >
            <StyledDaDataAddressSuggestion
              delay={500}
              filterLocations={filterLocationsData}
              value={dadaValue}
              onChange={setDadaValue}
              minChars={2}
              uid="dadata-address-dadavalue"
              inputProps={{
                placeholder: 'Укажите адрес объекта',
                onBlur: inputDadaValueValidation,
                disabled: isLoading,
              }}
              error={dadaValueStatus.status === 'error'}
            />
          </HelperText>
        </Container>
        <SiteAgreements>
          Нажимая кнопку «Продолжить», вы принимаете условия{' '}
          <a
            href={USER_AGREEMENTS_URL}
            target="_blank"
            onClick={handleViewAgreement}
            download
          >
            пользовательского соглашения
          </a>
        </SiteAgreements>
      </AdaptiveContainerInverse>
    </HelperText>
  );
};
