import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdaptiveListItem } from '@pulse-web-ui/adaptive-list-item';
import { Alert } from '@pulse-web-ui/alert';
import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';

import {
  AdaptiveListWrapper,
  Container,
  ControllerContainer,
  FormLabel,
  FormSub,
  LinkContainer,
  Skeleton,
} from '@src/components';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import { PetsActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { evenRisk } from '@src/utils';

export const PetsMainRisks: FC = () => {
  const navigate = useNavigate();

  const {
    state: {
      stateFormPets: { selectedIProduct, risks, selectedPetData },
    },
    dispatch,
  } = useContext(Store);

  const [listBoxStatus, setListBoxStatus] = useState<'error' | undefined>(
    undefined
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const { isLoading, error, res, refetch, isRefetching } = useRequest(
    'petsFormGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
    },
    [],
    true
  );

  useEffect(() => {
    if (!risks) {
      refetch();
    }
  }, []);

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    setListBoxStatus(!risks?.some(evenRisk) ? 'error' : undefined);

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: listBoxStatus === 'error',
    });
  }, [listBoxStatus]);

  useEffect(() => {
    if (!isLoading && !isRefetching && res && res.risks?.length > 0) {
      dispatch({
        type: PetsActionTypes.SetRisks,
        payload: res.risks,
      });
    }
  }, [isLoading, isRefetching, res]);

  if (isLoading || isRefetching) return <Skeleton />;

  const onClick = (code: string) => {
    setStepUpdated();

    setListBoxStatus(undefined);

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        item.active = !item.active;
      }
      return item;
    });

    dispatch({
      type: PetsActionTypes.SetRisks,
      payload: newRisks,
    });
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: PetsActionTypes.SetRisksPage,
      payload: 'primary',
    });
    navigate('/pets-risk-descriptions');
  };

  if (error)
    return (
      <GlobalErrorInfo
        pending={isLoading || isRefetching}
        retrayHandler={refetch}
      />
    );

  return (
    <Container>
      <FormLabel>Выберите основные риски</FormLabel>
      <FormSub isError={listBoxStatus === 'error'}>
        Чтобы продолжить, выберите для подписки хотя бы 1 риск
      </FormSub>
      <ControllerContainer>
        <Alert
          type="info"
          title="Телеветеринария 24/7 уже включена"
          description="Обращайтесь к ветеринару по телефону, в мессенджерах или видеосвязи в удобное время"
        />
      </ControllerContainer>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && 'Нет данных'}
        {risks?.map((item) => {
          return item.binding ? (
            <AdaptiveListItem
              index={item.code}
              key={item.code}
              active={item.active}
              label={item.name}
              description={item.description}
              onClick={() => onClick(item.code)}
              imgSrc={''}
            />
          ) : (
            <></>
          );
        })}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          leftIcon={<Info width={24} color="active" />}
          variant="text"
          themeColor="white"
          themeColorAccent="active"
          onClick={descriptionNavHandler}
          label="Подробнее об основных рисках"
        />
      </LinkContainer>
    </Container>
  );
};
