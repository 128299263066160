import { Button } from '@pulse-web-ui/button';
import { PhoneClassic, Telegram, Whatsapp } from '@pulse-web-ui/icons';
import { colors } from '@pulse-web-ui/theme';

import {
  SupportBlockContent,
  SupportBlockTitle,
  SupportButtonsWrapper,
} from './new-support.styles';

export const NewSupportBlock = () => (
  <SupportBlockContent>
    <SupportBlockTitle>Обратиться в поддержку</SupportBlockTitle>
    <SupportButtonsWrapper>
      <Button
        style={{ backgroundColor: colors.gray, color: colors.primary }}
        themeColorAccent={colors.darkBlue2}
        themeColor={colors.secondary}
        leftIcon={<Telegram color={colors.primary} />}
        onClick={() => window.open('https://t.me/Pulse_insure_bot', '_blank')}
      >
        <strong>Telegram</strong>
      </Button>
      <Button
        style={{ backgroundColor: colors.gray, color: colors.primary }}
        themeColorAccent={colors.darkBlue2}
        themeColor={colors.secondary}
        leftIcon={<Whatsapp color={colors.primary} />}
        onClick={() =>
          window.open('https://wa.me/message/YV4SCMVSU53OG1', '_blank')
        }
      >
        <strong>WhatsApp</strong>
      </Button>
      <Button
        style={{ backgroundColor: colors.gray, color: colors.primary }}
        themeColorAccent={colors.darkBlue2}
        themeColor={colors.secondary}
        leftIcon={<PhoneClassic color={colors.primary} />}
        onClick={() => (window.location.href = 'tel:+78003021554')}
      >
        <strong>Бесплатный звонок</strong>
      </Button>
    </SupportButtonsWrapper>
  </SupportBlockContent>
);
