import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CenteredWrapper,
  FormLabel,
  FormSub,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, phoneRegEx } from '@src/constants';
import { useRequest } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';
import type { LoginFormProps } from './authorization.types';

const schema = yup.object().shape({
  phoneNumber: yup
    .mixed()
    .required('Введите номер телефона')
    .test('validPhone', 'Неверный номер телефона', (value) =>
      phoneRegEx.test(value)
    ),
});

const phoneValueCasting = (value: string) =>
  `+7${value.replace(/[\(\)\- ]/g, '')}`;

const phoneDisplayValueCasting = (value: string) =>
  // eslint-disable-next-line
  value.replace(/(.{2})(\d{3})(\d{3})(\d{2})(\d{2})/g, `$1 ($2) $3-$4-$5`);

export const IdentificationPage: FC = () => {
  const {
    state: {
      stateWizard,
      stateAuth: { phoneNumber, displayPhoneNumber, authorizeRefRoute },
      stateUser: { cachedPhoneNumber },
    },
    dispatch,
  } = useContext(Store);

  const { currentStep } = stateWizard;

  const navigate = useNavigate();

  const [phoneValue, setPhoneValue] = useState<string>(
    phoneNumber || cachedPhoneNumber || ''
  );
  const [displayPhoneValue, setDisplayPhoneValue] = useState<string>(
    displayPhoneNumber ||
      phoneDisplayValueCasting(cachedPhoneNumber || '') ||
      ''
  );

  const formProps = useMemo(
    () => ({
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: {
        phoneNumber: cachedPhoneNumber?.replace('+7', ''),
      },
    }),
    [schema]
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<LoginFormProps>(formProps);

  const { isLoading, error, res, refetch } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber: phoneValue,
      },
    },
    [phoneValue],
    true
  );

  useEffect(() => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });
    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });
    dispatch({
      type: AuthActionTypes.SetPhoneNumber,
      payload: '',
    });
    dispatch({
      type: AuthActionTypes.SetDisplayPhoneNumber,
      payload: '',
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: phoneValueCasting(value.phoneNumber!),
      });

      setDisplayPhoneValue(`+7 ${value.phoneNumber}`);
      setPhoneValue(phoneValueCasting(value.phoneNumber || ''));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: res?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: res?.authenticationToken,
      });
      dispatch({
        type: AuthActionTypes.SetPhoneNumber,
        payload: phoneValue,
      });
      dispatch({
        type: AuthActionTypes.SetDisplayPhoneNumber,
        payload: displayPhoneValue,
      });
    }
  }, [res, isLoading, phoneValue, displayPhoneValue]);

  useEffect(() => {
    if (error) {
      const e = (error as any).response.status;

      const msg =
        !!error || error !== null
          ? (error as any)?.response.data?.code === 'RATE_LIMIT_EXCEEDED'
            ? 'Превышен лимит попыток авторизации'
            : 'Ошибка отправки, попробуйте еще раз'
          : '';

      setError('phoneNumber', { type: 'string', message: msg });
    }
  }, [error]);

  const submitPage = handleSubmit(() => {
    navigate('/authorize');
  });

  useEffect(() => {
    if (
      !phoneValue.includes('_') &&
      displayPhoneValue !== '' &&
      phoneValue !== phoneNumber
    ) {
      refetch();
    }
  }, [phoneValue, displayPhoneValue, phoneNumber]);

  useEffect(() => {
    if (currentStep === 1) {
      navigate(authorizeRefRoute || '/');
    }
  }, [currentStep]);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.signupStart);
  }, []);

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={() => {
          navigate(-1);
        }}
        disabled={isLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        disabled={
          isLoading ||
          (!!errors.phoneNumber &&
            errors.phoneNumber.message === 'Превышен лимит попыток авторизации')
        }
        themeColor="primary"
        label="Получить код"
        onClick={submitPage}
      />
    </>
  );

  return (
    <BaseLayout verticalAlignment={true} footer={<Footer />}>
      <CenteredWrapper>
        <FormLabel>Введите телефон</FormLabel>
        <FormSub>Отправим проверочный код на этот номер</FormSub>
        <div style={{ width: 204 }}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={errors.phoneNumber?.message}
              >
                <Input
                  label="Телефон"
                  name="phoneNumber"
                  value={value}
                  onChange={onChange}
                  type="tel"
                  error={!!errors.phoneNumber || !!error}
                />
              </HelperText>
            )}
          />
        </div>
      </CenteredWrapper>
    </BaseLayout>
  );
};
