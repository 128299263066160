import type { DaDataAddressSuggestion } from 'react-dadata';

import type { OptionsMap } from '@pulse-web-ui/list-box/lib/list-box.types';

import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubProduct,
  Materials,
  Region,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type InsuranceSum = number;

export type InsuranceScheme = Record<string, string[]>;

export type SelectedIProduct = undefined | InsuranceProductType;

export type InsuranceSubproducts = {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
};

export interface IFLHouseStoreState {
  selectedIProduct?: InsuranceProductType;
  selectedRegion?: Region;
  regions?: OptionsMap;
  risks?: Risk[];
  insuranceSubproducts?: InsuranceSubproducts;
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  dadaValue?: DaDataAddressSuggestion;
  promoCode?: string;
  risksPage: 'primary' | 'secondary';
  materials?: Materials;
  selectedBuildingMaterialCode?: string;
  fieldWithEmptyDependencies: string | undefined;
  emptyDependencies: string[] | undefined;
  isObjectDataError?: boolean | undefined;
  getSubobjectsRefetchUid?: string;
}

export const initIFLHouseStoreState: IFLHouseStoreState = {
  selectedIProduct: undefined,
  selectedRegion: undefined,
  regions: undefined,
  risks: undefined,
  insuranceSubproducts: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  selectedDuration: null,
  selectedDate: null,
  dadaValue: undefined,
  promoCode: undefined,
  risksPage: 'primary',
  materials: undefined,
  selectedBuildingMaterialCode: undefined,
  fieldWithEmptyDependencies: undefined,
  emptyDependencies: [],
  isObjectDataError: undefined,
};
