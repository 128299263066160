import type { AuthTokens, AuthorizeFailState } from './authorize-store.types';

export interface AuthStoreState {
  phoneNumber: string;
  displayPhoneNumber: string;
  authenticationToken?: string;
  confirmationId?: string;
  authorizationToken?: string;
  registrationToken?: string;
  authorizeFailState?: AuthorizeFailState | undefined;
  authorizeRefRoute?: string;
  authTokens?: AuthTokens | undefined;
}

export const initAuthStoreState: AuthStoreState = {
  phoneNumber: '',
  displayPhoneNumber: '',
  authenticationToken: undefined,
  confirmationId: undefined,
  authorizationToken: undefined,
  registrationToken: undefined,
  authorizeFailState: undefined,
  authorizeRefRoute: undefined,
  authTokens: undefined,
};
