/* eslint-disable indent */
import { useContext } from 'react';

import { formPeriodLabels } from '@src/constants';
import { Store } from '@src/store';
import { OrderItemType, OrderItemValueType } from '@src/types';
import { currencyRuLocaleWithTwoFractionDigits, numFormat } from '@src/utils';

export const useOrderDataFormatting = () => {
  const {
    state: {
      stateFormNS: {
        selectedDate,
        risks,
        selectedMainRisks,
        selectedAdditionalRisks,
        insuranceSum,
        getPrices,
        selectedDuration,
      },
      stateAuth: { displayPhoneNumber },
    },
  } = useContext(Store);

  const selectedPrice = getPrices?.prices.filter(
    (item) => item.duration === selectedDuration
  )[0].premiumAndDelta;

  const selectedPromoPrice = getPrices?.prices.filter(
    (item) => item.duration === selectedDuration
  )[0].premiumAndDeltaPromo;

  let label = `${
    selectedPrice
      ? currencyRuLocaleWithTwoFractionDigits(Number(selectedPrice))
      : ''
  }${formPeriodLabels.get(selectedDuration!)?.label}`;

  let subLabel = '';
  const isSuccessfulPromo = getPrices?.isSuccessfulPromo;

  if (!!selectedPromoPrice) {
    label = `${currencyRuLocaleWithTwoFractionDigits(
      Number(selectedPromoPrice)
    )}${formPeriodLabels.get(selectedDuration!)?.label}`;
    subLabel =
      `${
        formPeriodLabels.get(selectedDuration!)?.promoSubLabel
      } ${selectedPrice}₽` || '';
  }

  const orderArray: OrderItemType[] = [
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Стоимость и периодичность оплаты',
      value: label,
      secondsValue: subLabel,
      successValue: isSuccessfulPromo ? 'Промокод применен' : '',
      failedValue:
        isSuccessfulPromo === undefined || isSuccessfulPromo === null
          ? ''
          : 'Промокод не применен',
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Основные риски',
      value: String(
        risks
          ?.filter((item) => selectedMainRisks?.includes(item.code))
          ?.map((item) => item.name)
          ?.join(', ')
      ),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Дополнительные риски',
      value: selectedAdditionalRisks?.length
        ? String(
            risks
              ?.filter((item) => selectedAdditionalRisks?.includes(item.code))
              ?.map((item) => item.name)
              ?.join(', ')
          )
        : 'Не выбраны',
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Сумма покрытия',
      value: `${numFormat(insuranceSum || 0)} ₽`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Подписка начнёт действовать',
      value: `с ${new Date(selectedDate || new Date()).toLocaleDateString(
        'ru-RU',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      )}`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Номер телефона',
      value: displayPhoneNumber,
    },
  ];

  return {
    orderArray,
  };
};
