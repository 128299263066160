import { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import {
  ArrowLeftIcon,
  CenteredWrapper,
  FormLabel,
  FormSub,
  NoWrapItem,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo, Otp } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { AuthActionTypes, Store } from '@src/store';
import type { Status } from '@src/types';

const expirePeriod = 30;

export const AuthorizationPage: FC = () => {
  const {
    state: {
      stateAuth: {
        confirmationId,
        displayPhoneNumber,
        authenticationToken,
        phoneNumber,
        registrationToken: registrationTokenFromStore,
        authorizeRefRoute,
      },
      stateUser: { userRegistrationData },
    },
    dispatch,
  } = useContext(Store);

  const [smsCode, setSmsCode] = useState<string>('');
  const [expiredTime, setExpiredTime] = useState<number>(
    Date.now() / 1000 + expirePeriod
  );
  const [registrationToken, setRegistrationToken] = useState<
    string | undefined
  >(registrationTokenFromStore);
  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'authRequest',
    'post',
    '/v1/user/confirm-authentication',
    {
      code: smsCode,
      confirmationId: confirmationId,
    },
    [phoneNumber],
    true,
    authenticationToken
  );

  const {
    isLoading: isResendLoading,
    error: resendError,
    res: resendRes,
    refetch: resendRefetch,
  } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber,
      },
    },
    [phoneNumber],
    true
  );
  const {
    isLoading: isRegistrationLoading,
    error: registrationError,
    res: registrationRes,
    refetch: registrationRefetch,
  } = useRequest(
    'registrationRequest',
    'post',
    '/v1/user/registration',
    {
      user: {
        ...userRegistrationData,
      },
    },
    [userRegistrationData, registrationToken],
    true,
    registrationToken
  );

  const registrationResponseError =
    registrationError && (registrationError as unknown as any);

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/registration');
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: AuthActionTypes.SetAuthorizationToken,
      payload: undefined,
    });
    dispatch({
      type: AuthActionTypes.SetRegistrationToken,
      payload: undefined,
    });
  }, []);

  useEffect(() => {
    if (!isRegistrationLoading && registrationRes) {
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: registrationRes,
      });
      sendAnalyticEvent(analyticEvents.signupCompleted, {
        registration: 'y',
      });

      navigate(authorizeRefRoute || '/');
    }
  }, [isRegistrationLoading, registrationRes]);

  useEffect(() => {
    if (
      registrationError &&
      registrationResponseError?.code !== 'ERR_NETWORK'
    ) {
      const e = (registrationError as any)?.response?.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Что-то пошло не так',
            subtitle: 'Попробуйте зарегистрироваться еще раз',
            refRoute: '/registration',
          },
        });
      } else {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Ошибка отправки',
            subtitle:
              'Попробуйте зарегистрироваться еще раз или напишите нам в поддержку',
            refRoute: '/registration',
          },
        });
      }
      navigate('/authorize-fail');
    }
  }, [registrationError]);

  useEffect(() => {
    if (!isResendLoading && resendRes) {
      setExpiredTime(Date.now() / 1000 + expirePeriod);
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: resendRes?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: resendRes?.authenticationToken,
      });
    }
  }, [resendRes]);

  useEffect(() => {
    if (!isLoading && res) {
      const { authorizationToken: aT, registrationToken: rT } = res;

      if (aT) {
        dispatch({
          type: AuthActionTypes.SetAuthorizationToken,
          payload: aT,
        });
      }
      if (rT) {
        dispatch({
          type: AuthActionTypes.SetRegistrationToken,
          payload: rT,
        });
      }

      sendAnalyticEvent(analyticEvents.enterOtpSuccess);
    }
  }, [isLoading, res]);

  const submitPage = () => {
    refetch().then(({ status, data }) => {
      if (data?.data.authorizationToken && status === 'success') {
        navigate('/new-authorize-confirm');
      }
      if (data?.data.registrationToken && status === 'success') {
        setRegistrationToken(data?.data.registrationToken);
      }
    });
  };

  const handleOnChange = (e: string) => {
    setSmsCode(e);
  };

  const status: Status = {
    status: error && (error as any)?.code ? 'error' : 'default',
    message: error && (error as any).code ? 'Неверный код' : '',
  };

  useEffect(() => {
    if (error) {
      const e = (error as any).response.status;

      if (e === 400) {
        if ((error as any).response.data.code === 'RATE_LIMIT_EXCEEDED') {
          dispatch({
            type: AuthActionTypes.SetAuthorizeFailState,
            payload: {
              title: 'Возьмите паузу',
              subtitle:
                'Превышено количество запросов, попробуйте позже или обратитесь в поддержку',
              refRoute: '/registration',
            },
          });
          navigate('/authorize-fail');
        }

        if ((error as any).response.data.code === 'UNKNOWN_USER_OR_CODE') {
          sendAnalyticEvent(analyticEvents.enterOtpFail);
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (resendError) {
      const e = (resendError as any).response.status;

      if (e === 400) {
        const title =
          (resendError as any).response.data.code === 'RATE_LIMIT_EXCEEDED'
            ? 'Возьмите паузу'
            : 'Что-то пошло не так';

        const subtitle =
          (resendError as any).response.data.code === 'RATE_LIMIT_EXCEEDED'
            ? 'Превышено количество запросов, попробуйте позже или обратитесь в поддержку'
            : 'Попробуйте зарегистрироваться еще раз или напишите нам в поддержку';

        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title,
            subtitle,
            refRoute: '/registration',
          },
        });
        navigate('/authorize-fail');
      }
    }
  }, [resendError]);

  useEffect(() => {
    if (registrationToken && userRegistrationData) {
      registrationRefetch();
    }
  }, [registrationToken, userRegistrationData]);

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={() => {
          navigate(-1);
        }}
        disabled={isLoading || isResendLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        themeColor="primary"
        label="Продолжить"
        onClick={submitPage}
        disabled={isLoading || isResendLoading}
      />
    </>
  );

  if (registrationResponseError?.code === 'ERR_NETWORK') {
    return (
      <GlobalErrorInfo
        pending={isRegistrationLoading}
        retrayHandler={registrationRefetch}
      />
    );
  }

  return (
    <BaseLayout verticalAlignment={true} footer={<Footer />}>
      <CenteredWrapper>
        <FormLabel>Введите код</FormLabel>
        <Otp
          onSubmit={handleOnChange}
          limit={5}
          onResend={() => resendRefetch()}
          status={status}
          expiryTimestamp={expiredTime}
          extraMessage={
            <FormSub>
              Мы отправили СМС с кодом на номер:{' '}
              <NoWrapItem>{displayPhoneNumber}</NoWrapItem>
            </FormSub>
          }
        />
      </CenteredWrapper>
    </BaseLayout>
  );
};
