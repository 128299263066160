/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { IFLStoreState } from './index';
import { IFLActionTypes } from './index';

export type IFLStoreReducer = (
  state: IFLStoreState,
  action: StoreActions
) => IFLStoreState;

export const formIFLStoreReducer: IFLStoreReducer = (state, action) => {
  switch (action?.type) {
    case IFLActionTypes.SetInsuranceProducts:
      return {
        ...state,
        insuranceProducts: action.payload,
      };
    case IFLActionTypes.SetSelectedIProduct:
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case IFLActionTypes.SetSelectedRegion:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case IFLActionTypes.SetRegions:
      return {
        ...state,
        regions: action.payload,
      };
    case IFLActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case IFLActionTypes.SetInsuranceSubproducts:
      return {
        ...state,
        insuranceSubproducts: action.payload,
      };
    case IFLActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case IFLActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case IFLActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case IFLActionTypes.SetDadaValue:
      return {
        ...state,
        dadaValue: action.payload,
      };
    case IFLActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case IFLActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    default:
      return state;
  }
};
