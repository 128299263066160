import React, { FC } from 'react';

import {
  TotalSumSubtitle,
  TotalSumTitle,
  TotalSumValue,
  TotalSumWrapper,
} from './total-sum.styles';
import { TotalSumProps } from './total-sum.types';

export const TotalSum: FC<TotalSumProps> = ({
  totalSum,
  isError,
  subtitleText,
  title,
}) => (
  <TotalSumWrapper isError={isError}>
    <TotalSumTitle>{title || 'Сумма покрытия:'}</TotalSumTitle>
    <TotalSumValue>{totalSum} &#8381;</TotalSumValue>
    <TotalSumSubtitle isError={isError}>{subtitleText}</TotalSumSubtitle>
  </TotalSumWrapper>
);
