import { FC } from 'react';

import type { OrderItemType } from '@src/types';

import {
  OrderCheckoutItemTitle,
  OrderCheckoutItemWrapper,
  OrderItemSubValue,
  OrderItemTitle,
  OrderItemValue,
  OrderItemValueWrapper,
  OrderItemWrapper,
} from './components.styles';

export const OrderCheckoutItem: FC<OrderItemType> = (orderItem) => (
  <OrderCheckoutItemWrapper>
    <OrderCheckoutItemTitle isColsIdentical={orderItem.isColsIdentical}>
      {orderItem.title}
    </OrderCheckoutItemTitle>
    <OrderItemValueWrapper>
      <OrderItemValue>{orderItem.value}</OrderItemValue>
      {orderItem.secondsValue ? (
        <OrderItemSubValue>{orderItem.secondsValue}</OrderItemSubValue>
      ) : null}
    </OrderItemValueWrapper>
  </OrderCheckoutItemWrapper>
);
