import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import {
  AdaptiveColumns,
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckboxGridItemWrapper,
  FormLabel,
  FormSub,
  GridColumnContainer,
} from '@src/components';
import {
  ClientCategory,
  USER_PERSONAL_DATA_AGREEMENTS,
  USER_RECL_AGREEMENTS_URL,
} from '@src/constants';
import { BaseLayout } from '@src/layouts/base-layout';
import { registratiomFormSchema } from '@src/schemas';
import {
  RegistrationFormProps,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { UserRegistrationDataTypes } from '@src/types';

export const RegistrationPage: FC = () => {
  const {
    state: {
      stateWizard,
      stateUser: { regUtm, cachedRegistrationFormData },
      stateAuth: { authorizeRefRoute },
    },
    dispatch,
  } = useContext(Store);

  const { currentStep } = stateWizard;

  const defaultValues = useMemo(
    () => ({
      lastName: cachedRegistrationFormData?.lastName || '',
      firstName: cachedRegistrationFormData?.firstName || '',
      middleName: cachedRegistrationFormData?.middleName || '',
      useMiddleName: cachedRegistrationFormData?.useMiddleName || false,
      userPoliciesCode003:
        cachedRegistrationFormData?.userPoliciesCode003 || false,
      userPoliciesCode002:
        cachedRegistrationFormData?.userPoliciesCode002 || false,
    }),
    [cachedRegistrationFormData]
  );

  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const navigate = useNavigate();
  const approvedAt = new Date().toISOString();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<RegistrationFormProps>({
    resolver: yupResolver(registratiomFormSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues,
  });

  const userPolicies = [
    {
      approvedAt,
      code: '003',
    },
    {
      approvedAt,
      code: '005',
    },
  ];

  let userRegistrationData: UserRegistrationDataTypes = {
    extendedIntermediaryChannelCode: extendedIntermediaryChannelCode,
    firstName: cachedRegistrationFormData?.firstName || '',
    lastName: cachedRegistrationFormData?.lastName || '',
    middleName: cachedRegistrationFormData?.middleName || '',
    clientCategory: ClientCategory.STANDART,
    userPolicies,
  };

  if (regUtm?.utm_source) {
    userRegistrationData.utmSource = regUtm.utm_source;
  }

  if (regUtm?.utm_campaign) {
    userRegistrationData.utmCampaign = regUtm.utm_campaign;
  }

  if (regUtm?.utm_medium) {
    userRegistrationData.utmMedium = regUtm.utm_medium;
  }

  if (regUtm?.utm_content) {
    userRegistrationData.utmContent = regUtm.utm_content;
  }

  if (regUtm?.wm_id) {
    userRegistrationData.wmId = regUtm.wm_id;
  }

  const submitPage = handleSubmit(() => {
    navigate('/login');
  });

  const goBack = () => {
    navigate(-1);
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: currentStep - 1 || 1,
    });
  };

  const Footer = () => (
    <>
      <Button themeColor="gray" variant="circle" onClick={goBack}>
        <ArrowLeftIcon />
      </Button>
      <Button
        themeColor="primary"
        label="Продолжить"
        onClick={submitPage}
        rightIcon={<ArrowRightIcon />}
      />
    </>
  );

  const middleNameStateOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue('middleName', '');
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.userPoliciesCode002) {
        userPolicies.push({
          approvedAt,
          code: '002',
        });
        userRegistrationData?.userPolicies?.push({
          approvedAt,
          code: '002',
        });
      }

      userRegistrationData = {
        ...userRegistrationData,
        lastName: value.lastName,
        firstName: value.firstName,
        middleName: value.useMiddleName ? '' : value.middleName,
      };

      dispatch({
        type: UserActionTypes.SetCachedRegistrationFormData,
        payload: {
          ...value,
          middleName: value.useMiddleName ? '' : value.middleName,
        },
      });

      dispatch({
        type: UserActionTypes.SetUserRegistrationData,
        payload: userRegistrationData,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (currentStep === 1) {
      navigate(authorizeRefRoute || '/');
    }
  }, [currentStep]);

  return (
    <BaseLayout verticalAlignment={true} footer={<Footer />}>
      <FormLabel adaptiveCentered={true}>Как к вам обращаться?</FormLabel>
      <FormSub adaptiveCentered={true}>Введите свои данные кириллицей</FormSub>
      <AdaptiveColumns>
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={errors.lastName?.message}
            >
              <Input
                label="Фамилия"
                name="lastName"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                error={!!errors.lastName}
              />
            </HelperText>
          )}
        />
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={errors.firstName?.message}
            >
              <Input
                label="Имя"
                name="firstName"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.firstName}
              />
            </HelperText>
          )}
        />
        <Controller
          control={control}
          name="middleName"
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={errors.middleName?.message}
            >
              <Input
                label="Отчество"
                name="middleName"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                error={!!errors.middleName}
                disabled={cachedRegistrationFormData?.useMiddleName}
              />
            </HelperText>
          )}
        />
        <CheckboxGridItemWrapper>
          <Controller
            control={control}
            name="useMiddleName"
            render={({ field: { onChange }, fieldState }) => (
              <Checkbox
                checked={cachedRegistrationFormData?.useMiddleName || false}
                label="У меня нет отчества"
                name="useMiddleName"
                onChange={(e) => {
                  onChange(e);
                  middleNameStateOnChange(e);
                }}
                message={errors.useMiddleName?.message}
                status={fieldState.error && 'error'}
              />
            )}
          />
        </CheckboxGridItemWrapper>
      </AdaptiveColumns>
      <GridColumnContainer gridStep={16}>
        <Controller
          control={control}
          name="userPoliciesCode003"
          render={({ field: { onChange }, fieldState }) => (
            <Checkbox
              checked={cachedRegistrationFormData?.userPoliciesCode003 || false}
              label={
                <>
                  Соглашаюсь на обработку{' '}
                  <a href={USER_PERSONAL_DATA_AGREEMENTS} target="_blank">
                    персональных данных
                  </a>
                </>
              }
              name="userPoliciesCode003"
              onChange={onChange}
              message={errors.userPoliciesCode003?.message}
              status={fieldState.error && 'error'}
            />
          )}
        />
        <Controller
          control={control}
          name="userPoliciesCode002"
          render={({ field: { onChange }, fieldState }) => (
            <Checkbox
              checked={cachedRegistrationFormData?.userPoliciesCode002 || false}
              label={
                <>
                  Соглашаюсь на получение{' '}
                  <a href={USER_RECL_AGREEMENTS_URL} target="_blank">
                    специальных предложений, скидок и полезных рекомендаций
                  </a>
                </>
              }
              name="userPoliciesCode002"
              onChange={onChange}
              message={errors.userPoliciesCode002?.message}
              status={fieldState.error && 'error'}
            />
          )}
        />
      </GridColumnContainer>
    </BaseLayout>
  );
};
