import { useContext, useMemo } from 'react';
import * as yup from 'yup';

import { petNameRegEx } from '@src/constants';
import { Store } from '@src/store';

export const usePetsDataSchema = () => {
  const {
    state: {
      stateFormPets: { pets },
    },
  } = useContext(Store);

  const petsWithBreeds = useMemo(
    () =>
      pets?.filter(({ breeds }) => !!breeds?.length)?.map(({ kind }) => kind),
    [pets]
  );

  return useMemo(
    () =>
      yup.object().shape({
        kind: yup
          .string()
          .nullable()
          .required('Поле обязательно для заполнения'),
        breed: yup
          .string()
          .nullable()
          .when('kind', (kind, schema) =>
            !kind || !!petsWithBreeds.find((petKind) => petKind === kind)
              ? schema.required('Поле обязательно для заполнения')
              : schema
          ),
        gender: yup
          .string()
          .nullable()
          .required('Поле обязательно для заполнения'),
        name: yup
          .string()
          .nullable()
          .required('Поле обязательно для заполнения')
          .test(
            'validName',
            'До 40 символов, латиница, кириллица, цифры, подчеркивание, дефис, пробел',
            (value) => petNameRegEx.test(value!)
          ),
        age: yup
          .string()
          .nullable()
          .required('Поле обязательно для заполнения'),
      }),
    [petsWithBreeds]
  );
};
