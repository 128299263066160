/* eslint-disable indent */
type MinDate = Date | null;

export const getDatepickerPeriod = (
  ageMin: number,
  effectiveSince: Date
): { minDate: MinDate; maxDate: Date } => {
  let minDate: MinDate = new Date(effectiveSince);
  const maxDate = new Date(effectiveSince);

  switch (ageMin) {
    case 1:
      minDate.setFullYear(minDate.getFullYear() - 18);
      maxDate.setFullYear(maxDate.getFullYear() - 1);
      break;
    case 18:
      minDate.setFullYear(minDate.getFullYear() - 69);
      maxDate.setFullYear(maxDate.getFullYear() - 18);
      break;
    case 70:
      minDate = null;
      maxDate.setFullYear(maxDate.getFullYear() - 70);
      break;
    default:
      break;
  }

  return {
    minDate,
    maxDate,
  };
};
