/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';

import {
  Container,
  FormLabel,
  FormSubTitle,
  InfoWrapper,
  MiddleNameWrapper,
  PaymentPeriodsListWrapper,
  PromoWrapper,
  RadioLabelRender,
  Skeleton,
  WarnigText,
  WarnigWrapper,
  WrapperWithFlexibleGrow,
} from '@src/components';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest, useRequests } from '@src/hooks';
import {
  AuthActionTypes,
  OrderActionTypes,
  PetsActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import {
  FormPromoProps,
  GetPrices,
  OrderItemValueType,
  OrderItemsBlockType,
  SelectedDuration,
} from '@src/types';
import {
  currencyRuLocaleWithTwoFractionDigits,
  getAdditionalRisksToString,
  getMainRisksToString,
  numFormat,
  setPaymentPageTexts,
} from '@src/utils';

import {
  formInsurancePeriodDefaultValues,
  formInsurancePeriodSchema,
} from '../../schemas';
import { usePetsOrderData } from './hooks';
import { PetsPeymentPeriodLabels } from './pets-constants';
import { PromoSubmitButton } from './pets-payment-period.styles';
import { getFormattedAge, sublimitRisksConverter } from './utils';

export const PetsPaymentPeriod: FC = () => {
  const {
    state: {
      stateFormPets: {
        insuranceSum,
        promoCode,
        risks,
        sublimits,
        selectedPetData: {
          name: petName = '',
          age: petAge = '',
          gender: petGender = '',
          breed: petBreed = '',
          kind: petKind = '',
        } = {},
        selectedPetKindName,
        selectedDuration,
        selectedDate,
        selectedIProduct,
        getPrices,
      },
      stateAuth: { authTokens, displayPhoneNumber },
      stateUser: { profile },
    },
    dispatch,
  } = useContext(Store);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormPromoProps>(
    formInsurancePeriodDefaultValues
  );
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [submitPromoLabel, setSubmitPromoLabel] = useState<string>('Применить');
  const [orderArray, setOrderArray] = useState<OrderItemsBlockType[]>([]);

  // TODO: добавить аналитику по питомцам
  // useEffect(() => {
  //   sendAnalyticEvent(analyticEvents.);
  // }, []);

  const navigate = useNavigate();
  usePetsOrderData();

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrder,
      payload: orderArray,
    });
  }, [orderArray]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: { promoCode: promoCode },
  });

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const validatePage = useCallback(() => {
    navigate('/new-order-detail');

    return false;
  }, []);

  useNextStep(validatePage);

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: PetsActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    // TODO: добавить аналитику по питомцам
    // sendAnalyticEvent(analyticEvents.);
  };

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: PetsActionTypes.SetSelectedDuration,
      payload: val,
    });
    //TODO: добавить аналитику по питомцам
    // sendAnalyticEvent(analyticEvents.);
  };

  const {
    isLoading: profileIsLoading,
    error: profileError,
    res: profileRes,
    refetch: profileRefetch,
  } = useRequest(
    'userGetProfile',
    'post',
    '/v1/user/get-profile',
    {},
    // [authTokens?.authorization?.accessToken, profile], // TODO: после тестирования
    [profile],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading,
    isError,
    res: [getPricesData, getInsuranceLimitStartData],
    refetchAll,
  } = useRequests([
    {
      key: 'petsFormGetPricesFull',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        productCode: selectedIProduct?.code,
        risks: risksArray,
        pets: {
          kind: petKind,
          age: petAge,
          coverages: sublimits?.risks
            ? sublimitRisksConverter(sublimits.risks)
            : [],
        },
        returnMinDuration: false,
        promoCode: authTokens?.authorization?.accessToken && promoCode,
        insuranceSum: Number(insuranceSum),
      },
      deps: [
        risks,
        sublimits,
        petKind,
        petAge,
        promoCode,
        formData.promoCode,
        authTokens?.authorization?.accessToken,
      ],
      accessToken: authTokens?.authorization?.accessToken,
    },
    {
      key: 'formIFLGetInsuranceLimitStart',
      method: 'post',
      url: '/v1/references/get-insurance-limit-start',
      request: {
        productCode: selectedIProduct?.code,
      },
      deps: [risks],
    },
  ]);

  useEffect(() => {
    if (profileIsLoading || isLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [profileIsLoading, isLoading]);

  useEffect(() => {
    if (!profileIsLoading && profileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: profileRes,
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [profileRes, profileIsLoading]);

  useEffect(() => {
    setPaymentPageTexts(
      'Если с вашим питомцем что-то случится, свяжитесь с нами в приложении «Пульс Жизни»',
      selectedDate
    );

    const selectedPrice = getPrices?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDelta;

    const selectedPromoPrice = getPrices?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0].premiumAndDeltaPromo;

    let label = `${
      selectedPrice
        ? currencyRuLocaleWithTwoFractionDigits(Number(selectedPrice))
        : ''
    }${PetsPeymentPeriodLabels.get(selectedDuration!)?.label}`;

    let subLabel = '';
    const isSuccessfulPromo = getPrices?.isSuccessfulPromo;

    if (!!selectedPromoPrice) {
      label = `${currencyRuLocaleWithTwoFractionDigits(
        Number(selectedPromoPrice)
      )}${PetsPeymentPeriodLabels.get(selectedDuration!)?.promoLabel}`;
      subLabel =
        `${
          PetsPeymentPeriodLabels.get(selectedDuration!)?.promoSubLabel
        } ${selectedPrice} ₽` || '';
    }

    const name = [
      profileRes?.profile?.lastName,
      profileRes?.profile?.firstName,
      profileRes?.profile?.middleName !== ''
        ? profileRes?.profile?.middleName
        : null,
    ].join(' ');

    const tempOrderArray: OrderItemsBlockType[] = [
      {
        type: OrderItemValueType.BLOCK,
        title: 'Личные данные',
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Страхователь',
            value: name,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Номер телефона',
            value: displayPhoneNumber,
          },
        ],
      },
      {
        type: OrderItemValueType.BLOCK,
        title: 'Данные питомца',
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Питомец',
            value: `${selectedPetKindName} • ${petGender} • ${getFormattedAge(
              petAge
            )}`,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Порода питомца',
            value: petBreed,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Как зовут питомца',
            value: petName,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Основные риски',
            value: getMainRisksToString(risks),
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Дополнительные риски',
            value: getAdditionalRisksToString(risks) || 'Не выбраны',
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Стоимость покрытия',
            value: `${numFormat(insuranceSum)} ₽`,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Стоимость и периодичность оплаты',
            value: label,
            secondsValue: subLabel,
            successValue: isSuccessfulPromo ? 'Промокод применен' : '',
            failedValue:
              isSuccessfulPromo === undefined || isSuccessfulPromo === null
                ? ''
                : 'Промокод не применен',
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: 'Подписка начнёт действовать',
            value: `${selectedDate?.toLocaleDateString('ru-RU', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}`,
          },
        ],
      },
    ];

    setOrderArray(tempOrderArray);
  }, [
    getPrices?.prices,
    selectedDuration,
    risks,
    insuranceSum,
    selectedDate,
    displayPhoneNumber,
    profileRes,
    selectedPetKindName,
    petGender,
    petAge,
    petBreed,
    petName,
  ]);

  useEffect(() => {
    if (!isLoading && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: PetsActionTypes.SetGetPrices,
        payload: getPricesData.data as GetPrices,
      });
      if (selectedDuration === null) {
        dispatch({
          type: PetsActionTypes.SetSelectedDuration,
          payload: getPricesData?.data?.prices[0]?.duration as SelectedDuration,
        });
      }
      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        formData.promoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'Такого промокода нет или он больше не действует',
        });
        dispatch({
          type: PetsActionTypes.SetPromoCode,
          payload: undefined,
        });
      }
    }
  }, [isLoading, getPricesData.data]);

  useEffect(() => {
    if (!isLoading && getInsuranceLimitStartData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      setMinDate(new Date(getInsuranceLimitStartData.data?.startDate));
      setMaxDate(new Date(getInsuranceLimitStartData.data?.endDate));
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate || new Date(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [isLoading, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (formData.promoCode) {
      dispatch({
        type: PetsActionTypes.SetPromoCode,
        payload: formData.promoCode,
      });
      refetchAll();
    }
  }, [formData.promoCode]);

  useEffect(() => {
    const isPromocodeAppliedSuccessfully =
      getPricesData.data?.isSuccessfulPromo && promoCode !== '';

    setSubmitPromoDisabled(!!isPromocodeAppliedSuccessfully);

    setSubmitPromoLabel(
      isPromocodeAppliedSuccessfully ? 'Применён' : 'Применить'
    );
  }, [getPricesData.data, promoCode]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPage = handleSubmit((data) => {
    dispatch({
      type: PetsActionTypes.SetPromoCode,
      payload: data.promoCode,
    });
    setFormData(data);
  });

  if (profileIsLoading || isLoading) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={refetchAll} />;
  }

  if (profileError) {
    const e = (profileError as any).response.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={profileRefetch} />;
  }

  return (
    <Container>
      <FormLabel marginBottom={24}>Периодичность оплаты</FormLabel>
      <PaymentPeriodsListWrapper>
        <NewRadioGroup
          name="duration"
          onChange={onRadioSelector}
          selectedValue={selectedDuration}
        >
          {getPrices?.prices &&
            getPrices?.prices.map((item) => (
              <NewRadio
                key={`${item.duration}-key`}
                value={item.duration}
                label={RadioLabelRender(item)}
                description={
                  PetsPeymentPeriodLabels.get(item.duration)?.description
                }
              />
            ))}
        </NewRadioGroup>
      </PaymentPeriodsListWrapper>
      {authTokens?.authorization?.accessToken ? (
        <PromoWrapper>
          <Container>
            <MiddleNameWrapper>
              <WrapperWithFlexibleGrow>
                <Controller
                  control={control}
                  name="promoCode"
                  render={({ field: { onChange, value }, fieldState }) => (
                    <HelperText
                      status={fieldState.error ? 'error' : 'success'}
                      message={
                        errors.promoCode?.message ||
                        (getPricesData.data?.isSuccessfulPromo &&
                        promoCode !== ''
                          ? 'Скидка на первый период подписки'
                          : '')
                      }
                    >
                      <Input
                        label="Промокод"
                        value={value}
                        onChange={(val) => {
                          checkSubmitState(val);
                          return onChange(val);
                        }}
                        error={!!errors.promoCode}
                        disabled={isLoading}
                      />
                    </HelperText>
                  )}
                />
              </WrapperWithFlexibleGrow>
              <PromoSubmitButton>
                <Button
                  label={submitPromoLabel}
                  onClick={submitPage}
                  disabled={submitPromoDisabled}
                  themeColor="white"
                />
              </PromoSubmitButton>
            </MiddleNameWrapper>
          </Container>
        </PromoWrapper>
      ) : (
        <PromoWrapper>
          <WarnigWrapper>
            <Info width={24} />
            <WarnigText>
              Промокод можно ввести после авторизации на следующем шаге
            </WarnigText>
          </WarnigWrapper>
        </PromoWrapper>
      )}
      <FormSubTitle>Подписка станет активна</FormSubTitle>
      <HelperText
        message={
          dateEqual
            ? ''
            : 'Выберите дату начала подписки. Самое раннее — через 7 дней с текущего дня'
        }
        status="default"
        messagePosition="down"
      >
        {isLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} />
            <WarnigText>
              Подписка начнет действовать{' '}
              {minDate.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </HelperText>
    </Container>
  );
};
