import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppContent } from '@src/common-components/container';
import { ThemeContainer } from '@src/components';
import { WebAnalytic } from '@src/components/web-analytic';
import {
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
} from '@src/constants';
import {
  AuthorizationConfirmPage,
  AuthorizationPage,
  AuthorizeFailPage,
  IdentificationPage,
  IsSubscriptionExistsPage,
  NewAuthorizationConfirmPage,
  NewAuthorizationPage,
  NewAuthorizeFailPage,
  NewIdentificationPage,
  PersonalInfoPage,
  RegistrationPage,
  SubscriptionChoice,
} from '@src/pages/authorization';
import {
  BestToPayErrorPage,
  BestToPayInProgressPage,
  BestToPayStartPage,
  BestToPaySuccessPage,
} from '@src/pages/best-to-pay';
import { IFLFlatRiskDescriptions } from '@src/pages/ifl-flat-form/form-insurance-risk-descriptions';
import { IFLFlatPage } from '@src/pages/ifl-flat-form/ifl-flat-page';
import { IFLHouseMaterialsDescriptions } from '@src/pages/ifl-house-form/form-insurance-materials';
import { IFLHouseRiskDescriptions } from '@src/pages/ifl-house-form/form-insurance-risk-descriptions';
import { IFLHousePage } from '@src/pages/ifl-house-form/ifl-house-page';
import { NSPage } from '@src/pages/ns-form/ns-page';
import {
  NewOrderCheckout,
  NewOrderDetail,
  OrderCheckout,
  OrderDetail,
} from '@src/pages/order';
import { PetsPage, PetsRiskDescriptions } from '@src/pages/pets-form';
import { ScoreErrorPage } from '@src/pages/score-error';
import { VipPage } from '@src/pages/vip';
import { StoreProvider } from '@src/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => (
  <StoreProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeContainer>
          <AppContent>
            <WebAnalytic />
            <Routes>
              <Route path="/" element={<IsSubscriptionExistsPage />} />
              <Route path="/house" element={<IFLHousePage />} />
              <Route path="/apartment" element={<IFLFlatPage />} />
              <Route
                path="/house-materials-descriptions"
                element={<IFLHouseMaterialsDescriptions />}
              />
              <Route
                path="/house-risk-descriptions"
                element={<IFLHouseRiskDescriptions />}
              />
              <Route
                path="/flat-risk-descriptions"
                element={<IFLFlatRiskDescriptions />}
              />
              <Route path="/ns" element={<NSPage />} />
              <Route path="/score-error" element={<ScoreErrorPage />} />
              <Route path="/login" element={<IdentificationPage />} />
              <Route path="/new-login" element={<NewIdentificationPage />} />
              <Route
                path="/new-authorize-confirm"
                element={<NewAuthorizationConfirmPage />}
              />
              <Route path="/authorize" element={<AuthorizationPage />} />
              <Route path="/new-authorize" element={<NewAuthorizationPage />} />
              <Route path="/personal-info" element={<PersonalInfoPage />} />
              <Route
                path="/subscription-choice"
                element={<SubscriptionChoice />}
              />
              <Route
                path="/authorize-confirm"
                element={<AuthorizationConfirmPage />}
              />
              <Route path="/authorize-fail" element={<AuthorizeFailPage />} />
              <Route
                path="/new-authorize-fail"
                element={<NewAuthorizeFailPage />}
              />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path={PAYMENT_ROUTE} element={<BestToPayStartPage />} />
              <Route
                path={PAYMENT_ERROR_ROUTE}
                element={<BestToPayErrorPage />}
              />
              <Route
                path={PAYMENT_SUCCESS_ROUTE}
                element={<BestToPaySuccessPage />}
              />
              <Route
                path={PAYMENT_IN_PROGRESS_ROUTE}
                element={<BestToPayInProgressPage />}
              />
              <Route path="/order-detail" element={<OrderDetail />} />
              <Route path="/new-order-detail" element={<NewOrderDetail />} />
              <Route path="/order-checkout" element={<OrderCheckout />} />
              <Route
                path="/new-order-checkout"
                element={<NewOrderCheckout />}
              />
              <Route path="/pets" element={<PetsPage />} />
              <Route
                path="/pets-risk-descriptions"
                element={<PetsRiskDescriptions />}
              />
              <Route path="/vip" element={<VipPage />} />
            </Routes>
          </AppContent>
        </ThemeContainer>
      </BrowserRouter>
    </QueryClientProvider>
  </StoreProvider>
);

export default App;
