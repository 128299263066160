import Axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { AuthActionTypes, Store } from '@src/store';
import type { Url } from '@src/types';
import { refreshToken } from '@src/utils';

export const useDownloadPdf = (
  key: string,
  url: Url,
  authToken: string,
  deps?: any[]
) => {
  const {
    state: {
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  Axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired

        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          authTokens
        ) {
          originalConfig._retry = true;

          dispatch({
            type: AuthActionTypes.SetAuthTokens,
            payload: undefined,
          });

          try {
            const rs = await refreshToken(authTokens);

            if (rs?.data) {
              return dispatch({
                type: AuthActionTypes.SetAuthTokens,
                payload: rs.data,
              });
            }

            return Promise.reject(err);
          } catch (_error: any) {
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 403 && err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );

  const headers = {
    'X-Process-ID': uuidv4(),
    Accept: 'application/json',
    'Content-Type': 'application/pdf',
  };

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const { isLoading, error, data, refetch } = useQuery(
    [key, deps],
    () =>
      Axios.post(
        `${window.envUrls.BASEURL}/v1/file/${url}`,
        {},
        {
          responseType: 'arraybuffer',
          headers: authToken ? authHeaders : headers,
        }
      ),
    {
      enabled: false,
    }
  );

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return { isLoading, error, res, refetch };
};
