import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { AdaptiveColumns } from '@src/components/container';
import { useNsDetailsInsured } from '@src/pages/ns-form/hooks';
import { InsurancePersonsForm } from '@src/types';
import { calculateAge } from '@src/utils';

import {
  InsurancePersonFormButton,
  InsurancePersonFormCheckbox,
  InsurancePersonFormContainer,
  InsurancePersonFormContent,
  InsurancePersonFormHead,
  InsurancePersonFormNumber,
  InsurancePersonFormSubTitle,
  InsurancePersonFormTitle,
  InsurancePersonFormTitleBox,
} from './insurance-person-form.styles';
import { InsurancePersonFormProps } from './insurance-person-form.types';
import {
  getDatepickerPeriod,
  getInsurancePersonSubTitle,
  getInsurancePersonTitle,
  isVisibleIsMeButton,
} from './utils';

export const InsurancePersonForm = ({
  personsIndex,
  ageMin,
  handleSelectionMe,
  effectiveSince,
  profileData,
  lockedFields,
}: InsurancePersonFormProps) => {
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    trigger,
    setValue,
  } = useFormContext<InsurancePersonsForm>();
  const personErrors = errors.persons?.[personsIndex];

  const { profileDataForForm } = useNsDetailsInsured();

  const isMe = getValues(`persons.${personsIndex}.isMe`);
  const userAge = profileDataForForm?.birthDate
    ? calculateAge(new Date(profileDataForForm.birthDate))
    : null;
  const isMeVisible = userAge
    ? isVisibleIsMeButton(ageMin, userAge)
    : ageMin !== 1;

  const handleOnChangeNoPatronymic = (value: boolean) => {
    if (!value && !!personErrors?.middleName?.message) {
      trigger();
    }

    setValue(`persons.${personsIndex}.middleName`, '');
  };

  return (
    <InsurancePersonFormContainer>
      <InsurancePersonFormHead>
        <InsurancePersonFormTitleBox>
          <InsurancePersonFormNumber>
            {personsIndex + 1}
          </InsurancePersonFormNumber>
          <InsurancePersonFormTitle>
            {getInsurancePersonTitle(ageMin)}
          </InsurancePersonFormTitle>
          <InsurancePersonFormSubTitle>
            {getInsurancePersonSubTitle(ageMin)}
          </InsurancePersonFormSubTitle>
        </InsurancePersonFormTitleBox>
        {isMeVisible && (
          <Controller
            control={control}
            name={`persons.${personsIndex}.isMe`}
            render={({ field: { onChange, value } }) => (
              <InsurancePersonFormButton
                active={value}
                onClick={() => {
                  onChange(!value);
                  handleSelectionMe(personsIndex);
                }}
              >
                это я
              </InsurancePersonFormButton>
            )}
          />
        )}
      </InsurancePersonFormHead>
      <InsurancePersonFormContent>
        <AdaptiveColumns>
          <Controller
            control={control}
            name={`persons.${personsIndex}.lastName`}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={fieldState.error?.message}
              >
                <Input
                  label="Фамилия"
                  value={value}
                  disabled={isMe && lockedFields?.lastName}
                  error={!!fieldState.error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </HelperText>
            )}
          />

          <Controller
            control={control}
            name={`persons.${personsIndex}.firstName`}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={fieldState.error?.message}
              >
                <Input
                  label="Имя"
                  value={value}
                  disabled={isMe && lockedFields?.firstName}
                  error={!!fieldState.error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </HelperText>
            )}
          />

          <Controller
            control={control}
            name={`persons.${personsIndex}.middleName`}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={fieldState.error?.message}
              >
                <Input
                  label="Отчество"
                  value={value}
                  disabled={
                    watch(`persons.${personsIndex}.useMiddleName`) ||
                    (isMe && lockedFields?.middleName)
                  }
                  error={!!fieldState.error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </HelperText>
            )}
          />

          <Controller
            control={control}
            name={`persons.${personsIndex}.useMiddleName`}
            render={({ field: { onChange, value } }) => (
              <InsurancePersonFormCheckbox
                checked={value}
                disabled={isMe && lockedFields?.middleName}
                onChange={(e) => {
                  onChange(e);
                  handleOnChangeNoPatronymic(value);
                }}
                label={<>У застрахованного нет отчества</>}
              />
            )}
          />

          <HelperText
            status={(personErrors?.birthDate?.message && 'error') || undefined}
            message={personErrors?.birthDate?.message}
          >
            <Controller
              control={control}
              name={`persons.${personsIndex}.birthDate`}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  selected={value ? new Date(value) : undefined}
                  onChange={onChange}
                  showMonthDropdown
                  showYearDropdown
                  disabled={
                    (isMe && !!profileData?.birthDate) ||
                    (isMe && lockedFields?.birthDate)
                  }
                  label="Дата рождения"
                  error={!!personErrors?.birthDate?.message}
                  {...getDatepickerPeriod(ageMin, effectiveSince)}
                />
              )}
            />
          </HelperText>
        </AdaptiveColumns>
      </InsurancePersonFormContent>
    </InsurancePersonFormContainer>
  );
};
