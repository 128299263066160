import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { ListRangeItem } from '@pulse-web-ui/list-range-item';

import {
  AdaptiveHeadersWrapper,
  AdaptivePerMonthFooter,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  IflSumPerMonthContainer,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { GlobalErrorInfo, IflSumPerMonth, TotalSum } from '@src/features';
import { IflSumPerMonthError } from '@src/features/ifl-sum-per-month';
import { useNextStep, useRequest } from '@src/hooks';
import { PetRisk, PetsActionTypes, Store } from '@src/store';
import { Coverage, Sublimits } from '@src/store/pets/pets-store.types';
import { WizardActionTypes } from '@src/store/wizard';
import { numBetween, numFormat } from '@src/utils';

import { sublimitRisksConverter } from './utils';

const schemaObj: Record<string, any> = {};
const defaults: Record<string, number> = {};
const LIABILITY_2 = 'Liability_2';

export const PetsInsuranceSum: FC = () => {
  const {
    state: {
      stateFormPets: { selectedIProduct, risks, selectedPetData, sublimits },
      stateWizard: { currentStep },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const [schema, setSchema] = useState<any>();
  const [currentSum, setCurrentSum] = useState(250_000);
  const [currentSumMinLimit, setCurrentSumMinLimit] = useState('0');
  const [currentSumMaxLimit, setCurrentSumMaxLimit] = useState('0');
  const [currentSumErr, setCurrentSumErr] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [newSublimits, setNewSublimits] = useState<Coverage[] | undefined>();

  useEffect(() => {
    // TODO: добавить аналитику по питомцам
    // sendAnalyticEvent(analyticEvents.);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const isError = (code: string) =>
    code === LIABILITY_2 &&
    newSublimits?.length === 1 &&
    newSublimits[0].code === LIABILITY_2
      ? true
      : false;

  const getErrorMessage = (code: string) =>
    code === LIABILITY_2
      ? 'Гражданская ответсвенность не может быть застрахована без покрытия хотя бы одной категории'
      : undefined;

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    if (sublimits?.risks) {
      setNewSublimits(sublimitRisksConverter(sublimits?.risks));
    }
  }, [sublimits?.risks]);

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: (errors as any).inner.reduce(
              (allErrors: any, currentError: ValidationError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const resolver = useYupValidationResolver(schema);

  const { control, formState, reset, trigger } = useForm<
    Record<string, number>
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...defaults,
    },
  });

  const updateLimits = async (code: string, value: number) => {
    setStepUpdated();
    const valid = await trigger(code);

    if (valid) {
      const updatedSublimits: Sublimits = {
        risks: [],
      };

      sublimits?.risks.map((item) => {
        if (item.code === code) {
          item.defaultInsuranceSum = String(value);
        }
        updatedSublimits.risks.push(item);
      });

      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: updatedSublimits,
      });

      let totalSum = 0;

      updatedSublimits.risks.map(
        (risk: any) => (totalSum += Number(risk.defaultInsuranceSum))
      );

      setCurrentSum(totalSum);
      setSelectedId(undefined);
    }
  };

  const { isLoading, error, res, refetch } = useRequest(
    'petsFormGetSublimits',
    'post',
    '/v2/references/get-sublimits',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
      risks: risksArray,
    },
    [risks, selectedPetData?.kind, selectedIProduct?.code]
  );
  const {
    isFetching: isFetchingSumPerQuarter,
    error: errorSumPerQuarter,
    res: resSumPerQuarter,
    refetch: refetchSumPerQuarter,
  } = useRequest(
    'petsFormGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      insuranceSum: Number(currentSum),
      productCode: selectedIProduct?.code,
      returnMinDuration: true,
      risks: risksArray,
      pets: {
        kind: selectedPetData?.kind,
        age: selectedPetData?.age,
        coverages: newSublimits,
      },
    },
    [
      currentSum,
      selectedIProduct?.code,
      risks,
      selectedPetData?.kind,
      selectedPetData?.age,
      newSublimits,
      authTokens?.authorization?.accessToken,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  const validatePage = useCallback(
    () => !selectedId && !currentSumErr,
    [selectedId, currentSumErr]
  );

  const handleRefetch = () => {
    if (error) refetch();
    if (errorSumPerQuarter) refetchSumPerQuarter();
  };

  useNextStep(validatePage);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (newSublimits) refetchSumPerQuarter();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [newSublimits]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: res,
      });

      let totalSum = 0;

      setCurrentSumMinLimit(
        res.totalMinLimit ?? selectedIProduct?.minProductLimit ?? '0'
      );

      setCurrentSumMaxLimit(
        res.totalMaxLimit ?? selectedIProduct?.maxProductLimit ?? '0'
      );

      res.risks.map((risk: PetRisk) => {
        schemaObj[risk.code] = yup
          .number()
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .typeError('Вы должны ввести число')
          .min(
            Number(risk.minLimit),
            `Сумма не менее ${Number(risk.minLimit)}₽`
          )
          .max(
            Number(risk.maxLimit),
            `Сумма не более ${Number(risk.maxLimit)}₽`
          )
          .integer('Сумма должна быть целым значением')
          .required();

        totalSum += Number(risk.defaultInsuranceSum);
        defaults[risk.code] = Number(risk.defaultInsuranceSum);
      });

      const newSchema = yup.object(schemaObj);
      setSchema(newSchema);

      setCurrentSum(totalSum);
      reset(defaults);
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (currentSum > 0) {
      dispatch({
        type: PetsActionTypes.SetInsuranceSum,
        payload: Number(currentSum),
      });
    }
  }, [currentSum]);

  useEffect(() => {
    setCurrentSumErr(
      !numBetween(
        currentSum,
        Number(currentSumMinLimit),
        Number(currentSumMaxLimit),
        true
      )
    );
  }, [currentSum, currentSumMinLimit, currentSumMaxLimit]);

  useEffect(() => {
    const disableNav =
      (!!selectedId || currentSumErr) && (currentSumErr || !!selectedId);
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: disableNav,
    });
  }, [selectedId, currentSumErr, currentStep]);

  useEffect(() => {
    const disableNav = Boolean(selectedId);

    dispatch({
      type: WizardActionTypes.SetBackNavDisabled,
      payload: disableNav,
    });
  }, [selectedId]);

  if (isLoading) return <Skeleton />;

  if (
    error ||
    (errorSumPerQuarter &&
      (errorSumPerQuarter as any)?.response?.data.code !== 'VALIDATION_ERROR' &&
      (errorSumPerQuarter as any)?.response?.data.code !== 'BUSINESS_ERROR' &&
      (errorSumPerQuarter as any)?.response?.data.code !== 'TECHNICAL_ERROR')
  )
    return (
      <GlobalErrorInfo pending={isLoading} retrayHandler={handleRefetch} />
    );

  if (!isLoading && res?.risks?.length === 0)
    return (
      <div>
        <h3>Список пуст</h3>
      </div>
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          Выберите страховые суммы
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          Они определяют максимальную выплату при наступлении страхового события
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      <AdaptiveSliderListWrapper>
        {sublimits?.risks.map((item: PetRisk) => (
          <Controller
            key={`${item.code}-key`}
            name={item.code}
            control={control}
            render={({ field }) => (
              <ListRangeItem
                label={item.name}
                value={Number(item.defaultInsuranceSum)}
                min={Number(item.minLimit)}
                max={Number(item.maxLimit)}
                step={Number(item.step)}
                description={item.description}
                itemIcon={item.imageUrl}
                status={{
                  type:
                    (formState.errors[item.code]?.message && 'error') ||
                    'default',
                  message: formState.errors[item.code]?.message,
                }}
                onChange={(value: number) => {
                  field.onChange(value);
                  updateLimits(item.code, value);
                }}
                setSelectedId={() => {
                  setSelectedId(item.code);
                }}
                id={item.code}
                disabled={!!selectedId && selectedId !== item.code}
                isOpen={selectedId === item.code}
                isError={isError(item.code)}
                errorMessage={getErrorMessage(item.code)}
                isInputEditable={false}
                isInfoEnable={false}
              />
            )}
          />
        ))}
      </AdaptiveSliderListWrapper>
      {sublimits?.risks && (
        <AdaptivePerMonthFooter>
          <SumWrapper>
            <TotalSum
              title="Общая сумма покрытия"
              totalSum={numFormat(currentSum)}
              isError={currentSumErr}
              subtitleText={
                `от ${numFormat(Number(currentSumMinLimit))} ₽ ` +
                `до ${numFormat(Number(currentSumMaxLimit))} ₽`
              }
            />
          </SumWrapper>
          <SumWrapper>
            <IflSumPerMonthContainer>
              {(currentSum || currentSum === 0) && newSublimits && (
                <IflSumPerMonth
                  isLoading={isFetchingSumPerQuarter || !!errorSumPerQuarter}
                  disabled={isLoading || !!selectedId}
                  sumPerMonth={Number(
                    resSumPerQuarter?.prices[0].premiumAndDelta
                  )}
                  desktopTitle="Стоимость страховки в квартал"
                  mobileTitle="Стоимость страховки в квартал"
                />
              )}
              {errorSumPerQuarter && (
                <IflSumPerMonthError
                  errorResponse={errorSumPerQuarter}
                  currentSumErr={
                    currentSumErr &&
                    (currentSum < Number(currentSumMinLimit) ? 'less' : 'gt')
                  }
                />
              )}
            </IflSumPerMonthContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
      )}
    </Container>
  );
};
