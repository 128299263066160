// regex для телефонов на +7, 8
export const phoneRegEx =
  /^(\+7|8)?[\s\-]?\(?[4890][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

//+70005501205

// regex для фамилий
// export const nameRegEx = /^[^a-zA-Z0-9]/;
export const nameRegEx = /^[\-а-яА-ЯёЁ0-9\\'\\''-.\s^]+$/;
export const notDigital = /^[^\d]/;

export const promoEx = /^[A-Za-zА-Яа-яЁё0-9. _]{1,20}$/;

export const petNameRegEx = /^[A-Za-zА-Яа-яЁё0-9. _-]{1,40}$/;
