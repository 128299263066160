export enum NSActionTypes {
  SetInsuranceProduct = 'NS/SET_INSURANCE_PRODUCT',
  SetAgeRatios = 'NS/SET_AGE_RATIOS',
  SetNumberInsurePersons = 'NS/SET_NUMBER_INSURE_PERSONS',
  SetRisks = 'NS/SET_RISKS',
  SetSelectedMainRisks = 'NS/SET_SELECTED_MAIN_RISKS',
  SetSelectedAdditionalRisks = 'NS/SET_SELECTED_ADDITIONAL_RISKS',
  SetInsuranceSum = 'NS/SET_INSURANCE_SUM',
  SetSelectedDate = 'NS/SET_SELECTED_DATE',
  SetSelectedDuration = 'NS/SET_SELECTED_DURATION',
  SetGetPrices = 'NS/SET_GET_PRICES',
  SetSubscriptionDateLimit = 'NS/SET_SUBSCRIPTION_DATE_LIMIT',
  SetInsurePersons = 'NS/SET_INSURE_PERSONS',
  SetPromoCode = 'IFL/SET_PROMO_CODE',
}
