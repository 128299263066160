import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  DatePickerIcon,
  HorizontalRegulatorIcon,
  RangeLimitsIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';

import {
  FormSub,
  FormSubLabel,
  GridColumnContainer,
  HeaderAdaptive1,
  Skeleton,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, iflHouseRoute } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';
import { IconColumnTitle } from './vip-page.styles';

// TODO: изменить на цвет из темы после ее обновления
const bgColor = '#F1F2F6';

export const VipPage: FC = () => {
  const {
    state: {
      stateUser: { profile },
      stateAuth: { authorizeRefRoute, authTokens },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();

  const {
    isLoading: profileIsLoading,
    error: profileError,
    res: profileRes,
    refetch: profileRefetch,
  } = useRequest(
    'userGetProfile',
    'post',
    '/v1/user/get-profile',
    {},
    // [authTokens?.authorization?.accessToken, profile],  // TODO: после тестирования убрать
    [profile],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!profile?.profile.firstName) {
      profileRefetch();
    }
  }, []);

  useEffect(() => {
    if (!profileIsLoading && profileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: profileRes,
      });
    }
  }, [profileRes, profileIsLoading]);

  if (profileIsLoading) return <Skeleton />;

  if (profileError) {
    const e = (profileError as any).response.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      navigate('/new-authorize-fail');
    }

    return <GlobalErrorInfo retrayHandler={profileRefetch} />;
  }

  return (
    <BaseLayout verticalAlignment={false}>
      <GridColumnContainer gridStep={3}>
        <UniversalColumn align="left" gap={0}>
          <HeaderAdaptive1>
            Поздравляем, {profile?.profile.firstName}!
          </HeaderAdaptive1>
          <FormSubLabel>
            Как привилегированному клиенту СК «Пульс», вам доступны особые
            условия подписки на защиту в нашем приложении «Пульс Жизни»
          </FormSubLabel>
        </UniversalColumn>
        <FormSub margin="0 0 40px ">
          {isBrowser ? (
            <DisplayQRButton />
          ) : (
            <Button
              adaptiveWidth
              themeColor="primary"
              label="Скачать приложение"
              onClick={() => {
                sendAnalyticEvent(analyticEvents.authVIPapp);
                return window.open(
                  window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK,
                  '_blank'
                );
              }}
            />
          )}
        </FormSub>
      </GridColumnContainer>
      <UniversalColumnsWrapper>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <RangeLimitsIcon />
          <IconColumnTitle>Сумма покрытия до 60 млн ₽</IconColumnTitle>
          <div>
            Расширенный лимит суммы покрытия, от которого зависит максимальный
            размер выплаты при страховом событии
          </div>
        </UniversalColumn>
        {authorizeRefRoute === iflHouseRoute && (
          <UniversalColumn align="left" bgColor={bgColor} gap={16}>
            <HorizontalRegulatorIcon />
            <IconColumnTitle>Отдельный выбор покрытия</IconColumnTitle>
            <div>
              Теперь покрытие конструктивных элементов дома и его отделки
              и инженерных систем выбирается отдельно
            </div>
          </UniversalColumn>
        )}
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <DatePickerIcon />
          <IconColumnTitle>Выбор даты начала действия полиса</IconColumnTitle>
          <div>
            Например, при желании избежать перекрытия периодов страхования,
            если ваша недвижимость уже застрахована
          </div>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
