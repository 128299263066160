import {
  PetData,
  SelectedPetData,
  Sublimits,
} from '@src/store/pets/pets-store.types';
import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubProduct,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type InsuranceSum = number;

export type InsuranceScheme = Record<string, string[]>;

export type SelectedIProduct = undefined | InsuranceProductType;

export type InsuranceSubproducts = {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
};

export interface PetsStoreState {
  selectedIProduct?: InsuranceProductType;
  risks?: Risk[];
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  promoCode?: string;
  risksPage: 'primary' | 'secondary';
  pets: PetData[];
  selectedPetData?: SelectedPetData;
  sublimits?: Sublimits;
  selectedPetKindName?: string;
}

export const initPetsStoreState: PetsStoreState = {
  selectedIProduct: undefined,
  risks: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  selectedDuration: null,
  selectedDate: null,
  promoCode: undefined,
  risksPage: 'primary',
  pets: [],
  selectedPetData: undefined,
  sublimits: undefined,
  selectedPetKindName: undefined,
};
