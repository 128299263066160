import { colors } from '@pulse-web-ui/theme';

export const ArrowLeftIcon2 = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8.66406H1M1 8.66406L8 15.6641M1 8.66406L8 1.66406"
      stroke={colors.secondaryGray2}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
