import styled from 'styled-components';

import { colors, typography } from '@pulse-web-ui/theme';

export const SubTitle = styled.div`
  ${typography.regular18}
  color: ${colors.darkBlue2};
  margin: 0;
`;

export const Points = styled.ul`
  margin-bottom: 24px;
`;

export const MaterialFullDescriptionWrapper = styled.div`
  margin-bottom: 16px;
`;
