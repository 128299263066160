export const clearLocalStorage = () => {
  localStorage.removeItem('selectedProduct');
  localStorage.removeItem('clientCategory');
  localStorage.removeItem('email');
  localStorage.removeItem('selectedDate');
  localStorage.removeItem('startDate');
  localStorage.removeItem('operationId');
  localStorage.removeItem('referenceSuccess');
  localStorage.removeItem('retryPayment');
  localStorage.removeItem('successText');
};
