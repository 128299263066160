import styled from 'styled-components';

import { colors, typography } from '@pulse-web-ui/theme';

export const PromoSubmitButton = styled.div`
  ${typography.bold18};

  span {
    color: ${colors.darkBlue2} !important;
  }

  div div {
    border: 1px solid ${colors.neutral30};
    transition: 300ms ease all;

    &:hover {
      border-color: ${colors.secondaryLightGray};
    }
  }
`;
