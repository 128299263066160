export enum AuthActionTypes {
  SetPhoneNumber = 'AUTH/SET_PHONE_NUMBER',
  SetDisplayPhoneNumber = 'AUTH/SET_DISPLAY_PHONE_NUMBER',
  SetAuthenticationToken = 'AUTH/SET_AUTHENTICATION_TOKEN',
  SetConfirmationId = 'AUTH/SET_CONFIRMATION_ID',
  SetAuthorizationToken = 'AUTH/SET_AUTHORIZATION_TOKEN',
  SetRegistrationToken = 'AUTH/SET_REGISTRATION_TOKEN',
  SetAuthorizeFailState = 'AUTH/SET_AUTHORIZE_FAIL_STATE',
  SetAuthorizeRefRoute = 'AUTH/SET_AUTHORIZE_REF_ROUTE',
  SetAuthTokens = 'AUTH/SET_AUTH_TOKENS',
}

export type AuthorizeFailState = {
  title: string;
  subtitle: string;
  refRoute: string;
};

export type AuthTokens = {
  authorization: {
    accessToken: string;
    refreshToken: string;
  };
  isVip: boolean;
};
