export enum PetsActionTypes {
  SetSelectedIProduct = 'PETS/SET_SELECTED_I_PRODUCT',
  SetRisks = 'PETS/SET_RISKS',
  SetInsuranceSum = 'PETS/SET_INSURANCE_SUM',
  SetGetPrices = 'PETS/SET_GET_PRICES',
  SetSelectedDuration = 'PETS/SET_SELECTED_DURATION',
  SetSelectedDate = 'PETS/SET_SELECTED_DATE',
  SetPromoCode = 'PETS/SET_PROMO_CODE',
  SetRisksPage = 'PETS/SET_RISKS_PAGE',
  SetPets = 'PETS/SET_PETS',
  SetSelectedPetData = 'PETS/SET_SELECTED_PET_DATA',
  SetSelectedPetKindName = 'PETS/SET_SELECTED_PET_KIND_NAME',
  SetSublimits = 'PETS/SET_SUBLIMITS',
}

interface Breed {
  breedCategory: string;
  breedNames: string[];
}

export interface PetData {
  kind: string;
  kindName: string;
  minAge: string;
  maxAge: string;
  breeds?: Breed[];
}

export interface Coverage {
  code: string;
  insuranceSum: string;
}

export interface SelectedPetData {
  kind: string;
  breed?: string;
  age: string;
  gender: string;
  name: string;
  coverages?: Coverage[];
}

export interface PetRisk {
  code: string;
  name: string;
  description: string;
  minLimit: string;
  maxLimit: string;
  defaultInsuranceSum: string;
  step: string;
  imageUrl: string;
}
export interface Sublimits {
  risks: PetRisk[];
  totalMinLimit?: string;
  totalMaxLimit?: string;
}
