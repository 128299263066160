import styled from 'styled-components';

import { colors, media, rgba } from '@pulse-web-ui/theme';

export const OtpContainer = styled.div`
  margin-bottom: 56px;
  ${media.desktop} {
    margin-bottom: 8px;
  }

  .otp-input {
    input {
      transition: border-color 0.2s ease-in-out;

      &:hover {
        border: 1px solid ${colors.secondaryLightGray} !important;
      }
    }
  }
`;

export const otpInputStyle = {
  width: 48,
  height: 56,
  marginRight: 8,
  boxSizing: 'border-box',
  borderRadius: 4,
  textAlign: 'center',
  outline: 'none',
  border: `1px solid ${rgba(colors.primary, 0.2)}`,
  fontFamily: 'Roboto Flex',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  color: `${colors.darkBlue2}`,
};

export const otpErrorStyle = {
  borderColor: `${colors.main}`,
  backgroundColor: `${colors.white}`,
  color: `${rgba(colors.darkBlue2, 0.4)}`,
};
