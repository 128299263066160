/* eslint-disable indent */
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

import { Alignment } from '../../pages/authorization/authorization.types';

type GridProps = {
  marginBottom?: number;
  gridStep?: number;
};

type OrderHeaderProps = {
  bgUrl?: string | ReactNode;
};

type UniversalColumnProps = {
  align?: string;
  bgColor?: string;
  gap?: number;
};

export const Container = styled.div`
  width: 100%;
`;

export const IflSumPerMonthContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const GridColumnContainer = styled.div<GridProps>`
  ${(props) => mixins.flex({ horizontal: false, gap: props.gridStep || 32 })}
  margin-bottom: ${(props) => props.marginBottom || 0}
}
`;

export const CheckboxGridItemWrapper = styled.div`
  align-items: flex-start;
  height: 32px;
  ${media.desktop} {
    padding-top: 20px;
    height: 56px;
  }
`;

export const AdaptiveListWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
`;

export const CheckboxGridItemWrapperWithoutTopPadding = styled.div<{
  height?: number;
}>`
  align-items: flex-start;
  height: 32px;
  ${media.desktop} {
    height: ${({ height }) => (height ? `${height}px` : '56px')};
  }
`;

export const MiddleNameWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
`;

export const FullWidthWrapper = styled.div`
  width: 100%;
`;

export const WrapperWithFlexibleGrow = styled.div`
  flex-grow: 1;
`;

export const AdaptiveListExtendedWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: 56px;
`;

export const PaymentPeriodsListWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8 })}
  margin-bottom: 24px;
`;
export const PromoWrapper = styled.div`
  margin-bottom: 48px;
`;

export const AdaptiveSliderListWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 40 })}
  padding: 0 20px;
  margin-bottom: 32px;

  ${media.desktop} {
    padding: 0;
  }
`;

export const AdaptiveHeadersWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 34px;

  ${media.desktop} {
    padding: 0;
    margin-bottom: 56px;
  }
`;

export const NumericInputWrapper = styled.div`
  margin-top: 32px;

  ${media.desktop} {
    margin-top: 24px;
  }
`;

export const NumericInputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
`;

export const AdaptiveContainerInverse = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 32,
  })};

  ${media.desktop} {
    gap: 0;

    ${mixins.flex({
      horizontal: false,
      reverse: true,
      gap: 0,
    })}
    & > div {
      margin-bottom: 32px;
    }

    & > :first-child {
      margin-bottom: 32px;
    }
  }
`;

export const CenterAlignWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
    gap: 16,
    justifyContent: 'center',
  })}
`;

export const AlignedWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
    gap: 16,
    justifyContent: 'flex-start',
  })}
`;

export const CenteredWrapper = styled.div<{
  alignment?: Alignment;
}>`
  ${({ alignment }) =>
    mixins.flex({
      alignItems: alignment ? alignment : 'center',
      horizontal: false,
    })}
  text-align: center;
`;

export const NumericInputContainer = styled.div`
  margin-bottom: 18px;
`;

export const ControllerContainer = styled.div`
  margin-bottom: 18px;
`;

export const NoWrapItem = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export const AdaptiveColumns = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: 16px;

  ${media.desktop} {
    ${mixins.flex({ horizontal: true })}
  }
}

& > div {
  ${media.desktop} {
    flex-basis: calc(50% - 8px);
  }
}
`;

export const UniversalColumnsWrapper = styled.div`
  width: 100%;
  ${mixins.flex({ horizontal: false, gap: 24 })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: false })}
  }
`;

export const UniversalColumn = styled.div<UniversalColumnProps>`
  ${(props) => mixins.flex({ horizontal: false, gap: props.gap })}
  box-sizing: border-box;
  width: 100%;
  text-align: ${(props) => props.align};
  background-color: ${(props) => props.bgColor};
  padding: ${(props) => (props.bgColor ? '16px 24px' : 0)};
  border-radius: ${(props) => (props.bgColor ? '8px' : 0)};
  color: ${colors.darkBlue2};
`;

export const OrderHeader = styled.div<OrderHeaderProps>`
  padding: 48px 16px 32px;
  background-size: cover;
  background-image: ${(props) =>
    props.bgUrl ? `url(${props.bgUrl})` : 'none'};
  border-radius: 16px;
`;

export const OrderTitle = styled.div`
  color: ${colors.darkBlue2};
  ${typography.medium24}
  line-height: 34px;
  margin-bottom: 8px;
`;

export const OrderSubTitle = styled.div`
  color: ${colors.secondaryGray2};

  ${typography.regular16}
  line-height: 20px;
`;

export const OrderWrapper = styled.div`
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 20px;
  margin-bottom: 16px;

  ${media.desktop} {
    border-color: transparent;
  }
`;

export const DocumentsWrapper = styled.div`
  padding-top: 12px;

  > div {
    padding-bottom: 0;
    margin-bottom: 16px;
  }
`;

export const WarnigWrapper = styled.div`
  background-color: #fff2cc;
  ${typography.regular16}
  ${mixins.flex({ horizontal: true, gap: 6, wrap: false })}
  color: ${colors.darkBlue2};
  border-radius: 16px;
  padding: 16px;
  & > svg {
    flex-shrink: 0;
  }
`;

export const InfoWrapper = styled.div`
  ${typography.regular16}
  ${mixins.flex({ horizontal: true, gap: 6, wrap: false })}
  color: ${colors.darkBlue2};
  border-radius: 16px;
  border: 1px solid ${colors.primary};
  padding: 16px;
  & > svg {
    flex-shrink: 0;
  }
`;

export const WarnigText = styled.div`
  padding: 3px 0;
`;

export const LinkContainer = styled.div`
  padding: 24px 0;
`;
