import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { RadioCardGroup } from '@pulse-web-ui/radio-card-group';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import { IFLHouseActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

import { useMaterials } from './use-materials';

const formSubText = 'Выберите хотя бы один тип дома';

export const FormInsuranceMaterials: FC = () => {
  const {
    state: {
      stateFormIFLHouse: {
        materials,
        selectedIProduct,
        selectedRegion,
        selectedBuildingMaterialCode,
      },
    },
    dispatch,
  } = useContext(Store);

  const [isRadioCardGroupError, setIsRadioCardGroupError] =
    useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflHouseMaterials);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const { isLoading, res, error, refetch } = useRequest(
    'formIFLHouseGetMaterials',
    'post',
    '/v1/references/get-materials',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
    },
    [selectedIProduct?.code, selectedRegion?.region]
  );

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !selectedBuildingMaterialCode || false,
    });

    setIsRadioCardGroupError(!selectedBuildingMaterialCode);

    return !!selectedBuildingMaterialCode || false;
  }, [selectedBuildingMaterialCode]);
  const { preparedMaterials } = useMaterials();

  useNextStep(validatePage);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: isRadioCardGroupError,
    });
  }, [isRadioCardGroupError]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: IFLHouseActionTypes.SetMaterials,
        payload: res.materials,
      });
    }
  }, [isLoading, res]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  const handleMaterialObjectClick = (event: ChangeEvent<HTMLInputElement>) => {
    setStepUpdated();
    setIsRadioCardGroupError(false);

    dispatch({
      type: IFLHouseActionTypes.SetSelectedBuildingMaterialCode,
      payload: event.target.value,
    });
  };

  const descriptionNavHandler = () => {
    navigate('/house-materials-descriptions');
  };

  return (
    <Container>
      <FormLabel marginBottom={24}>Выберите тип дома</FormLabel>
      {isRadioCardGroupError && (
        <FormSub isError={isRadioCardGroupError}>{formSubText}</FormSub>
      )}
      <AdaptiveListWrapper>
        {!isLoading && (!materials || materials?.length === 0) && 'Нет данных'}
        {preparedMaterials && preparedMaterials.length > 0 ? (
          <RadioCardGroup
            name="radioCardGroup"
            value={selectedBuildingMaterialCode}
            onChange={handleMaterialObjectClick}
            items={preparedMaterials}
            error={isRadioCardGroupError}
          />
        ) : null}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          leftIcon={<Info width={24} color="active" />}
          variant="text"
          themeColor="white"
          themeColorAccent="active"
          onClick={descriptionNavHandler}
          label="Какие дома считаются каменными или деревянными?"
        />
      </LinkContainer>
    </Container>
  );
};
