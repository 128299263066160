import Axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { AuthActionTypes, Store } from '@src/store';
import type { Method, Url } from '@src/types';
import { refreshToken } from '@src/utils';

const headers = {
  'X-Process-ID': uuidv4(),
  'Content-type': 'application/json',
};

export const useRequest = <T>(
  key: string,
  method: Method,
  url: Url,
  request?: T,
  deps?: any[],
  fetchManual?: boolean,
  authToken?: string
) => {
  const {
    state: {
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  Axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired

        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          authTokens
        ) {
          originalConfig._retry = true;

          dispatch({
            type: AuthActionTypes.SetAuthTokens,
            payload: undefined,
          });

          try {
            const rs = await refreshToken(authTokens);

            if (rs?.data) {
              return dispatch({
                type: AuthActionTypes.SetAuthTokens,
                payload: rs.data,
              });
            }

            return Promise.reject(err);
          } catch (_error: any) {
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 403 && err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const queryResult = useQuery(
    [key, deps],
    () =>
      Axios[method](`${window.envUrls.BASEURL}${url}`, request, {
        headers: authToken ? authHeaders : headers,
      }),
    {
      enabled: fetchManual ? false : true,
      staleTime: 100_000_000,
    }
  );

  const { isLoading, error, data, refetch, isFetching, isRefetching, status } =
    queryResult;

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    status,
  };
};
