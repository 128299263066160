import styled from 'styled-components';

import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

// TODO: Старые стили нужные для перехода на темизацию
// export const TotalSumWrapper = styled.div<{ isError: boolean }>`
//   border: 1px solid
//     ${(props) => (props.isError ? colors.system.error : colors.active)};
//   border-radius: 12px;
//   padding: 12px 24px;
//   box-sizing: border-box;
//   width: 100%;
//   // TODO: убрать хардкод цвета после добавления новой палитры
//   background: ${(props) => (props.isError ? '#FDDDE1' : colors.white)};
//
//   ${mixins.flex({ horizontal: false })}
//   ${media.desktop} {
//     border-radius: 16px;
//     padding: 16px 24px;
//   }
// `;
//
// export const TotalSumValue = styled.div`
//   ${typography.bold22}
//   color: ${colors.primary};
//   white-space: nowrap;
//   margin-bottom: 3px;
// `;
//
// export const TotalSumTitle = styled.div`
//   ${typography.regular16}
//   color: ${colors.primary};
//   white-space: nowrap;
//   margin-bottom: 3px;
// `;
//
// export const TotalSumSubtitle = styled.div<{ isError: boolean }>`
//   ${typography.pulsSubhead4.regular}
//   color: ${(props) => (props.isError ? colors.system.error : colors.secondary)};
//   letter-spacing: 0.02em;
// `;

export const TotalSumWrapper = styled.div<{ isError: boolean }>`
  border: 1px solid
    ${(props) => (props.isError ? colors.system.error : '#DCDCE6')};
  border-radius: 8px;
  padding: 12px 24px;
  box-sizing: border-box;
  width: 100%;
  // TODO: убрать хардкод цвета после добавления новой палитры
  background: ${(props) => (props.isError ? '#FDDDE1' : colors.white)};

  ${mixins.flex({ horizontal: false })}
  ${media.desktop} {
    border-radius: 8px;
    padding: 16px 24px;
  }
`;

export const TotalSumValue = styled.div`
  ${typography.bold22}
  color: ${colors.darkBlue2};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const TotalSumTitle = styled.div`
  ${typography.regular16}
  color: ${colors.darkBlue2};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const TotalSumSubtitle = styled.div<{ isError: boolean }>`
  ${typography.pulsSubhead4.regular}
  color: ${(props) =>
    props.isError ? colors.system.error : colors.secondaryGray2};
  letter-spacing: 0.02em;
`;
