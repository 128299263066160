import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import type { AuthTokens } from '@src/store';

const headers = {
  'X-Process-ID': uuidv4(),
  'Content-type': 'application/json',
};

export const refreshToken = (authTokens: AuthTokens) => {
  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authTokens) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authTokens.authorization.refreshToken}`,
    };
    return Axios.post(
      `${window.envUrls.BASEURL}/v1/user/refresh-token`,
      {},
      {
        headers: authHeaders,
      }
    );
  }

  return;
};
