export enum IFLActionTypes {
  SetInsuranceProducts = 'IFL/SET_INSURANCE_PRODUCTS',
  SetSelectedIProduct = 'IFL/SET_SELECTED_I_PRODUCT',
  SetSelectedRegion = 'IFL/SET_SELECTED_REGION',
  SetRegions = 'IFL/SET_REGIONS',
  SetRisks = 'IFL/SET_RISKS',
  SetInsuranceSubproducts = 'IFL/SET_INSURANCE_SUBPRODUCTS',
  SetInsuranceSum = 'IFL/SET_INSURANCE_SUM',
  SetGetPrices = 'IFL/SET_GET_PRICES',
  SetSelectedDuration = 'IFL/SET_SELECTED_DURATION',
  SetSelectedDate = 'IFL/SET_SELECTED_DATE',
  SetDadaValue = 'IFL/SET_DADA_VALUE',
  SetPromoCode = 'IFL/SET_PROMO_CODE',
}
