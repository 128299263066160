import styled from 'styled-components';

import { Spinner } from '@pulse-web-ui/spinner';
import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

import type { SumPerMonthWrapperProps } from './sum-per-month.types';

// TODO: Старые стили нужные для перехода на темизацию
// export const SumPerMonthWrapper = styled.div<SumPerMonthWrapperProps>`
//   ${mixins.flex({ horizontal: false })}
//   background: ${colors.active};
//   color: ${(props) => props.theme.colors.white};
//   padding: 12px 24px;
//   border-radius: 12px;
//   opacity: ${(props) => (props.disabled ? 0.3 : 1)};
//   min-height: 81px;
//   box-sizing: border-box;
//   width: 100%;
//   flex-direction: column-reverse;
//   justify-content: flex-end;
//
//   ${media.desktop} {
//     //display: block;
//     border-radius: 16px;
//     padding: 16px 24px;
//     min-height: 104px;
//     flex-direction: column;
//     justify-content: flex-start;
//   }
// `;
//
// export const SumPerMonthValue = styled.div`
//   ${typography.bold22}
//   white-space: nowrap;
//   margin-bottom: 3px;
//
//   ${media.desktop} {
//     margin-bottom: 0;
//   }
// `;
//
// export const SumPerMonthTitle = styled.div`
//   ${typography.regular16}
//   white-space: nowrap;
//
//   ${media.desktop} {
//     margin-bottom: 3px;
//   }
// `;
//
// export const StyledSpinner = styled(Spinner)``;
//
// export const SumPerMonthErrorMessage = styled.div`
//   ${typography.regular14}
//   letter-spacing: 0.02em;
//   color: ${colors.main};
//   padding-top: 16px;
//
//   ${media.desktop} {
//     padding-top: 13px;
//   }
// `;
//
// SumPerMonthWrapper.defaultProps = {
//   theme: {
//     colors: colors,
//   },
// };

export const SumPerMonthWrapper = styled.div<SumPerMonthWrapperProps>`
  ${mixins.flex({ horizontal: false })}
  color: ${colors.darkBlue2};
  padding: 12px 24px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  min-height: 81px;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: flex-end;
  border: 1px solid ${colors.primary};

  ${media.desktop} {
    //display: block;
    border-radius: 8px;
    padding: 16px 24px;
    min-height: 112px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const SumPerMonthValue = styled.div`
  ${typography.bold22}
  white-space: nowrap;
  margin-bottom: 3px;

  ${media.desktop} {
    margin-bottom: 0;
  }
`;

export const SumPerMonthTitle = styled.div`
  ${typography.regular16}
  white-space: nowrap;

  ${media.desktop} {
    margin-bottom: 3px;
  }
`;

export const StyledSpinner = styled(Spinner)``;

export const SumPerMonthErrorMessage = styled.div`
  ${typography.regular14}
  letter-spacing: 0.02em;
  color: ${colors.main};
  padding-top: 16px;

  ${media.desktop} {
    padding-top: 13px;
  }
`;

SumPerMonthWrapper.defaultProps = {
  theme: {
    colors: colors,
  },
};
