import styled from 'styled-components';

import { colors, mixins } from '@pulse-web-ui/theme';

import { FormSub } from '@src/components';
import { FormSubProps } from '@src/components/form-elements/form-elements.types';

export const GlobalErrorWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 24,
    alignItems: 'center',
    justifyContent: 'center',
  })}
  flex-grow: 1;
`;

export const GlobalErrorContent = styled.div`
  ${mixins.flex({
    horizontal: false,
    alignItems: 'center',
    justifyContent: 'center',
  })}
`;

export const GlobalErrorFormSub = styled(FormSub)<FormSubProps>`
  color: ${colors.darkBlue2};
`;
