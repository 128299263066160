import styled from 'styled-components';

import { colors, media, typography } from '@pulse-web-ui/theme';

import { FormSub } from '@src/components';

export const PageTitle = styled.h1`
  ${typography.medium24};
  color: ${colors.darkBlue2};
  margin-bottom: 8px;
`;

export const PageSubTitle = styled.h1<{ marginBottom?: number }>`
  ${typography.regular16};
  color: ${colors.secondaryGray};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '24px'};
`;

export const StyledDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 42px;
  cursor: pointer;
  // TODO: добавить цвет из colors
  color: #21145a;

  ${typography.regular18};

  p {
    margin-left: 10px;
  }
`;

export const StyledFormSub = styled(FormSub)`
  display: flex;
  align-items: center;

  text-align: center;
`;
