import type {
  DocumentType,
  OrderItemType,
  OrderItemsBlockType,
} from '@src/types';

export type { OrderItemType, DocumentType } from '@src/types';

export enum OrderActionTypes {
  SetOrder = 'ORDER/SET_ORDER',
  SetOrderSubmitData = 'ORDER/SE_SET_ORDER_SUBMIT_DATA',
  SetOrderInitData = 'ORDER/SE_SET_ORDER_INIT_DATA',
  SetOrderPageTitle = 'ORDER/SET_ORDER_PAGE_TITLE',
  SetOrderRequestData = 'ORDER/SET_ORDER_REQUEST_DATA',
  SetCachedOrderRequestData = 'ORDER/SET_CACHED_REQUEST_DATA',
}

export type OrderDataTypes = OrderItemType[] | OrderItemsBlockType[];

export type OrderSubmitDataTypes = {
  contractId: string;
  address: string;
  clientDocument: DocumentType[];
  premiumAndDelta: string | number;
  billId: string;
  subscriptionId: string;
  subscriptionNumber: string;
  contractNumber: string;
  orderNumber: string;
  isSuccessfulPromo?: null | boolean;
};

export type OrderInitDataTypes = {
  id: string;
  signature: string;
  clientCoreId: string;
  paymentGateOrder: string;
  sector: string;
  url: string;
  failUrl: string;
  paymentInstruments: string[] | [];
};
