import styled from 'styled-components';

import { media, mixins, typography } from '@pulse-web-ui/theme';

export const SupportBlockContent = styled.div`
  box-sizing: border-box;
  padding-top: 40px;

  ${media.desktop} {
    padding-top: 32px;
  }
`;

export const SupportBlockTitle = styled.h3`
  ${typography.regular18};
  margin-bottom: 16px;

  ${media.desktop} {
    ${typography.regular20};
  }
`;

export const SupportButtonsWrapper = styled.div`
  ${mixins.flex({
    gap: 8,
    horizontal: false,
  })}

  ${media.desktop} {
    ${mixins.flex({ gap: 8 })}
  }
`;
