import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { NumericInput } from '@pulse-web-ui/numeric-input';

import {
  Container,
  HeaderAdaptive3,
  HeaderSub,
  SiteAgreements,
  Skeleton,
} from '@src/components';
import {
  AdaptiveContainerInverse,
  NumericInputBox,
  NumericInputContainer,
  NumericInputWrapper,
} from '@src/components/container';
import {
  FormHead,
  FormSubhead,
  FormSubheadListItem,
  NumericInputLabel,
} from '@src/components/form-elements';
import '@src/components/headers';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  USER_AGREEMENTS_URL,
  analyticEvents,
  insuranceProductsCode,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep } from '@src/hooks';
import { useRequests } from '@src/hooks/use-requests';
import { insurePersonsFormatter } from '@src/pages/ns-form/utils';
import { AuthActionTypes, Store } from '@src/store';
import type { AgeRatio } from '@src/store/ns';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { InsuranceProductType, NumberInsurePersons } from '@src/types';
import { ageRatiosFormat } from '@src/utils/age-ratios-format';

export const FormNsQuantity: FC = memo(() => {
  const {
    state: {
      stateFormNS: { numberInsurePersons, insuranceProduct, ageRatios },
      stateWizard: { wantNextStep, updateFormState },
    },
    dispatch,
  } = useContext(Store);
  const [selectPersonsError, setSelectPersonsError] = useState(false);

  const totalNumberInsurePersons = useMemo(
    () =>
      Object.values(numberInsurePersons).reduce((sum, value) => sum + value, 0),
    [numberInsurePersons]
  );

  const isDisabledIncrement = useMemo(() => {
    if (insuranceProduct && insuranceProduct.maxObjectsNumber !== null) {
      return totalNumberInsurePersons >= insuranceProduct.maxObjectsNumber;
    }
    return false;
  }, [totalNumberInsurePersons, insuranceProduct]);

  const { res, isLoading, isError, refetchAll } = useRequests([
    {
      key: 'formNSGetInsuranceProducts',
      method: 'post',
      url: '/v1/references/get-insurance-product',
      request: {
        productCode: insuranceProductsCode.accident,
      },
    },
    {
      key: 'formNSGetAgeRatio',
      method: 'post',
      url: '/v1/references/get-age-ratio',
    },
  ]);

  const [insuranceProductsRes, ageRatioRes] = res;

  const ageRatiosLabels = useMemo(
    () => ageRatiosFormat(ageRatios),
    [ageRatios]
  );

  const storeInsuranceProduct = useCallback(
    (data: InsuranceProductType) => {
      dispatch({
        type: NSActionTypes.SetInsuranceProduct,
        payload: data || undefined,
      });
    },
    [insuranceProductsRes]
  );

  const storeInsuranceAgeRatio = useCallback((data: AgeRatio[]) => {
    dispatch({
      type: NSActionTypes.SetAgeRatios,
      payload: data,
    });
  }, []);

  const storeNumberInsurePersons = useCallback((data: NumberInsurePersons) => {
    const newInsurePersons = insurePersonsFormatter(data);

    dispatch({
      type: NSActionTypes.SetNumberInsurePersons,
      payload: data,
    });

    dispatch({
      type: NSActionTypes.SetInsurePersons,
      payload: newInsurePersons,
    });
  }, []);

  const storeFwNavDisabled = useCallback((data: boolean) => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: data,
    });
  }, []);

  const { control, getValues, watch } = useForm<NumberInsurePersons>({
    defaultValues: {
      ...numberInsurePersons,
    },
  });

  const validatePage = useCallback(
    () => totalNumberInsurePersons !== 0 && !selectPersonsError,
    [totalNumberInsurePersons, selectPersonsError]
  );

  const handleViewAgreement = () => {
    sendAnalyticEvent(analyticEvents.userAgreement);
  };

  useNextStep(validatePage);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/ns',
    });
  }, []);

  useEffect(() => {
    if (!isLoading && insuranceProductsRes && !insuranceProduct) {
      storeInsuranceProduct(insuranceProductsRes.data);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && ageRatioRes && (!ageRatios || ageRatios.length === 0)) {
      storeInsuranceAgeRatio(ageRatioRes.data.ageRatios);
    }
  }, [ageRatioRes]);

  useEffect(() => {
    const subscription = watch(() => storeNumberInsurePersons(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.startAccident);
  }, []);

  useEffect(() => {
    if (wantNextStep && !totalNumberInsurePersons) {
      setSelectPersonsError(true);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [wantNextStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, [updateFormState]);

  useEffect(() => {
    if (selectPersonsError && totalNumberInsurePersons) {
      setSelectPersonsError(false);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [totalNumberInsurePersons]);

  if (isLoading) return <Skeleton />;

  if (isError)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetchAll} />;

  return (
    <AdaptiveContainerInverse>
      <Container>
        <FormHead>Кого хотите защитить?</FormHead>
        <FormSubheadListItem isError={selectPersonsError}>
          Выберите количество застрахованных лиц по возрастным категориям
        </FormSubheadListItem>
        <FormSubhead>
          {insuranceProduct?.maxObjectsNumber &&
            `Вы можете застраховать до ${insuranceProduct.maxObjectsNumber} человек в одной подписке`}
        </FormSubhead>
        <NumericInputContainer>
          <NumericInputWrapper>
            <HeaderAdaptive3>
              <span>Дети</span>
            </HeaderAdaptive3>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.children}</NumericInputLabel>
              <Controller
                control={control}
                name="numberChildren"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
          </NumericInputWrapper>
          <NumericInputWrapper>
            <HeaderAdaptive3>
              <span>Взрослые</span>
              <HeaderSub>в том числе и вы</HeaderSub>
            </HeaderAdaptive3>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.adults}</NumericInputLabel>
              <Controller
                control={control}
                name="numberAdults"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.elderly}</NumericInputLabel>
              <Controller
                control={control}
                name="numberElderly"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
          </NumericInputWrapper>
        </NumericInputContainer>
      </Container>
      <SiteAgreements>
        Нажимая кнопку «Продолжить», вы принимаете условия{' '}
        <a
          target="_blank"
          href={USER_AGREEMENTS_URL}
          onClick={handleViewAgreement}
          download
        >
          пользовательского соглашения
        </a>
      </SiteAgreements>
    </AdaptiveContainerInverse>
  );
});
