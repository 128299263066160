/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { Document } from '@pulse-web-ui/icons';

import {
  ArrowLeftIcon,
  Container,
  DocumentsWrapper,
  FormHead,
  OrderTitle,
  OrderWrapper,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  INSURANCE_POLICIES,
  analyticEvents,
  formPeriodLabels,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  nsRoute,
  petsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { OrderActionTypes, Store } from '@src/store';
import { OrderItemValueType } from '@src/types';
import { currencyRuLocaleWithTwoFractionDigits } from '@src/utils';

import { ClientDocumentItem } from './components/client-document-item';
import {
  OrderCheckoutAggrWrapper,
  OrderCheckoutTitleWrapper,
} from './components/components.styles';
import { OrderCheckoutItem } from './components/order-checkout-item';

type ContractDataType = {
  amount?: string | number;
  contractId?: string;
  email?: string | null;
  operationType: 'ORDER';
  orderNumber?: string;
  paymentDocumentId?: string;
  subscriptionId?: string;
};

const schema = yup.object().shape({
  insurancePolicies: yup
    .bool()
    .oneOf([true], 'Без этого согласия не можем оформить полис'),
});

export const NewOrderCheckout: FC = () => {
  const {
    state: {
      stateFormIFL: { selectedDuration },
      stateFormIFLHouse: { selectedDuration: selectedDurationIflHouse },
      stateFormIFLFlat: { selectedDuration: selectedDurationIflFlat },
      stateFormNS: { selectedDuration: selectedDurationNS },
      stateFormPets: { selectedDuration: selectedDurationPets },
      stateUser: { profile },
      stateOrder: { orderSubmitData, orderInitData },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
      stateUser,
    },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    switch (authorizeRefRoute) {
      case iflHouseRoute:
        sendAnalyticEvent(analyticEvents.iflStep8Docs);
        sendAnalyticEvent(analyticEvents.iflHouseStep8Docs);
        break;
      case iflFlatRoute:
        sendAnalyticEvent(analyticEvents.iflStep8Docs);
        sendAnalyticEvent(analyticEvents.iflFlatStep8Docs);
        break;
      case petsRoute:
        sendAnalyticEvent(analyticEvents.petToStepDocs);
        break;
      default:
        break;
    }
  }, []);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ insurancePolicies: boolean }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      insurancePolicies: false,
    },
  });

  const clientDocuments = orderSubmitData?.clientDocument.map((document) => ({
    ...document,
    icon: Document,
  }));

  const handleViewAgreement = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.viewAgreementRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.viewAgreementAccident);
        break;
    }
  };

  useEffect(() => {
    if (!orderSubmitData) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/'); // проверить
    }
  }, [orderSubmitData]);

  const orderResult = [
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Стоимость и периодичность оплаты',
      value: `${
        orderSubmitData?.premiumAndDelta
          ? currencyRuLocaleWithTwoFractionDigits(
              Number(orderSubmitData?.premiumAndDelta)
            )
          : ''
      } ${
        formPeriodLabels.get(
          selectedDuration ||
            selectedDurationIflFlat ||
            selectedDurationIflHouse ||
            selectedDurationNS ||
            selectedDurationPets ||
            ''
        )?.label || 'в месяц'
      }`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Номер полиса',
      value: `${orderSubmitData?.contractNumber}`, // проверить - с бэка в моковых данных не приходит contractNumber, только orderNumber
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: 'Полис отправили на почту, cтанет действительным после оплаты',
      value: profile?.profile.email || '',
    },
  ];

  const contractData: ContractDataType = {
    amount: orderSubmitData?.premiumAndDelta,
    contractId: orderSubmitData?.contractId,
    email: stateUser.profile?.profile?.email,
    operationType: 'ORDER',
    orderNumber: orderSubmitData?.orderNumber,
    paymentDocumentId: orderSubmitData?.billId,
    subscriptionId: orderSubmitData?.subscriptionId,
  };

  const { isLoading, res, error, refetch } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    '/v1/payment/init-order',
    contractData,
    [orderInitData],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 2,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 2,
        });
        break;
    }

    navigate(-1);
  };

  const submitPage = handleSubmit(() => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPaymentMethodRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPaymentMethodAccident);
        break;
      case petsRoute:
        sendAnalyticEvent(analyticEvents.petToStepPay);
        break;
    }

    refetch();
  });

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: OrderActionTypes.SetOrderInitData,
        payload: res,
      });
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (orderInitData?.signature) {
      navigate('/pay');
    }
  }, [orderInitData]);

  useEffect(() => {
    if (!authTokens) {
      navigate('/personal-info');
    }
  }, [authTokens]);

  if (error) return <GlobalErrorInfo retrayHandler={refetch} />;

  if (isLoading) return <Skeleton />;

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={handleClickBack}
        disabled={isLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        label="Перейти к оплате"
        themeColor="primary"
        onClick={submitPage}
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <OrderCheckoutTitleWrapper>
        <Container>
          <OrderTitle>Оплата подписки</OrderTitle>
        </Container>
      </OrderCheckoutTitleWrapper>
      <OrderWrapper>
        {orderResult?.map((orderItem) => (
          <OrderCheckoutItem
            key={`${orderItem.title} + ${orderItem.value}`}
            isColsIdentical={true}
            {...orderItem}
          />
        ))}
        <OrderCheckoutAggrWrapper>
          <Controller
            control={control}
            name="insurancePolicies"
            render={({ field: { onChange }, fieldState }) => (
              <Checkbox
                label={
                  <>
                    Принимаю условия страхового полиса, правил страхования и{' '}
                    <a
                      href={INSURANCE_POLICIES}
                      onClick={handleViewAgreement}
                      target="_blank"
                    >
                      соглашения о безакцептном списании
                    </a>{' '}
                    денежных средств
                  </>
                }
                name="insurancePolicies"
                onChange={onChange}
                message={errors.insurancePolicies?.message}
                status={fieldState.error && 'error'}
              />
            )}
          />
        </OrderCheckoutAggrWrapper>
      </OrderWrapper>
      <Container>
        <FormHead>Ваши документы</FormHead>
      </Container>
      <DocumentsWrapper>
        {clientDocuments?.map((clientDocumentItem) => (
          <ClientDocumentItem key={uuid()} {...clientDocumentItem} />
        ))}
      </DocumentsWrapper>
    </BaseLayout>
  );
};
