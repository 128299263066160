import { forwardRef, memo, useState } from 'react';
import OtpInput from 'react-otp-input';

import { HelperText } from '@pulse-web-ui/helper-text';

import { CenteredWrapper, GridColumnContainer } from '@src/components';

import { Resend } from './components';
import { OtpContainer, otpErrorStyle, otpInputStyle } from './otp.styles';
import { OtpProps } from './otp.types';

export const Otp = memo(
  forwardRef<HTMLDivElement, OtpProps>(
    (
      {
        expiryTimestamp,
        onResend,
        status,
        onAbleResendOtp,
        limit = 6,
        onSubmit,
        extraMessage,
        disabled,
        alignment,
        isExpiryTimestampText,
        gridStep,
        style,
      },
      ref
    ) => {
      const [val, setVal] = useState('');

      const optChangeHandler = (val: string) => {
        setVal(val);
        onSubmit(val);
      };

      return (
        <OtpContainer style={style} data-component="otp" ref={ref}>
          <GridColumnContainer gridStep={gridStep}>
            <CenteredWrapper alignment={alignment}>
              <div style={{ textAlign: 'start' }}>
                <HelperText message={status?.message} status={status?.status}>
                  <OtpInput
                    className="otp-input"
                    isInputNum
                    value={val}
                    onChange={optChangeHandler}
                    numInputs={limit}
                    hasErrored={status?.status === 'error'}
                    separator={<span>&nbsp;</span>}
                    inputStyle={otpInputStyle}
                    errorStyle={otpErrorStyle}
                    isDisabled={disabled}
                  />
                </HelperText>
              </div>
            </CenteredWrapper>

            {extraMessage && <CenteredWrapper>{extraMessage}</CenteredWrapper>}
            {expiryTimestamp && (
              <CenteredWrapper>
                <Resend
                  isExpiryTimestampText={isExpiryTimestampText}
                  expiryTimestamp={expiryTimestamp}
                  onResend={onResend}
                  onAbleResendOtp={onAbleResendOtp}
                />
              </CenteredWrapper>
            )}
          </GridColumnContainer>
        </OtpContainer>
      );
    }
  )
);
