/* eslint-disable indent */
import React from 'react';

import { SumPerMonthErrorMessage } from './sum-per-month.styles';
import { SumPerMonthErrorProps } from './sum-per-month.types';

export const SumPerMonthError = ({
  errorResponse,
  currentSumErr,
}: SumPerMonthErrorProps) => {
  const errorCode = errorResponse?.response?.data.code;

  if (errorCode === 'VALIDATION_ERROR' || errorCode === 'TECHNICAL_ERROR') {
    let msg = 'Невозможно рассчитать. Проверьте выбранные условия';

    switch (currentSumErr) {
      case 'less':
        msg =
          'К сожалению, сумма покрытия меньше минимально допустимого значения. Увеличьте сумму покрытия, чтобы продолжить оформление';
        break;
      case 'gt':
        msg =
          'К сожалению, сумма покрытия выше максимально допустимого значения. Уменьшите сумму покрытия, чтобы продолжить оформление';
        break;
    }
    return <SumPerMonthErrorMessage>{msg}</SumPerMonthErrorMessage>;
  }

  return null;
};
