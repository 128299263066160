import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { AdaptiveListItem } from '@pulse-web-ui/adaptive-list-item';
import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import { IFLHouseActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { evenRisk } from '@src/utils';

const formSubText =
  'Эти события нередки и наносят большой ущерб. Включите в подписку как минимум один из этих рисков';

export const FormInsuranceRisks: FC = () => {
  const navigate = useNavigate();

  const {
    state: {
      stateFormIFLHouse: {
        risks,
        selectedIProduct,
        selectedBuildingMaterialCode,
      },
    },
    dispatch,
  } = useContext(Store);

  const [listBoxStatus, setListBoxStatus] = useState<'error' | undefined>(
    undefined
  );

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep2Risk);
    sendAnalyticEvent(analyticEvents.iflHouseStep2Risk, {
      b_type: selectedBuildingMaterialCode?.toLowerCase(),
    });

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const { isLoading, error, res, refetch } = useRequest(
    'formIFLGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
    },
    []
  );

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    setListBoxStatus(!risks?.some(evenRisk) ? 'error' : undefined);

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: listBoxStatus === 'error',
    });
  }, [listBoxStatus]);

  useEffect(() => {
    if (!isLoading && res && res.risks.length > 0) {
      dispatch({
        type: IFLHouseActionTypes.SetRisks,
        payload: res.risks,
      });
    }
  }, [isLoading, res]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  const onClick = (code: string) => {
    setStepUpdated();

    setListBoxStatus(undefined);

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        sendAnalyticEvent(
          item.active
            ? analyticEvents.deselectOptionRealty
            : analyticEvents.selectOptionRealty,
          {
            option: item.name,
          }
        );

        item.active = !item.active;
      }
      return item;
    });

    dispatch({
      type: IFLHouseActionTypes.SetRisks,
      payload: newRisks,
    });

    dispatch({
      type: IFLHouseActionTypes.SetGetSubobjectsRefetchUid,
      payload: uuidv4(),
    });

    dispatch({
      type: IFLHouseActionTypes.SetEmptyDependencies,
      payload: [],
    });

    dispatch({
      type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
      payload: undefined,
    });
  };

  const ymSendAnalytics = (name: string) => {
    sendAnalyticEvent(analyticEvents.viewOptionRealty, {
      option: name,
    });
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: IFLHouseActionTypes.SetRisksPage,
      payload: 'primary',
    });
    navigate('/house-risk-descriptions');
  };

  return (
    <Container>
      <FormLabel>Выберите основные риски</FormLabel>
      <FormSub isError={listBoxStatus === 'error'}>{formSubText}</FormSub>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && 'Нет данных'}
        {risks?.map((item) => {
          return item.binding === true ? (
            <AdaptiveListItem
              index={item.code}
              key={item.code}
              active={item.active}
              label={item.name}
              description={item.description}
              onClick={() => onClick(item.code)}
              imgSrc={item.imageUrl}
              onOpen={() => ymSendAnalytics(item.name)}
            />
          ) : (
            <></>
          );
        })}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          leftIcon={<Info width={24} color="active" />}
          variant="text"
          themeColor="white"
          themeColorAccent="active"
          onClick={descriptionNavHandler}
          label="Подробнее об основных рисках"
        />
      </LinkContainer>
    </Container>
  );
};
