import type { AuthStoreState } from '@src/store/authorize';
import { initAuthStoreState } from '@src/store/authorize';
import type { IFLStoreState } from '@src/store/ifl';
import { initIFLStoreState } from '@src/store/ifl';
import type { IFLFlatStoreState } from '@src/store/ifl-flat';
import { initIFLFlatStoreState } from '@src/store/ifl-flat';
import type { IFLHouseStoreState } from '@src/store/ifl-house';
import { initIFLHouseStoreState } from '@src/store/ifl-house';
import type { NSStoreState } from '@src/store/ns';
import { initNSStoreState } from '@src/store/ns';
import type { OrderStoreState } from '@src/store/order';
import { initOrderStoreState } from '@src/store/order';
import type { PetsStoreState } from '@src/store/pets';
import { initPetsStoreState } from '@src/store/pets';
import type { UserStoreState } from '@src/store/user';
import { initUserStoreState } from '@src/store/user';
import type { WizardStoreState } from '@src/store/wizard';
import { initWizardStoreState } from '@src/store/wizard';

export type StoreState = {
  stateFormIFL: IFLStoreState;
  stateFormIFLHouse: IFLHouseStoreState;
  stateFormIFLFlat: IFLFlatStoreState;
  stateFormNS: NSStoreState;
  stateWizard: WizardStoreState;
  stateAuth: AuthStoreState;
  stateUser: UserStoreState;
  stateOrder: OrderStoreState;
  stateFormPets: PetsStoreState;
};

export const initStoreState: StoreState = {
  stateFormIFL: { ...initIFLStoreState },
  stateFormIFLHouse: { ...initIFLHouseStoreState },
  stateFormIFLFlat: { ...initIFLFlatStoreState },
  stateFormNS: { ...initNSStoreState },
  stateWizard: { ...initWizardStoreState },
  stateAuth: { ...initAuthStoreState },
  stateUser: { ...initUserStoreState },
  stateOrder: { ...initOrderStoreState },
  stateFormPets: { ...initPetsStoreState },
};
