import { memo, useContext, useEffect } from 'react';
import type { FC } from 'react';

import { Button } from '@pulse-web-ui/button';
import { ErrorAlertIcon } from '@pulse-web-ui/colored-icons';

import { HeaderAdaptive1 } from '@src/components';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

import {
  GlobalErrorContent,
  GlobalErrorFormSub,
  GlobalErrorWrapper,
} from './global-error-info.styles';

type Props = {
  pending?: boolean;
  retrayHandler?: () => void;
};

const TitleText1 = 'Упс! При загрузке данных';
const TitleText2 = 'что-то пошло не так';
const ContentText = 'Пожалуйста, проверьте качество связи и попробуйте еще раз';

export const GlobalErrorInfo: FC<Props> = memo(({ pending, retrayHandler }) => {
  const { dispatch } = useContext(Store);

  const handleRetry = () => {
    if (retrayHandler) {
      retrayHandler();
    }
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: true,
    });
  }, []);

  return (
    <GlobalErrorWrapper>
      <ErrorAlertIcon />
      <GlobalErrorContent>
        <HeaderAdaptive1 align="center">
          {TitleText1}
          <br />
          {TitleText2}
        </HeaderAdaptive1>
        <GlobalErrorFormSub align="center">{ContentText}</GlobalErrorFormSub>
      </GlobalErrorContent>
      {retrayHandler ? (
        <Button
          disabled={pending}
          themeColor={pending ? 'secondary' : 'primary'}
          label="Попробовать еще раз"
          onClick={handleRetry}
        />
      ) : (
        <></>
      )}
    </GlobalErrorWrapper>
  );
});
