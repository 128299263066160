/* eslint-disable indent */
import { ageCategory } from '@src/utils/age-ratios-format';

export const getInsurancePersonTitle = (ageMin: number) => {
  if (ageMin === 1) {
    return ageCategory.children;
  }

  return ageCategory.adults;
};
