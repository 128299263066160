import type { DaDataAddressSuggestion } from 'react-dadata';

import type { OptionsMap } from '@pulse-web-ui/list-box/lib/list-box.types';

import {
  GetPrices,
  InsuranceProduct,
  InsuranceSubProduct,
  Region,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type InsuranceSum = number;

export type SelectedIProduct = undefined | InsuranceProduct;

export type SelectedRegion = undefined | Region;

export type InsuranceSubproducts = {
  subobjects: InsuranceSubProduct[];
};

export interface IFLStoreState {
  insuranceProducts: InsuranceProduct[];
  selectedIProduct: undefined | string;
  selectedRegion: SelectedRegion;
  regions: undefined | OptionsMap;
  risks: undefined | Risk[];
  insuranceSubproducts: undefined | InsuranceSubproducts;
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  dadaValue: undefined | DaDataAddressSuggestion;
  promoCode?: string;
}

export const initIFLStoreState: IFLStoreState = {
  insuranceProducts: [],
  selectedIProduct: undefined,
  selectedRegion: undefined,
  regions: undefined,
  risks: undefined,
  insuranceSubproducts: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  selectedDuration: null,
  selectedDate: null,
  dadaValue: undefined,
  promoCode: undefined,
};
