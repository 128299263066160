import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { Select } from '@pulse-web-ui/select';

import {
  AdaptiveColumns,
  Container,
  ControllerContainer,
  FormLabel,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequests } from '@src/hooks';
import {
  AuthActionTypes,
  PetsActionTypes,
  Store,
  WizardActionTypes,
} from '@src/store';
import { SelectedPetData } from '@src/store/pets';
import { InsuranceProductType } from '@src/types';

import { usePetsDataSchema } from './hooks';
import {
  createKindOptions,
  getAgeOptionsByKind,
  getBreedOptionsByKind,
} from './utils';

const GENDER_OPTIONS = [
  { label: 'Самец', value: 'Самец' },
  { label: 'Самка', value: 'Самка' },
];

type PetsDataForm = SelectedPetData;

export const PetsData = () => {
  const {
    state: {
      stateFormPets: { pets, selectedPetData },
    },
    dispatch,
  } = useContext(Store);
  const [petKind, setPetKind] = useState(selectedPetData?.kind || '');
  const [formData, setFormData] = useState<PetsDataForm | undefined>();

  const petsDataSchema = usePetsDataSchema();

  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    getValues,
    setValue,
    reset,
  } = useForm<PetsDataForm>({
    resolver: yupResolver(petsDataSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: formData,
  });

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.petToStepPetType);
  }, []);

  const kindOptions = useMemo(() => createKindOptions(pets), [pets]);
  const hasPetBreeds = useMemo(
    () =>
      !!petKind.length &&
      !!pets.find((pet) => pet.kind === petKind)?.breeds?.length,
    [petKind, pets]
  );
  const onKindChange = (val: string) => {
    setPetKind(val);
    reset({
      kind: val,
    });
    dispatch({
      type: PetsActionTypes.SetRisks,
      payload: undefined,
    });
  };

  const submitPage = handleSubmit((data) => {
    setFormData(data);
    dispatch({
      type: PetsActionTypes.SetSelectedPetData,
      payload: data,
    });
    dispatch({
      type: PetsActionTypes.SetSelectedPetKindName,
      payload: pets.find(({ kind }) => kind === data.kind)?.kindName,
    });
  });

  const validatePage = () =>
    submitPage().then(
      () =>
        !Object.values(getValues()).some((val) => !val) &&
        !Object.keys(errors).length
    );

  useNextStep(validatePage);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/pets',
    });
  }, []);

  useEffect(() => {
    if (!hasPetBreeds) {
      unregister('breed');
    }
  }, [hasPetBreeds, unregister]);

  useEffect(() => {
    if (selectedPetData) {
      Object.keys(selectedPetData).forEach((key) => {
        const formFieldKey = key as keyof PetsDataForm;
        setValue(formFieldKey, selectedPetData[formFieldKey]);
      });
    }
  }, []);

  const {
    isLoading,
    isError,
    res: [petsInfoResponse, resProduct],
    refetchAll,
  } = useRequests([
    {
      key: 'petsFormGetPetsInfo',
      method: 'post',
      url: '/v1/references/get-pets-info ',
      request: {
        productCode: insuranceProductsCode.pets,
      },
    },
    {
      key: 'petsFormGetInsuranceProduct',
      method: 'post',
      url: '/v1/references/get-insurance-product',
      request: {
        productCode: insuranceProductsCode.pets,
      },
    },
  ]);

  useEffect(() => {
    if (
      !isLoading &&
      petsInfoResponse &&
      !!petsInfoResponse?.data?.pets?.length
    ) {
      dispatch({
        type: PetsActionTypes.SetPets,
        payload: petsInfoResponse.data.pets,
      });
    }
  }, [isLoading, petsInfoResponse?.data]);

  useEffect(() => {
    if (!isLoading && resProduct && resProduct?.data) {
      const data: InsuranceProductType = { ...resProduct.data };

      dispatch({
        type: PetsActionTypes.SetSelectedIProduct,
        payload: data,
      });
    }
  }, [isLoading, resProduct?.data]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: isError,
    });
  }, [isError]);

  if (isLoading) return <Skeleton />;

  if (isError) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetchAll} />;
  }

  return (
    <Container>
      <FormLabel>Данные о вашем питомце</FormLabel>
      <ControllerContainer>
        <Controller
          control={control}
          name="kind"
          render={({ field: { onChange, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={errors.kind?.message}
            >
              <Select
                onChange={(val) => {
                  onKindChange(val);
                  onChange(val);
                }}
                value={value || ''}
                options={kindOptions}
                placeholder={'Вид питомца'}
                status={fieldState.error && 'error'}
              />
            </HelperText>
          )}
        />
      </ControllerContainer>
      {hasPetBreeds && (
        <ControllerContainer>
          <Controller
            control={control}
            name="breed"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.breed?.message}
              >
                <AutocompleteSelect
                  onChange={onChange}
                  value={value || ''}
                  options={getBreedOptionsByKind(pets, petKind)}
                  placeholder={'Порода'}
                  status={fieldState.error && 'error'}
                />
              </HelperText>
            )}
          />
        </ControllerContainer>
      )}
      <AdaptiveColumns>
        <Controller
          control={control}
          name="age"
          render={({ field: { onChange, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={
                errors.age?.message ||
                'Укажите сколько питомцу полных лет, если возраст до года, выберите количество месяцев'
              }
            >
              <Select
                onChange={onChange}
                value={value || ''}
                options={
                  !!petKind.length ? getAgeOptionsByKind(pets, petKind) : []
                }
                placeholder={'Возраст питомца'}
                status={fieldState.error && 'error'}
                disabled={!petKind.length}
              />
            </HelperText>
          )}
        />
        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={errors.gender?.message}
            >
              <Select
                onChange={onChange}
                value={value || ''}
                options={GENDER_OPTIONS}
                placeholder={'Пол'}
                status={fieldState.error && 'error'}
              />
            </HelperText>
          )}
        />
      </AdaptiveColumns>
      <ControllerContainer>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={
                errors.name?.message ||
                'Укажите кличку, которую вы обычно называете в ветеринарных клиниках'
              }
            >
              <Input
                name="name"
                onChange={onChange}
                value={value || ''}
                label={'Как зовут питомца'}
                error={!!errors.name}
                onBlur={onBlur}
              />
            </HelperText>
          )}
        />
      </ControllerContainer>
    </Container>
  );
};
