/* eslint-disable indent */
import { Product } from '@src/constants';

export const getProductForAnalytic = (product: string | undefined) => {
  let productForAnalytic;

  switch (product) {
    case Product.APARTMENT:
      productForAnalytic = 'flat';
      break;
    case Product.NS:
      productForAnalytic = 'accident';
      break;
    default:
      productForAnalytic = product;
      break;
  }

  return productForAnalytic;
};
