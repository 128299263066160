import styled from 'styled-components';

import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

type OrderItemTitleProps = {
  isColsIdentical?: boolean;
};

export const OrderItemWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 8,
    justifyContent: 'flex-start',
  })}
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.gray};
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderCheckoutItemWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 8,
    justifyContent: 'flex-start',
  })}
  margin-bottom: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid ${colors.gray};

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

export const OrderCheckoutAggrWrapper = styled.div`
  padding-top: 20px;
`;

export const OrderBlockItemWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 0,
    justifyContent: 'flex-start',
  })}
  padding-top: 32px;
`;

export const OrderContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 33px;
`;

export const OrderPageTitleWrapper = styled.h2`
  ${typography.pulsHead2.regular}
  color: ${colors.darkBlue2};
  margin-bottom: 24px;
  padding-top: 32px;
  letter-spacing: -0.02em;
`;

export const OrderItemTitle = styled.div<OrderItemTitleProps>`
  ${typography.regular14};
  color: #63779c;
`;

export const OrderCheckoutItemTitle = styled.div<OrderItemTitleProps>`
  ${typography.regular14};
  color: ${colors.darkGray};

  ${media.desktop} {
    flex-basis: ${(props) => (props.isColsIdentical ? '50%' : '200px')};
    flex-shrink: 0;
  }
`;

export const OrderCheckoutTitleWrapper = styled.div`
  margin-bottom: 8px;
`;

export const OrderBlockItemTitle = styled.div`
  ${mixins.flex({
    horizontal: true,
    justifyContent: 'space-between',
  })}
  margin-bottom: 16px;
`;

export const OrderBlockItemTitleButton = styled.button`
  color: ${colors.active};
  text-decoration: none;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  background: none;
  padding: 0;
  overflow: hidden;
`;

export const OrderItemValueWrapper = styled.div``;

export const OrderItemValue = styled.div`
  ${typography.bold16};
  color: ${colors.darkBlue2};
`;

export const OrderItemSubValue = styled.div`
  ${typography.regular14};
  color: ${colors.darkBlue2};
  margin-top: 6px;
`;
export interface OrderItemStatusValueProps {
  error: boolean;
}
export const OrderItemStatusValue = styled.div`
  ${typography.regular14}
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-top: 8px;
  color: ${({ error }: OrderItemStatusValueProps) =>
    error ? colors.main : '#1AA800'};
`;

export const DownloadItemWrapper = styled.div`
  ${mixins.flex({ horizontal: true, gap: 8, alignItems: 'center' })}
  margin-bottom: 13px;
  padding-bottom: 13px;
  color: ${colors.darkBlue2};
`;

export const DownloadItemContent = styled.div`
  ${mixins.flex({
    inline: true,
    horizontal: true,
    gap: 8,
    alignItems: 'center',
  })}
  cursor: pointer;
`;

export const EmailWrapper = styled.div`
  padding-top: 32px;
`;
