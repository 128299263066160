import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { ArrowLeftIcon, ArrowRightIcon, FormLabel } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, nameRegEx, notDigital } from '@src/constants';
import { useRequest } from '@src/hooks';
import { AuthActionTypes, Store } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';
import type { AuthConfirmFormProps } from './authorization.types';

const schema = yup.object().shape({
  lastName: yup
    .string()
    .required('Укажите фамилию')
    .test(
      'lenLn',
      'Максимальная длина 50 символов',
      (value) => value!.length < 50
    )
    .test('validFirstLetterLN', 'Имя должно начинаться не с цифры', (value) =>
      notDigital.test(value!)
    )
    .test('validLasttName', 'Используйте кириллицу', (value) =>
      nameRegEx.test(value!)
    )
    .test(
      'containSpacesLn',
      'Фамилия не должна содержать пробелов',
      (value) => !value!.includes(' ')
    ),
});

export const AuthorizationConfirmPage: FC = () => {
  const {
    state: {
      stateAuth: { authorizationToken, authorizeRefRoute, phoneNumber },
    },
    dispatch,
  } = useContext(Store);

  const [lastNameValue, setLastNameValue] = useState<string>('');

  const formProps = useMemo(
    () => ({
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: {
        lastName: '',
      },
    }),
    [schema]
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AuthConfirmFormProps>(formProps);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'authRequest',
    'post',
    '/v1/user/authorization',
    {
      lastName: lastNameValue,
    },
    [lastNameValue],
    true,
    authorizationToken
  );

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/registration');
    }
  }, []);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: res,
      });
      sendAnalyticEvent(analyticEvents.signupCompleted, {
        registration: 'n',
      });

      if (res.isVip) {
        navigate('/vip');
      }

      navigate(authorizeRefRoute || '/');
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (lastNameValue) {
      refetch();
    }
  }, [lastNameValue]);

  const submitPage = handleSubmit((data) => {
    if (lastNameValue === data.lastName) {
      refetch();
    }

    setLastNameValue(data.lastName);
    sendAnalyticEvent(analyticEvents.enterSurname);
  });

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={() => {
          navigate(-1);
        }}
        disabled={isLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        disabled={isLoading}
        themeColor="primary"
        label="Продолжить"
        onClick={submitPage}
      />
    </>
  );

  useEffect(() => {
    if (error) {
      const e = (error as any).response.status;
      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Не узнаём вас',
            subtitle: 'Введите фамилию, которую вы указали при регистрации',
            refRoute: '/authorize-confirm',
          },
        });
        navigate('/authorize-fail');
      }

      if (
        e === 400 &&
        (error as any).response.data.code === 'RATE_LIMIT_EXCEEDED'
      ) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Возьмите паузу',
            subtitle:
              'Превышено количество запросов, попробуйте позже или обратитесь в поддержку',
            refRoute: '/registration',
          },
        });
        navigate('/authorize-fail');
      }

      const msg =
        !!error || error !== null ? 'Ошибка отправки, попробуйте еще раз' : '';

      setError('lastName', { type: 'string', message: msg });
    }
  }, [error]);

  return (
    <BaseLayout verticalAlignment={true} footer={<Footer />}>
      <FormLabel>Начинаем вас узнавать, напомните свою фамилию</FormLabel>
      <Controller
        control={control}
        name="lastName"
        render={({ field: { onChange, onBlur, value }, fieldState }) => (
          <HelperText
            status={fieldState.error ? 'error' : 'default'}
            message={errors.lastName?.message}
          >
            <Input
              name="lastName"
              label="Фамилия"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.lastName || !!error}
            />
          </HelperText>
        )}
      />
    </BaseLayout>
  );
};
