import { OrderRequestData } from '@src/types';

import type {
  OrderDataTypes,
  OrderInitDataTypes,
  OrderSubmitDataTypes,
} from './order-store.types';

export interface OrderStoreState {
  order: OrderDataTypes | undefined;
  orderSubmitData: OrderSubmitDataTypes | undefined;
  orderInitData: OrderInitDataTypes | undefined;
  orderPageTitle: string | undefined;
  orderRequestData: OrderRequestData | undefined;
  cachedOrderRequestData: OrderRequestData | undefined;
}

export const initOrderStoreState: OrderStoreState = {
  order: undefined,
  orderSubmitData: undefined,
  orderInitData: undefined,
  orderPageTitle: undefined,
  orderRequestData: undefined,
  cachedOrderRequestData: undefined,
};
