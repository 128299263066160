/* eslint-disable indent */
import type { FC, ReactNode } from 'react';
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { ArrowLeftIcon, ArrowRightIcon } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, iflRoute, nsRoute } from '@src/constants';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

import { ContentArea, NavFooter } from './step-wizard-form.styles';
import { NavHeader } from './step-wizard-header';

type Props = {
  maxStep: number;
  children: ReactNode;
  useResetSteps: () => void;
  nextButtonLabel?: string;
  goBack?: () => void;
};

export const StepWizardForm: FC<Props> = ({
  maxStep,
  useResetSteps,
  children,
  nextButtonLabel,
  goBack,
}) => {
  const {
    state: {
      stateWizard,
      stateAuth: { authorizeRefRoute },
    },
    dispatch,
  } = useContext(Store);
  const { currentStep } = stateWizard;
  const location = useLocation();

  useResetSteps();

  const setStep = (step: number) => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: step,
    });
  };

  const setNextStep = () => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  };

  const onClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep,
        });
        break;
      // TODO: добавить аналитику для питомцев
    }

    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: false,
    });

    if (currentStep === 1 && goBack) {
      goBack();
    } else {
      setStep(currentStep - 1);
    }
  };

  return useMemo(
    () => (
      <>
        <NavHeader currentStep={currentStep} maxStep={maxStep} />
        <ContentArea>{children}</ContentArea>
        <NavFooter>
          <Button
            themeColor="gray"
            variant="circle"
            onClick={onClickBack}
            disabled={stateWizard.backNavDisabled}
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            themeColor="primary"
            onClick={setNextStep}
            disabled={stateWizard.fwNavDisabled}
          >
            {nextButtonLabel || 'Продолжить'}
          </Button>
        </NavFooter>
      </>
    ),
    [
      currentStep,
      stateWizard.fwNavDisabled,
      stateWizard.backNavDisabled,
      goBack,
      nextButtonLabel,
    ]
  );
};
