/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { Store } from '@src/store';

export const useAnalyticsIfl = () => {
  const {
    state: {
      stateWizard: { wantNextStep, currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (wantNextStep) {
      switch (currentStep) {
        case 1:
          sendAnalyticEvent(analyticEvents.toStep2Realty);
          break;
        case 2:
          sendAnalyticEvent(analyticEvents.toStep3Realty);
          break;
        case 3:
          sendAnalyticEvent(analyticEvents.toStep4Realty);
          break;
        case 4:
          sendAnalyticEvent(analyticEvents.toStep5Realty);
          break;
        case 5:
          sendAnalyticEvent(analyticEvents.toConfirmationRealty);
          break;
        default:
          break;
      }
    }
  }, [wantNextStep]);
};
