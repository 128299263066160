/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { IFLHouseActionTypes, Store, initStoreState } from '@src/store';

export const useResetHouseSteps = () => {
  const {
    state: { stateWizard },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    if (stateWizard.updateFormState) {
      switch (stateWizard.currentStep) {
        case 1:
          dispatch({
            type: IFLHouseActionTypes.SetRisks,
            payload: initStoreState.stateFormIFLHouse.risks,
          });
          dispatch({
            type: IFLHouseActionTypes.SetInsuranceSubproducts,
            payload: initStoreState.stateFormIFLHouse.insuranceSubproducts,
          });
          break;
        case 2:
          dispatch({
            type: IFLHouseActionTypes.SetInsuranceSubproducts,
            payload: initStoreState.stateFormIFLHouse.insuranceSubproducts,
          });
          break;
        default:
          break;
      }
    }
  }, [stateWizard.currentStep, stateWizard.updateFormState]);
};
