import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { ListRangeItem } from '@pulse-web-ui/list-range-item';

import {
  AdaptiveHeadersWrapper,
  AdaptivePerMonthFooter,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  IflSumPerMonthContainer,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { DEFAULT_FIAS_ID, analyticEvents } from '@src/constants';
import { GlobalErrorInfo, IflSumPerMonth, TotalSum } from '@src/features';
import { IflSumPerMonthError } from '@src/features/ifl-sum-per-month';
import { useNextStep, useRequest } from '@src/hooks';
import { subObjectConverter } from '@src/pages/ifl-form/utils';
import { Store } from '@src/store';
import { IFLHouseActionTypes } from '@src/store/ifl-house';
import type { InsuranceSubproducts } from '@src/store/ifl-house';
import { WizardActionTypes } from '@src/store/wizard';
import {
  InsuranceConvertedSubProduct,
  InsuranceSubProduct,
  InsuranceSubProductType,
} from '@src/types';
import { numBetween, numFormat } from '@src/utils';

const schemaObj: Record<string, any> = {};
let defaults: Record<string, number> = {};

const getErrorMessage = (name: string) =>
  `${name} ${
    name === 'Гражданская ответсвенность'
      ? 'не может быть застрахована'
      : 'не могут быть застрахованы'
  } без покрытия хотя бы одной из выделенных категорий`;

export const FormInsuranceSum: FC = () => {
  const {
    state: {
      stateFormIFLHouse: {
        insuranceSubproducts,
        selectedIProduct,
        selectedRegion,
        risks,
        selectedBuildingMaterialCode,
        fieldWithEmptyDependencies,
        emptyDependencies,
        dadaValue,
        getSubobjectsRefetchUid,
      },
      stateWizard: { currentStep },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [schema, setSchema] = useState<any>();
  const [currentSum, setCurrentSum] = useState(2_000_000);
  const [currentSumMinLimit, setCurrentSumMinLimit] = useState('0');
  const [currentSumMaxLimit, setCurrentSumMaxLimit] = useState('0');
  const [currentSumErr, setCurrentSumErr] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [newSubobjects, setnewSubobjects] = useState<
    InsuranceConvertedSubProduct[] | undefined
  >();
  const navigate = useNavigate();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep4Сoverage);
    sendAnalyticEvent(analyticEvents.iflHouseStep4Сoverage);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    if (insuranceSubproducts?.subObjects) {
      setnewSubobjects(subObjectConverter(insuranceSubproducts?.subObjects));
    }
  }, [insuranceSubproducts?.subObjects]);

  // const { minProductLimit, maxProductLimit } = selectedIProduct!;

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: (errors as any)?.inner?.reduce(
              (allErrors: any, currentError: ValidationError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const resolver = useYupValidationResolver(schema);

  const { control, formState, reset, trigger, getValues } = useForm<
    Record<string, number>
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...defaults,
    },
  });

  const updateInsuranseSubproducts = async (code: string, value: number) => {
    setStepUpdated();
    const valid = await trigger(code);

    if (valid) {
      const updatedInsuranceSubproducts: InsuranceSubproducts = {
        subObjects: [],
        subObjectsDependencyScheme:
          insuranceSubproducts?.subObjectsDependencyScheme,
      };

      insuranceSubproducts?.subObjects.map((item) => {
        if (item.code === code) {
          item.defaultInsuranceSum = String(value);
        }
        updatedInsuranceSubproducts.subObjects.push(item);
      });

      dispatch({
        type: IFLHouseActionTypes.SetInsuranceSubproducts,
        payload: updatedInsuranceSubproducts,
      });

      let totalSum = 0;

      updatedInsuranceSubproducts.subObjects.map(
        (subobject: any) => (totalSum += Number(subobject.defaultInsuranceSum))
      );

      setCurrentSum(totalSum);
      setSelectedId(undefined);

      if (
        value > 0 &&
        !!insuranceSubproducts?.subObjectsDependencyScheme?.[code]
      ) {
        const values = getValues();

        if (
          insuranceSubproducts.subObjectsDependencyScheme[code].every(
            (dependencyName) => !values[dependencyName]
          )
        ) {
          dispatch({
            type: IFLHouseActionTypes.SetEmptyDependencies,
            payload: insuranceSubproducts.subObjectsDependencyScheme[code],
          });
          dispatch({
            type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
            payload: code,
          });
        }
      }

      if (value === 0 && !!insuranceSubproducts?.subObjectsDependencyScheme) {
        const values = getValues();

        const { fieldNameWithEmptyDependencies, emptyDependencyNames } =
          Object.keys(insuranceSubproducts?.subObjectsDependencyScheme).reduce(
            (
              acc: {
                fieldNameWithEmptyDependencies?: string;
                emptyDependencyNames: string[];
              },
              fieldName
            ) => {
              const dependencies =
                insuranceSubproducts?.subObjectsDependencyScheme?.[fieldName];

              if (
                values[fieldName] > 0 &&
                !!dependencies?.length &&
                dependencies.some(
                  (dependencyName) => dependencyName === code
                ) &&
                dependencies.every((dependencyName) => !values[dependencyName])
              ) {
                return {
                  fieldNameWithEmptyDependencies: fieldName,
                  emptyDependencyNames: dependencies,
                };
              }

              return acc;
            },
            {
              fieldNameWithEmptyDependencies: undefined,
              emptyDependencyNames: [],
            }
          );

        if (
          !!emptyDependencyNames &&
          !!fieldNameWithEmptyDependencies?.length
        ) {
          dispatch({
            type: IFLHouseActionTypes.SetEmptyDependencies,
            payload: emptyDependencyNames,
          });
          dispatch({
            type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
            payload: fieldNameWithEmptyDependencies,
          });
        }
      }

      if (
        (value === 0 && code === fieldWithEmptyDependencies) ||
        (value > 0 && emptyDependencies?.some((name) => name === code))
      ) {
        dispatch({
          type: IFLHouseActionTypes.SetEmptyDependencies,
          payload: [],
        });
        dispatch({
          type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
          payload: undefined,
        });
      }
    }
  };

  const { isLoading, error, res, refetch } = useRequest(
    'formIFLHooksGetSubproducts',
    'post',
    '/v1/references/get-subobjects',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
      risks: risksArray,
      buildingMaterial: selectedBuildingMaterialCode,
    },
    [
      risks,
      getSubobjectsRefetchUid,
      selectedBuildingMaterialCode,
      selectedIProduct?.code,
      selectedRegion?.region,
      authTokens?.authorization?.accessToken,
    ],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    isFetching: isFetchingSumPerMonth,
    error: errorSumPerMonth,
    res: resSumPerMonth,
    refetch: refetchSumPerMonth,
  } = useRequest(
    'formIFLGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks: risksArray,
      productCode: selectedIProduct?.code,
      insuranceSum: Number(currentSum),
      personProperties: {
        subObjects: newSubobjects,
        address: {
          regionCode: selectedRegion?.region,
          address: dadaValue?.value || '',
          addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
        },
      },
      buildingMaterial: selectedBuildingMaterialCode,
      returnMinDuration: true,
    },
    [
      selectedIProduct?.code,
      currentSum,
      newSubobjects,
      selectedRegion?.region,
      selectedBuildingMaterialCode,
      authTokens?.authorization?.accessToken,
      dadaValue?.value,
      dadaValue?.data?.fias_id,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  const validatePage = useCallback(
    () =>
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      (!selectedId && !currentSumErr),
    [selectedId, currentSumErr, fieldWithEmptyDependencies, emptyDependencies]
  );

  const handleRefetch = () => {
    if (error) refetch();
    if (errorSumPerMonth) refetchSumPerMonth();
  };

  useNextStep(validatePage);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (newSubobjects) refetchSumPerMonth();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [newSubobjects]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: IFLHouseActionTypes.SetInsuranceSubproducts,
        payload: res,
      });

      let totalSum = 0;

      setCurrentSumMinLimit(
        res.totalMinLimit ?? selectedIProduct?.minProductLimit ?? '0'
      );

      setCurrentSumMaxLimit(
        res.totalMaxLimit ?? selectedIProduct?.maxProductLimit ?? '0'
      );

      defaults = {};

      res.subObjects.map((subobject: InsuranceSubProductType) => {
        schemaObj[subobject.code] = yup
          .number()
          .typeError('Вы должны ввести число')
          .min(
            Number(subobject.minLimit),
            `Сумма не менее ${Number(subobject.minLimit)}₽`
          )
          .max(
            Number(subobject.maxLimit),
            `Сумма не более ${Number(subobject.maxLimit)}₽`
          )
          .integer('Сумма должна быть целым значением')
          .required()
          .test(
            'In diapason?',
            'Сумма не может быть от 1 до 49999 рублей',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (value) => value! > 49_999 || value! < 1
          );

        totalSum += Number(subobject.defaultInsuranceSum);
        defaults[subobject.code] = Number(subobject.defaultInsuranceSum);
      });

      const newSchema = yup.object(schemaObj);
      setSchema(newSchema);

      setCurrentSum(totalSum);
      reset(defaults);
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (currentSum > 0) {
      dispatch({
        type: IFLHouseActionTypes.SetInsuranceSum,
        payload: Number(currentSum),
      });
    }
  }, [currentSum]);

  useEffect(() => {
    setCurrentSumErr(
      !numBetween(
        currentSum,
        Number(currentSumMinLimit),
        Number(currentSumMaxLimit),
        true
      )
    );
  }, [currentSum, currentSumMinLimit, currentSumMaxLimit]);

  useEffect(() => {
    const disableNav =
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      ((!!selectedId || currentSumErr) && (currentSumErr || !!selectedId));
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: disableNav,
    });
  }, [
    selectedId,
    currentSumErr,
    currentStep,
    fieldWithEmptyDependencies,
    emptyDependencies,
  ]);

  if (isLoading) return <Skeleton />;

  if (error && (error as any)?.response?.data.code === 'USER_DATA_ERROR') {
    navigate('/score-error');
  }

  if (
    errorSumPerMonth &&
    (errorSumPerMonth as any)?.response?.data.code === 'OBJECT_DATA_ERROR'
  ) {
    dispatch({
      type: IFLHouseActionTypes.SetIsObjectDataError,
      payload: true,
    });

    navigate('/score-error');
  }

  if (
    (error && (error as any)?.response?.data.code !== 'USER_DATA_ERROR') ||
    (errorSumPerMonth &&
      (errorSumPerMonth as any)?.response?.data.code !== 'VALIDATION_ERROR' &&
      (errorSumPerMonth as any)?.response?.data.code !== 'BUSINESS_ERROR' &&
      (errorSumPerMonth as any)?.response?.data.code !== 'TECHNICAL_ERROR' &&
      (errorSumPerMonth as any)?.response?.data.code !== 'OBJECT_DATA_ERROR')
  )
    return (
      <GlobalErrorInfo pending={isLoading} retrayHandler={handleRefetch} />
    );

  if (!isLoading && res?.length === 0)
    return (
      <div>
        <h3>Список пуст</h3>
      </div>
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          Выберите суммы покрытия в каждой категории
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          Они определяют максимальную выплату при наступлении страхового события
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      <AdaptiveSliderListWrapper>
        {insuranceSubproducts?.subObjects.map((item: InsuranceSubProduct) => (
          <Controller
            key={`${item.code}-key`}
            name={item.code}
            control={control}
            render={({ field }) => (
              <ListRangeItem
                label={item.name}
                value={Number(item.defaultInsuranceSum)}
                min={Number(item.minLimit)}
                max={Number(item.maxLimit)}
                step={Number(item.step)}
                description={item.description}
                itemIcon={item.imageUrl}
                status={{
                  type:
                    (formState.errors[item.code]?.message && 'error') ||
                    'default',
                  message: formState.errors[item.code]?.message,
                }}
                onChange={(value: number) => {
                  field.onChange(value);
                  updateInsuranseSubproducts(item.code, value);
                  sendAnalyticEvent(analyticEvents.coverageSumChangeRealty, {
                    coverage_type: item.name,
                  });
                }}
                setSelectedId={() => {
                  setSelectedId(item.code);
                  sendAnalyticEvent(analyticEvents.coverageExplanationRealty, {
                    coverage_type: item.name,
                  });
                }}
                id={item.code}
                disabled={!!selectedId && selectedId !== item.code}
                isOpen={selectedId === item.code}
                isError={item.code === fieldWithEmptyDependencies}
                isHighlighted={emptyDependencies?.some(
                  (name) => name === item.code
                )}
                errorMessage={
                  item.code === fieldWithEmptyDependencies
                    ? getErrorMessage(item.name)
                    : undefined
                }
              />
            )}
          />
        ))}
      </AdaptiveSliderListWrapper>
      {insuranceSubproducts?.subObjects && (
        <AdaptivePerMonthFooter>
          <SumWrapper>
            <TotalSum
              title="Общая сумма покрытия"
              totalSum={numFormat(currentSum)}
              isError={currentSumErr}
              subtitleText={
                `от ${numFormat(Number(currentSumMinLimit))} ₽ ` +
                `до ${numFormat(Number(currentSumMaxLimit))} ₽`
              }
            />
          </SumWrapper>
          <SumWrapper>
            <IflSumPerMonthContainer>
              {(currentSum || currentSum === 0) && newSubobjects && (
                <IflSumPerMonth
                  isLoading={isFetchingSumPerMonth || !!errorSumPerMonth}
                  disabled={isLoading || !!selectedId}
                  sumPerMonth={Number(
                    resSumPerMonth?.prices[0].premiumAndDelta
                  )}
                />
              )}
              {errorSumPerMonth && (
                <IflSumPerMonthError
                  errorResponse={errorSumPerMonth}
                  currentSumErr={
                    currentSumErr &&
                    (currentSum < Number(currentSumMinLimit) ? 'less' : 'gt')
                  }
                />
              )}
            </IflSumPerMonthContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
      )}
    </Container>
  );
};
