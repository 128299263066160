/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { IFLFlatStoreState } from './index';
import { IFLFlatActionTypes } from './index';

export type IFLFlatStoreReducer = (
  state: IFLFlatStoreState,
  action: StoreActions
) => IFLFlatStoreState;

export const formIFLFlatStoreReducer: IFLFlatStoreReducer = (state, action) => {
  switch (action?.type) {
    case IFLFlatActionTypes.SetSelectedIProduct:
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedRegion:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case IFLFlatActionTypes.SetRegions:
      return {
        ...state,
        regions: action.payload,
      };
    case IFLFlatActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case IFLFlatActionTypes.SetRisksPage:
      return {
        ...state,
        risksPage: action.payload,
      };
    case IFLFlatActionTypes.SetInsuranceSubproducts:
      return {
        ...state,
        insuranceSubproducts: action.payload,
      };
    case IFLFlatActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case IFLFlatActionTypes.SetDadaValue:
      return {
        ...state,
        dadaValue: action.payload,
      };
    case IFLFlatActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case IFLFlatActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case IFLFlatActionTypes.SetFieldWithEmptyDependencies:
      return {
        ...state,
        fieldWithEmptyDependencies: action.payload,
      };
    case IFLFlatActionTypes.SetEmptyDependencies:
      return {
        ...state,
        emptyDependencies: action.payload,
      };

    case IFLFlatActionTypes.SetIsObjectDataError:
      return {
        ...state,
        isObjectDataError: action.payload,
      };
    case IFLFlatActionTypes.SetGetSubobjectsRefetchUid:
      return {
        ...state,
        getSubobjectsRefetchUid: action.payload,
      };
    default:
      return state;
  }
};
