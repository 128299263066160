import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Check, Edit } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { RangeSlider } from '@pulse-web-ui/range-slider';
import { colors } from '@pulse-web-ui/theme';

import {
  AdaptiveSliderListWrapper,
  AlignedWrapper,
  Container,
  FormSub,
  TotalSum,
} from '@src/components';
import {
  AdaptivePerMonthHeader,
  FormCaption,
  FormEditIconWrapper,
  FormFieldWrapper,
  FormHead,
  SumPerMonthOuterWrapper,
} from '@src/components/form-elements';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo, SumPerMonth } from '@src/features';
import { useIsDesktop, useNextStep, useRequest } from '@src/hooks';
import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { currencyRuLocale, numFormat } from '@src/utils/currency-format';

export const FormInsuranceSum: FC = memo(() => {
  const {
    state: {
      stateFormNS: {
        insuranceSum,
        insuranceProduct,
        selectedMainRisks = [],
        selectedAdditionalRisks = [],
        numberInsurePersons: { numberChildren, numberAdults, numberElderly },
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [isOpenField, setIsOpenField] = useState(false);
  const isDesktop = useIsDesktop();

  const risks = useMemo(
    () => [...selectedMainRisks, ...selectedAdditionalRisks],
    [selectedMainRisks, selectedAdditionalRisks]
  );

  const persons = useMemo(
    () => [
      ...Array(numberChildren).fill({ ageMin: '1' }),
      ...Array(numberAdults).fill({ ageMin: '18' }),
      ...Array(numberElderly).fill({ ageMin: '70' }),
    ],
    [numberChildren, numberAdults, numberElderly]
  );

  const schema = yup.object({
    editedSum: yup
      .number()
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      )
      .typeError('Вы должны ввести число')
      .min(
        Number(insuranceProduct?.minProductLimit),
        `Сумма не менее ${Number(insuranceProduct?.minProductLimit)}₽`
      )
      .max(
        Number(insuranceProduct?.maxProductLimit),
        `Сумма не более ${Number(insuranceProduct?.maxProductLimit)}₽`
      )
      .integer('Сумма должна быть целым значением')
      .required()
      .test(
        'In diapason?',
        'Сумма не может быть от 1 до 49999 рублей',
        (value) => value! > 49_999 || value! < 1
      ),
  });

  const {
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<{ editedSum: number }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      editedSum: 0,
    },
  });

  const toggleOpenField = () => setIsOpenField(!isOpenField);

  const storeInsuranceSum = useCallback((data: number) => {
    dispatch({
      type: NSActionTypes.SetInsuranceSum,
      payload: data,
    });
  }, []);

  const updateInsuranceSum = () => {
    if (!errors.editedSum) {
      storeInsuranceSum(Number(getValues('editedSum')));
      toggleOpenField();
    }
  };

  const validatePage = useCallback(() => !isOpenField, [isOpenField]);

  const { isLoading, error, res, refetch, isFetching } = useRequest(
    'formNSGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks,
      productCode: insuranceProduct?.code,
      insuranceSum: Number(insuranceSum),
      returnMinDuration: true,
      accidents: {
        persons,
      },
    },
    [
      numberChildren,
      numberAdults,
      numberElderly,
      authTokens?.authorization?.accessToken,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  useNextStep(validatePage);

  useEffect(() => {
    const handler = setTimeout(() => {
      refetch();
      sendAnalyticEvent(analyticEvents.coverageSumChangeAccident);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [insuranceSum]);

  useEffect(() => {
    if (!isOpenField) reset({ editedSum: insuranceSum });
  }, [insuranceSum]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: isOpenField,
    });
    dispatch({
      type: WizardActionTypes.SetBackNavDisabled,
      payload: isOpenField,
    });
  }, [isOpenField]);

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  return (
    <Container>
      {insuranceProduct && insuranceSum && (
        <>
          <FormHead>
            <AdaptivePerMonthHeader>
              <AlignedWrapper>
                <span>Сумма покрытия: </span>
                <TotalSum>{currencyRuLocale(insuranceSum)} &#8381;</TotalSum>
                {!isOpenField && (
                  <FormEditIconWrapper onClick={toggleOpenField}>
                    <Edit color={colors.darkGray} />
                  </FormEditIconWrapper>
                )}
                <FormSub>
                  Исходя из неё будут рассчитываться размеры выплат и стоимость
                  подписки
                </FormSub>
              </AlignedWrapper>
              {isDesktop && (
                <SumPerMonthOuterWrapper>
                  <SumPerMonth
                    isLoading={isFetching || !!error}
                    sumPerMonth={res?.prices[0].premiumAndDelta}
                  />
                </SumPerMonthOuterWrapper>
              )}
            </AdaptivePerMonthHeader>
          </FormHead>
          {isOpenField && (
            <Controller
              name="editedSum"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormFieldWrapper>
                  <HelperText
                    message={errors?.editedSum?.message}
                    status={errors?.editedSum && 'error'}
                  >
                    <Input
                      value={String(value)}
                      onChange={onChange}
                      error={!!errors?.editedSum}
                    />
                  </HelperText>
                  <Button
                    themeColor="primary"
                    onClick={updateInsuranceSum}
                    variant="circle"
                  >
                    <Check color={colors.white} width={12} />
                  </Button>
                </FormFieldWrapper>
              )}
            />
          )}
          {!isOpenField && (
            <AdaptiveSliderListWrapper>
              <RangeSlider
                min={Number(insuranceProduct.minProductLimit)}
                max={Number(insuranceProduct.maxProductLimit)}
                value={Number(insuranceSum)}
                step={Number(insuranceProduct.step)}
                onChange={storeInsuranceSum}
                disabled={false}
              />
            </AdaptiveSliderListWrapper>
          )}
          <FormCaption>
            {`Выберите сумму от ${numFormat(
              Number(insuranceProduct.minProductLimit)
            )} до ${numFormat(Number(insuranceProduct.maxProductLimit))}`}
          </FormCaption>
          {!isDesktop && (
            <SumPerMonth
              isLoading={isFetching || !!error}
              sumPerMonth={res?.prices[0].premiumAndDelta}
            />
          )}
        </>
      )}
    </Container>
  );
});
