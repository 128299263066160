import type { FC } from 'react';

import { useIsDesktop } from '@src/hooks';
import { currencyRuLocaleWithTwoFractionDigits } from '@src/utils';

import {
  StyledSpinner,
  SumPerMonthTitle,
  SumPerMonthValue,
  SumPerMonthWrapper,
} from './sum-per-month.styles';
import type { SumPerMonthProps } from './sum-per-month.types';

export const SumPerMonth: FC<SumPerMonthProps> = ({
  isLoading,
  disabled,
  sumPerMonth,
  desktopTitle,
  mobileTitle,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <SumPerMonthWrapper disabled={disabled}>
      <SumPerMonthTitle>
        {isDesktop
          ? desktopTitle || 'Стоимость страховки в месяц'
          : mobileTitle || 'стоимость в месяц'}
      </SumPerMonthTitle>
      {isLoading || !sumPerMonth ? (
        <StyledSpinner inverse={false} />
      ) : (
        <SumPerMonthValue>
          {currencyRuLocaleWithTwoFractionDigits(sumPerMonth)} &#8381;
        </SumPerMonthValue>
      )}
    </SumPerMonthWrapper>
  );
};
