import type { DaDataAddressSuggestion } from 'react-dadata';

import type { OptionsMap } from '@pulse-web-ui/list-box/lib/list-box.types';

import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubProduct,
  Region,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type InsuranceSum = number;

export type InsuranceScheme = Record<string, string[]>;

export type SelectedIProduct = undefined | InsuranceProductType;

export type InsuranceSubproducts = {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
};

export interface IFLFlatStoreState {
  selectedIProduct: undefined | InsuranceProductType;
  selectedRegion: undefined | Region;
  regions: undefined | OptionsMap;
  risks: undefined | Risk[];
  insuranceSubproducts: undefined | InsuranceSubproducts;
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  dadaValue: undefined | DaDataAddressSuggestion;
  promoCode?: string;
  risksPage: 'primary' | 'secondary';
  fieldWithEmptyDependencies: string | undefined;
  emptyDependencies: string[] | undefined;
  isObjectDataError?: boolean | undefined;
  getSubobjectsRefetchUid?: string;
}

export const initIFLFlatStoreState: IFLFlatStoreState = {
  selectedIProduct: undefined,
  selectedRegion: undefined,
  regions: undefined,
  risks: undefined,
  insuranceSubproducts: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  selectedDuration: null,
  selectedDate: null,
  dadaValue: undefined,
  promoCode: undefined,
  risksPage: 'primary',
  fieldWithEmptyDependencies: undefined,
  emptyDependencies: [],
  isObjectDataError: undefined,
};
