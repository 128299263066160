import type { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';

import {
  Container,
  FormLabel,
  FormSub,
  FormSubLabel,
  GridColumnContainer,
} from '@src/components';

import { BaseLayout } from '../../layouts/base-layout';

export const BestToPayErrorPage: FC = () => {
  const pageTitle = 'Платёж отклонён';
  const pageSubTitle =
    'К сожалению, по технической причине платёж отклонен. Попробуйте еще раз';

  const retryHandler = () => {
    localStorage.setItem('retryPayment', uuid());
  };

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={24}>
        <Container>
          <FormLabel>{pageTitle}</FormLabel>
          <FormSubLabel>{pageSubTitle}</FormSubLabel>
          <FormSub>
            <Button
              adaptiveWidth
              onClick={retryHandler}
              themeColor="primary"
              label="Попробовать еще раз"
            />
          </FormSub>
        </Container>
      </GridColumnContainer>
    </BaseLayout>
  );
};
