/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import {
  ArrowLeftIcon,
  Container,
  FormSubTitle,
  OrderSubTitle,
  OrderTitle,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  UseQueryStatus,
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  nsRoute,
  petsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest, useWebanalyticParams } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { formOrderDetailSchema } from '@src/schemas';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import {
  OrderItemType,
  OrderItemValueType,
  OrderItemsBlockType,
} from '@src/types';

import {
  EmailWrapper,
  OrderContentWrapper,
  OrderPageTitleWrapper,
} from './components/components.styles';
import { OrderBlockItem } from './components/order-block-item';
import { OrderItem } from './components/order-item';

export const NewOrderDetail: FC = () => {
  const {
    state: {
      stateUser: { profile },
      stateOrder: {
        order,
        orderSubmitData,
        orderPageTitle,
        orderRequestData,
        cachedOrderRequestData,
      },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] =
    useState<boolean>(false);
  const [shouldNavigateCheckout, setShouldNavigateCheckout] =
    useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string | undefined | null>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(formOrderDetailSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      email: profile?.profile.email,
    },
  });
  // TODO: добавить цветные шапки когда будем делать темизацию
  // const bgUrl = useMemo(() => {
  //   switch (authorizeRefRoute) {
  //     case iflRoute:
  //     case iflHouseRoute:
  //     case iflFlatRoute:
  //       return SvgBgIfl;
  //     case nsRoute:
  //       return SvgBgNs;
  //     case petsRoute:
  //       return SvgBgPets;
  //     default:
  //       break;
  //   }
  // }, [authorizeRefRoute]);

  const orderAdditionalSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case iflRoute:
      case iflHouseRoute:
      case iflFlatRoute:
      case nsRoute:
        return 'Вы всегда можете изменить условия подписки';
      case petsRoute:
        return 'Вы всегда можете отменить подписку';
      default:
        return 'Вы всегда можете изменить условия подписки';
    }
  }, [authorizeRefRoute]);

  useEffect(() => {
    switch (authorizeRefRoute) {
      case iflHouseRoute:
        sendAnalyticEvent(analyticEvents.iflStep7Confirm);
        sendAnalyticEvent(analyticEvents.iflHouseStep7Confirm);
        break;
      case iflFlatRoute:
        sendAnalyticEvent(analyticEvents.iflStep7Confirm);
        sendAnalyticEvent(analyticEvents.iflFlatStep7Confirm);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toConfirmationAccident);
        break;
      case petsRoute:
        sendAnalyticEvent(analyticEvents.petToStepConfirm);
        break;
      default:
        break;
    }
  }, []);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch, status } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    '/v1/subscription/submit',
    orderRequestData,
    [orderSubmitData, updatedEmail],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: updateProfileIsLoading,
    error: updateProfileError,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        email: updatedEmail,
      },
    },
    [profile],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 1,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 1,
        });
        break;
      // TODO: добавить аналитику питомцев
    }

    navigate(-1);
  };

  const onSubmitPageHandler = handleSubmit((data) => {
    localStorage.setItem('email', data?.email || profile?.profile.email || '');

    if (!profile?.profile.email || profile?.profile.email !== data.email) {
      setUpdatedEmail(data.email);
      setShouldUpdateProfile(true);

      updateProfileRefetch();
    }

    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseAccident);
        break;
      // TODO: добавить аналитику питомцев
    }

    setShouldSubmit(true);
    setShouldNavigateCheckout(true);
  });

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      setShouldUpdateProfile(false);

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });
    }
  }, [updateProfileIsLoading, updateProfileRes]);

  useEffect(() => {
    if (!isLoading && res) {
      setShouldSubmit(false);
      dispatch({
        type: OrderActionTypes.SetCachedOrderRequestData,
        payload: orderRequestData,
      });

      dispatch({
        type: OrderActionTypes.SetOrderSubmitData,
        payload: res,
      });
    }
  }, [res]);

  useEffect(() => {
    if (error && !res) {
      const e = (error as any).response.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Что-то пошло не так',
            subtitle: 'Попробуйте зарегистрироваться еще раз',
            refRoute: '/personal-info',
          },
        });

        navigate('/new-authorize-fail');
      }
    }
  }, [error, res]);

  useEffect(() => {
    if (updateProfileError && !updateProfileRes) {
      const e = (updateProfileError as any).response.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Что-то пошло не так',
            subtitle: 'Попробуйте зарегистрироваться еще раз',
            refRoute: '/personal-info',
          },
        });

        navigate('/new-authorize-fail');
      } else if (
        (updateProfileError as any)?.response?.data?.code === 'NOT_UNIQUE_EMAIL'
      ) {
        setError('email', {
          type: 'string',
          message: 'Такой email уже используется',
        });
      }
    }
  }, [updateProfileError, updateProfileRes]);

  useEffect(() => {
    if (!order) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/');
    }
  }, [order]);

  useEffect(() => {
    if (!authTokens) {
      navigate('/personal-info');
    }
  }, [authTokens]);

  useWebanalyticParams();

  useEffect(() => {
    if (
      (shouldSubmit &&
        !isLoading &&
        JSON.stringify(orderRequestData) !==
          JSON.stringify(cachedOrderRequestData)) ||
      shouldUpdateProfile
    ) {
      if (shouldUpdateProfile) {
        if (
          !updateProfileIsLoading &&
          updateProfileRes &&
          !updateProfileError
        ) {
          refetch();
        }
      } else if (status === UseQueryStatus.IDLE) {
        refetch();
      }
    } else if (shouldNavigateCheckout && !isLoading) {
      setShouldNavigateCheckout(false);

      navigate('/new-order-checkout');
    }
  }, [
    shouldUpdateProfile,
    updateProfileIsLoading,
    updateProfileRes,
    updateProfileError,
    shouldSubmit,
    orderRequestData,
    cachedOrderRequestData,
    shouldNavigateCheckout,
    isLoading,
  ]);

  if (error) {
    const e = (error as any)?.response?.status;

    if (e !== 401) {
      return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
    }
  }

  if (updateProfileError) {
    const response = (updateProfileError as any)?.response;

    if (
      response?.status !== 401 &&
      response?.data?.code !== 'NOT_UNIQUE_EMAIL'
    ) {
      return (
        <GlobalErrorInfo
          pending={updateProfileIsLoading}
          retrayHandler={updateProfileRefetch}
        />
      );
    }
  }

  if (updateProfileIsLoading || isLoading) return <Skeleton />;

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={handleClickBack}
        disabled={isLoading || updateProfileIsLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        label="Продолжить"
        themeColor="primary"
        onClick={onSubmitPageHandler}
        disabled={
          isLoading || updateProfileIsLoading || !!Object.keys(errors)?.length
        }
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        {/* <OrderHeader bgUrl={bgUrl}> // TODO: добавить цветные шапки когда будем делать темизацию */}
        <OrderTitle>{profile?.profile.firstName}, подписка готова</OrderTitle>
        <OrderSubTitle>
          Проверьте, всё ли указано верно.
          <br />
          {orderAdditionalSubTitle}
        </OrderSubTitle>
        {/* </OrderHeader> */}
      </Container>
      {orderPageTitle && (
        <Container>
          <OrderPageTitleWrapper>{orderPageTitle}</OrderPageTitleWrapper>
        </Container>
      )}
      <OrderContentWrapper>
        {(order as any[])
          ?.filter((item: OrderItemType | OrderItemsBlockType) => item.value)
          ?.map((orderItem: OrderItemsBlockType | OrderItemType) =>
            orderItem.type === OrderItemValueType.BLOCK ? (
              <OrderBlockItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemsBlockType)}
              />
            ) : (
              <OrderItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemType)}
              />
            )
          )}
        <EmailWrapper>
          <FormSubTitle>Пришлём полис на эту почту</FormSubTitle>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.email?.message}
              >
                <Input
                  name="email"
                  value={value || ''}
                  label="Email"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.email}
                />
              </HelperText>
            )}
          />
        </EmailWrapper>
      </OrderContentWrapper>
    </BaseLayout>
  );
};
