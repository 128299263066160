export const PetsPeymentPeriodLabels = new Map([
  [
    'P3M',
    {
      label: ' ₽ в квартал',
      description:
        'Самый гибкий вариант — можете менять условия раз в 3 месяца',
      promoLabel: ' ₽ в первый квартал',
      promoSubLabel: 'со второго квартала и далее ',
    },
  ],
  [
    'P1Y',
    {
      label: ' ₽ в год',
      description:
        'На 10% выгодней чем оплата раз в квартал. Изменить условия можно только через год',
      promoLabel: ' ₽ в первый год',
      promoSubLabel: 'со второго года и далее ',
    },
  ],
]);
