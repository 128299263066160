import styled from 'styled-components';

import { colors, mixins, typography } from '@pulse-web-ui/theme';

export const ContentWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: 32px;
`;

export const PromoWrapper = styled.div`
  ${mixins.flex({ gap: 8 })};
  width: 100%;
`;

export const PromoTextInput = styled.div`
  flex: auto;
`;

export const PromoSubmitButton = styled.div`
  ${typography.bold18};

  span {
    color: ${colors.darkBlue2} !important;
  }

  div div {
    border: 1px solid ${colors.neutral30};
    transition: 300ms ease all;

    &:hover {
      border-color: ${colors.secondaryLightGray};
    }
  }
`;
