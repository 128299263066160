import { useEffect } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  DocumentsIcon,
  RegulatorIcon,
  StarsIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';

import { PageSubTitle, PageTitle } from '@src/common-components/headers';
import {
  FormSub,
  FormSubLabel,
  GridColumnContainer,
  HeaderAdaptive4,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';

import { BaseLayout } from '../../layouts/base-layout';

// TODO: изменить на цвет из темы после ее обновления
const bgColor = '#F1F2F6';

export const BestToPayInProgressPage: FC = () => {
  const storedStartDate = localStorage.getItem('startDate');

  const pageTitle = 'Уже обрабатываем ваш платеж';
  const pageSubTitle =
    'Обычно это происходит моментально, но сейчас немного дольше обычного. Подождите немного, скоро ваша подписка появится на главной';
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }
  }, []);

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={24}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{pageTitle}</PageTitle>
          {storedStartDate ? (
            <PageSubTitle>
              Начнёт действовать{' '}
              {new Date(storedStartDate).toLocaleDateString('ru', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </PageSubTitle>
          ) : null}
          <FormSubLabel>{pageSubTitle}</FormSubLabel>
        </UniversalColumn>
        <FormSub>
          {isBrowser ? (
            <DisplayQRButton />
          ) : (
            <Button
              adaptiveWidth
              themeColor="primary"
              label="Скачать приложение"
              onClick={() =>
                window.open(
                  window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK,
                  '_blank'
                )
              }
            />
          )}
        </FormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderAdaptive4>В приложении вы сможете</HeaderAdaptive4>
      </UniversalColumn>
      <UniversalColumnsWrapper>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <StarsIcon />
          <div>Сообщить о насупление страхового случая</div>
        </UniversalColumn>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <RegulatorIcon />
          <div>Изменить, приостановить или отменить подписку в любое время</div>
        </UniversalColumn>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <DocumentsIcon />
          <div>Получить полезные материалы и документы по страховке</div>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
