/* eslint-disable indent */
import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';

import {
  ArrowLeftIcon,
  Container,
  OrderSubTitle,
  OrderTitle,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  nsRoute,
  petsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest, useWebanalyticParams } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { AuthActionTypes, OrderActionTypes, Store } from '@src/store';
import {
  OrderItemType,
  OrderItemValueType,
  OrderItemsBlockType,
} from '@src/types';

import {
  OrderContentWrapper,
  OrderPageTitleWrapper,
} from './components/components.styles';
import { OrderBlockItem } from './components/order-block-item';
import { OrderItem } from './components/order-item';

export const OrderDetail: FC = () => {
  const {
    state: {
      stateUser: { profile },
      stateOrder: {
        order,
        orderPageTitle,
        orderRequestData,
        cachedOrderRequestData,
        orderSubmitData,
      },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    switch (authorizeRefRoute) {
      case iflHouseRoute:
        sendAnalyticEvent(analyticEvents.iflStep7Confirm);
        sendAnalyticEvent(analyticEvents.iflHouseStep7Confirm);
        break;
      case iflFlatRoute:
        sendAnalyticEvent(analyticEvents.iflStep7Confirm);
        sendAnalyticEvent(analyticEvents.iflFlatStep7Confirm);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toConfirmationAccident);
        break;
      case petsRoute:
        sendAnalyticEvent(analyticEvents.petToStepConfirm, {
          auth: authTokens ? 'y' : 'n',
        });
        break;
      default:
        break;
    }
  }, []);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    '/v1/subscription/submit',
    orderRequestData,
    [orderSubmitData],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 1,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 1,
        });
        break;
    }

    navigate(-1);
  };

  const onSubmitPageHandle = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseAccident);
        break;
    }

    if (
      JSON.stringify(orderRequestData) !==
      JSON.stringify(cachedOrderRequestData)
    ) {
      refetch();
    } else {
      navigate('/order-checkout');
    }
  };

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: OrderActionTypes.SetCachedOrderRequestData,
        payload: orderRequestData,
      });
      dispatch({
        type: OrderActionTypes.SetOrderSubmitData,
        payload: res,
      });

      navigate('/order-checkout');
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (error && !res) {
      const e = (error as any).response.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Что-то пошло не так',
            subtitle: 'Попробуйте зарегистрироваться еще раз',
            refRoute: '/registration',
          },
        });
        navigate('/authorize-fail');
      }
    }
  }, [error]);

  useEffect(() => {
    if (!order) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/');
    }
  }, [order]);

  useEffect(() => {
    if (!authTokens) {
      navigate('/registration');
    }
  }, [authTokens]);

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  useWebanalyticParams();

  const Footer = () => (
    <>
      <Button
        themeColor="gray"
        variant="circle"
        onClick={handleClickBack}
        disabled={isLoading}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        label="Подтвердить"
        themeColor="primary"
        onClick={onSubmitPageHandle}
        disabled={isLoading}
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        <OrderTitle>{profile?.profile.firstName}, подписка готова</OrderTitle>
        <OrderSubTitle>
          Проверьте, всё ли указано верно. Вы всегда можете отменить подписку
        </OrderSubTitle>
      </Container>
      {orderPageTitle && (
        <Container>
          <OrderPageTitleWrapper>{orderPageTitle}</OrderPageTitleWrapper>
        </Container>
      )}
      <OrderContentWrapper>
        {(order as any[])
          ?.filter((item: OrderItemType | OrderItemsBlockType) => item.value)
          ?.map((orderItem: OrderItemsBlockType | OrderItemType) =>
            orderItem.type === OrderItemValueType.BLOCK ? (
              <OrderBlockItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemsBlockType)}
              />
            ) : (
              <OrderItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemType)}
              />
            )
          )}
      </OrderContentWrapper>
    </BaseLayout>
  );
};
