import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import { colors } from '@pulse-web-ui/theme';

const theme = {
  colors: colors,
};

export const ThemeContainer: FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
