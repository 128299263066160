import { useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers/header';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { DONT_RECOGNIZE_YOU_TITLE, analyticEvents } from '@src/constants';
import { NewSupportBlock } from '@src/features';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

export const NewAuthorizeFailPage: FC = () => {
  const navigate = useNavigate();

  const {
    state: {
      stateAuth: { authorizeFailState },
    },
    dispatch,
  } = useContext(Store);
  const pageTitle = authorizeFailState?.title || 'Ошибка авторизации';
  const pageSubTitle =
    authorizeFailState?.subtitle || 'Попробуйте авторизоваться еще раз';

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: true,
    });

    if (pageTitle === DONT_RECOGNIZE_YOU_TITLE) {
      sendAnalyticEvent(analyticEvents.enterSurnameFail);
    }
  }, []);

  return (
    <BaseLayout>
      <PageContent>
        <PageTitle>{pageTitle}</PageTitle>
        <PageSubTitle marginBottom={32}>{pageSubTitle}</PageSubTitle>
        <StyledButton
          themeColor="primary"
          label="Попробовать ещё раз"
          onClick={() => {
            navigate(authorizeFailState?.refRoute || '/personal-info');
          }}
        />
        <NewSupportBlock />
      </PageContent>
    </BaseLayout>
  );
};
