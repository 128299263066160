/* eslint-disable indent */
import styled, { css } from 'styled-components';

import { colors, rgba, typography } from '@pulse-web-ui/theme';

export const StyledDaDataContainer = styled.div<{ error?: boolean }>`
  .react-dadata__input {
    background-color: ${({ error }) =>
      error ? colors.danger20 : colors.neutral10};
    border: ${({ error }) =>
      error ? `1.5px solid ${colors.danger100}` : 'none'};
    border-radius: 4px;
    padding: 16px;
    transition: border-color 0.2s ease-in-out;

    &::placeholder {
      -webkit-text-fill-color: ${({ error }) =>
        error ? colors.danger100 : colors.secondaryGray2} !important;
    }

    ${({ error }) =>
      !error &&
      css`
        &:hover {
          background-color: ${colors.neutral20};
        }

        &:focus,
        &:focus-within {
          background-color: ${colors.white};
          border: 1.5px solid ${colors.secondaryLightGray};
        }
      `}
  }

  .react-dadata__container {
    position: relative;
  }

  .react-dadata__suggestions {
    position: absolute;
    list-style: none;
    margin: 0;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${rgba(colors.primary, 0.5)};
    box-shadow: 0 4px 24px ${rgba(colors.gray, 0.4)};
    border-radius: 4px;
    top: calc(100% + 8px);
    padding: 8px 0;
    overflow: hidden;
    left: 0;
    right: 0;
    text-align: left;
  }

  .react-dadata__suggestion-note {
    ${typography.caption1.regular}
    color: ${colors.darkBlue2};
    padding: 10px 10px 5px 10px;
  }

  .react-dadata__suggestion {
    ${typography.regular18}
    padding: 10px 24px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    display: block;
    background: none;
    border: none;
    text-align: left;
  }

  .react-dadata__suggestion--line-through {
    text-decoration: line-through;
  }

  .react-dadata__suggestion-subtitle {
    ${typography.caption1.regular}
    margin-top: 4px;
    color: ${colors.darkBlue2};
  }

  .react-dadata__suggestion-subtitle-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 4px;
  }

  .react-dadata__suggestion-subtitle-item:last-child {
    margin-right: 0;
  }

  .react-dadata__suggestion--current {
    background-color: ${colors.gray};
  }

  .react-dadata__suggestion:hover {
    background-color: ${colors.gray};
  }

  .react-dadata mark {
    background: none;
  }

  .react-dadata--highlighted {
    color: ${colors.main};
  }
`;
