import { ClientCategory } from '@src/constants';
import { SelectedPetData } from '@src/store/pets';

export type Risk = {
  active: boolean;
  binding: boolean;
  code: string;
  description: string;
  extendedDescription: string;
  imageUrl: string;
  insuranceType?: string;
  name: string;
};

export type Region = {
  region: string;
  regionName: string;
  regionTariff: null | undefined;
  version: string;
};

export type InsuranceProduct = {
  code: string;
  name: string;
  description?: string;
  minLimit: number;
  maxLimit: number;
  regions: Region[];
  step: number;
  effectivePeriod: number;
  defaultValue: null;
  maxObjectsNumber: number;
};

export type SelectedDuration = 'P1M' | 'P3M' | 'P1Y';

export type SelectedDate = Date;

export type Price = {
  discount: string | number;
  duration: SelectedDuration;
  premiumAndDelta: string;
  premiumAndDeltaPromo: null | string;
};

export type GetPrices =
  | {
      isSuccessfulPromo: boolean;
      prices: Price[];
    }
  | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type Action<T> = ActionMap<T>[keyof ActionMap<T>];
export type Status = {
  status: 'error' | 'default';
  message?: string;
};

export type Method = 'post' | 'get' | 'patch' | 'put';
export type Url = string;

export enum OrderItemValueType {
  SIMPLE = 'simple',
  BLOCK = 'block',
}
export type OrderItemType = {
  type: OrderItemValueType;
  title: string;
  value: string | undefined;
  secondsValue?: string;
  isColsIdentical?: boolean;
  successValue?: string;
  failedValue?: string;
};
export type OrderItemsBlockType = {
  type: OrderItemValueType;
  title: string;
  value: Array<OrderItemType> | undefined;
  onClick?: () => void;
};

export type DocumentType = {
  clientDocument: string;
  clientDocumentType: 'Memo' | 'Rules' | 'Contract';
  isNew: null;
};

export type Person = {
  age: number | undefined;
  ageMin: number | undefined;
  birthDate: Date;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  primaryRecordId?: number | undefined;
};

export type InsurePerson = {
  ageMin: number;
  birthDate: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  useMiddleName: boolean;
  isMe: boolean;
  isDisabledForm: boolean;
  primaryRecordId?: number | undefined;
};

export type InsurancePersonsForm = {
  persons: InsurePerson[];
};

export type NumberInsurePersons = {
  numberChildren: number;
  numberAdults: number;
  numberElderly: number;
};

export type Profile = {
  birthDate: string;
  email: string;
  firstName: string;
  intermediary: {
    campaign: string;
    mediaSource: string;
  };
  lastName: string;
  middleName: string;
  phone: string;
  primaryRecordId: number;
};

export type UserProfile = {
  hasSubscriptions: boolean;
  profile: Profile;
};

export type FormInsuranceProfile = {
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  birthDate: string | undefined;
  primaryRecordId?: number | undefined;
};

export type FormInsuranceContactsProps = {
  firstName: string;
  lastName: string;
  middleName?: string;
  useMiddleName: boolean;
  email: string;
  birthDate: string;
};

export type FormPromoProps = {
  promoCode?: string;
};

export type ResponseError = { code: string };

export type InsuranceSubProduct = {
  code: string;
  name: string;
  description: string;
  imageUrl: string;
  defaultInsuranceSum: string;
  maxLimit: number;
  minLimit: number;
  step: number;
};

export type AddressDataType = {
  address?: string;
  addressCode?: string;
  regionCode?: string;
  streetCode?: string;
  houseCode?: string;
  flatCode?: string;
  placeCode?: string;
  cityCode?: string;
};

export type InsuranceConvertedSubProduct = {
  code: string;
  insuranceSum: string;
};

export type OrderRequestDataPerson = Omit<Person, 'birthDate'> & {
  birthDate: string | undefined;
};

export type OrderRequestData = {
  productCode: string | undefined;
  contractDuration: SelectedDuration | null;
  risks: string[] | undefined;
  effectiveSince: string;
  insuranceSum?: string | undefined;
  accidents?: {
    persons: OrderRequestDataPerson[];
  };
  personProperties?: {
    subObjects: InsuranceConvertedSubProduct[] | undefined;
    address: AddressDataType;
  };
  pets?: SelectedPetData;
  promoCode?: string;
  buildingMaterial?: string;
};

export type UserLockedFieldsTypes = {
  birthDate: boolean;
  email: boolean;
  firstName: boolean;
  lastName: boolean;
  middleName: boolean;
  phone: boolean;
};

export type UserProfileTypes = {
  birthDate: null | string;
  clientCategory: ClientCategory | null;
  email: null | string;
  firstName: null | string | undefined;
  intermediary: {
    campaign: 'SMS';
    mediaSource: '0000';
  };
  lastName: null | string | undefined;
  middleName: null | string | undefined;
  phone: null | string;
  primaryRecordId: number;
  utmCampaign?: null | string;
  utmContent?: null | string;
  utmMedium?: null | string;
  utmSource?: null | string;
  wmId?: null | string;
};

export type UserPolice = {
  approvedAt: string;
  code: string;
};
export interface UserRegistrationDataTypes
  extends Omit<
    UserProfileTypes,
    'birthDate' | 'email' | 'intermediary' | 'phone' | 'primaryRecordId'
  > {
  birthDay?: null | string;
  extendedIntermediaryChannelCode?: string;
  userPolicies?: UserPolice[];
  userPoliciesCode003?: boolean;
  userPoliciesCode002?: boolean;
  useMiddleName?: boolean;
}

export type InsuranceProductType = {
  code: string;
  defaultInsuranceSum: null;
  maxObjectsNumber: null;
  maxProductLimit: string;
  minObjectLimit: string;
  minProductLimit: string;
  name: string;
  regions: Region[];
  step: null | string | number;
};

export type InsuranceSubProductType = {
  code: string;
  defaultInsuranceSum: null | string;
  description: string;
  imageUrl: string;
  maxLimit: string;
  minLimit: string;
  name: string;
  step: null | string | number;
};

export type FullDescriptionObject = {
  title: string;
  points?: Array<string>;
};

export type MaterialObject = {
  code: string;
  nameForUser: string;
  shortDescription: string;
  linkToFullDescription: string;
  fullDescriptionTitle: string;
  fullDescription: Array<FullDescriptionObject>;
  activeOption: boolean;
  imageUrl?: string;
  waitingMessage?: string;
};

export type Materials = Array<MaterialObject>;
