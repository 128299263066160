import type { FC } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { Container, FormSub, Header3 } from '@src/components';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store } from '@src/store';

export const IFLFlatRiskDescriptions: FC = () => {
  const {
    state: {
      stateFormIFLFlat: { risks, risksPage },
    },
  } = useContext(Store);
  const navigate = useNavigate();

  const Footer = () => (
    <>
      <Button
        themeColor="primary"
        onClick={() => {
          navigate(-1);
        }}
        label="Понятно"
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        {risks
          ?.filter(
            (item) =>
              (risksPage === 'primary' && item.binding === true) ||
              (risksPage === 'secondary' && item.binding === false)
          )
          .map((item) => (
            <>
              <Header3>{item.name}</Header3>
              <FormSub>{item.extendedDescription}</FormSub>
            </>
          ))}
      </Container>
    </BaseLayout>
  );
};
