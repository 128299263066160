/* eslint-disable indent */
import { ageCategorySubTitle } from '@src/utils/age-ratios-format';

export const getInsurancePersonSubTitle = (ageMin: number) => {
  switch (ageMin) {
    case 1:
      return ageCategorySubTitle.children;
    case 18:
      return ageCategorySubTitle.adults;
    case 70:
      return ageCategorySubTitle.elderly;
    default:
      break;
  }
};
