import { useCallback, useContext } from 'react';

import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { FormInsuranceProfile, InsurePerson } from '@src/types';

export const useNsDetailsInsured = () => {
  const {
    state: {
      stateWizard: { currentStep },
      stateUser: { profile: stateProfile },
    },
    dispatch,
  } = useContext(Store);

  const profileDataForForm: FormInsuranceProfile = {
    birthDate: stateProfile?.profile?.birthDate || '',
    firstName: stateProfile?.profile?.firstName || '',
    lastName: stateProfile?.profile?.lastName || '',
    middleName: stateProfile?.profile?.middleName || '',
    primaryRecordId: stateProfile?.profile?.primaryRecordId || undefined,
  };

  const storeFwNavDisabled = useCallback((data: boolean) => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: data,
    });
  }, []);

  const storeCurrentStep = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: currentStep + 1,
    });
  }, []);

  const storeWantNextStep = useCallback((data: boolean) => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: data,
    });
  }, []);

  const storeInsurePersons = useCallback((data: InsurePerson[]) => {
    dispatch({
      type: NSActionTypes.SetInsurePersons,
      payload: data,
    });
  }, []);

  return {
    storeFwNavDisabled,
    storeCurrentStep,
    storeWantNextStep,
    storeInsurePersons,
    profileDataForForm,
  };
};
