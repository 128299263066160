import { colors } from '@pulse-web-ui/theme';

export const ArrowLeftIcon = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1L1.70711 6.29289C1.31658 6.68342 1.31658 7.31658 1.70711 7.70711L7 13"
      stroke={colors.primary}
      strokeWidth="1.5"
    />
  </svg>
);
