import {
  FlatIcon,
  HouseIcon,
  NsIcon,
  PetsIcon,
} from '@pulse-web-ui/colored-icons';

import { InsurePerson } from '@src/types';

export {
  phoneRegEx,
  nameRegEx,
  notDigital,
  petNameRegEx,
  promoEx,
} from './regex';

export const formPeriodLabels = new Map([
  [
    'P1M',
    {
      label: ' ₽ в месяц',
      description: 'Самый гибкий вариант — можете менять условия каждый месяц',
      promoLabel: ' ₽ в первый месяц',
      promoSubLabel: 'со второго месяца и далее ',
    },
  ],
  [
    'P3M',
    {
      label: ' ₽ в квартал',
      description:
        'На 10% выгодней, чем помесячная оплата. Изменить условия можно через 3 месяца',
      promoLabel: ' ₽ в первый квартал',
      promoSubLabel: 'со второго квартала и далее ',
    },
  ],
  [
    'P1Y',
    {
      label: ' ₽ в год',
      description:
        'На 15% выгодней, чем помесячная оплата. Изменить условия можно только через год',
      promoLabel: ' ₽ в первый год',
      promoSubLabel: 'со второго года и далее ',
    },
  ],
]);

export const USER_AGREEMENTS_URL = window.envUrls.USER_AGREEMENTS_URL;

export const DADATA_API_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs';

export const USER_RECL_AGREEMENTS_URL = window.envUrls.USER_RECL_AGREEMENTS_URL;

export const USER_PERSONAL_DATA_AGREEMENTS =
  window.envUrls.USER_PERSONAL_DATA_AGREEMENTS;

export const INSURANCE_POLICIES = window.envUrls.INSURANCE_POLICIES_URL;

export const analyticEvents = {
  firstLaunch: 'Auth_First_Launch',
  userAgreement: 'Auth_User_Agreement',
  signupStart: 'Auth_Signup_start',
  requestNewOtp: 'Auth_Request_new_otp',
  requestTechSupport: 'Auth_Request_tech_support',
  enterOtpFail: 'Auth_Enter_otp_fail',
  enterOtpSuccess: 'Auth_Enter_otp_success',
  enterSurname: 'Auth_Enter_surname',
  signupCompleted: 'Auth_Signup_completed',
  startRealty: 'Purchase_Start_Realty',
  calculateRealty: 'Purchase_Calculate_Realty',
  regionSelectedRealty: 'Purchase_Region_Selected_Realty',
  typeSelectedRealty: 'Purchase_Type_Selected_Realty',
  toStep2Realty: 'Purchase_To_Step_2_Realty',
  viewOptionRealty: 'Purchase_View_Option_Realty',
  deselectOptionRealty: 'Purchase_Deselect_Option_Realty',
  selectOptionRealty: 'Purchase_Select_Option_Realty',
  toStep3Realty: 'Purchase_To_Step_3_Realty',
  coverageExplanationRealty: 'Purchase_Coverage_Explanation_Realty',
  coverageSumChangeRealty: 'Purchase_Coverage_Sum_Change_Realty',
  toStep4Realty: 'Purchase_To_Step_4_Realty',
  periodSelectRealty: 'Purchase_Period_Select_Realty',
  dateStartRealty: 'Purchase_Date_Start_Realty',
  toStep5Realty: 'Purchase_To_Step_5_Realty',
  addressSelectedRealty: 'Purchase_Address_selected_Realty',
  toStep6Realty: 'Purchase_To_Step_6_Realty',
  toConfirmationRealty: 'Purchase_To_Confirmation_Realty',
  toPurchaseRealty: 'Purchase_To_Purchase_Realty',
  viewAgreementRealty: 'Purchase_View_Agreement_Realty',
  viewDocumentRealty: 'Purchase_View_Document_Realty',
  toPaymentMethodRealty: 'Purchase_To_Payment_Method_Realty',
  toPaymentRealty: 'Purchase_To_Payment_Realty',
  paymentSuccessRealty: 'Purchase_Payment_Success_Realty',
  toPreviousRealty: 'Purchase_To_Previous_Realty',
  startAccident: 'Purchase_Start_Accident',
  calculateAccident: 'Purchase_Calculate_Accident',
  toStep2Accident: 'Purchase_To_Step_2_Accident',
  deselectOptionAccident: 'Purchase_Deselect_Option_Accident',
  selectOptionAccident: 'Purchase_Select_Option_Accident',
  toStep3Accident: 'Purchase_To_Step_3_Accident',
  deselectProtectionAccident: 'Purchase_Deselect_Protection_Accident',
  selectProtectionAccident: 'Purchase_Select_Protection_Accident',
  toStep4Accident: 'Purchase_To_Step_4_Accident',
  coverageSumChangeAccident: 'Purchase_Coverage_Sum_Change_Accident',
  toStep5Accident: 'Purchase_To_Step_5_Accident',
  periodSelectAccident: 'Purchase_Period_Select_Accident',
  dateStartAccident: 'Purchase_Date_Start_Accident',
  toStep6Accident: 'Purchase_To_Step_6_Accident',
  toStep7Accident: 'Purchase_To_Step_7_Accident',
  toConfirmationAccident: 'Purchase_To_Confirmation_Accident',
  toPurchaseAccident: 'Purchase_To_Purchase_Accident',
  viewAgreementAccident: 'Purchase_View_Agreement_Accident',
  viewDocumentAccident: 'Purchase_View_Document_Accident',
  toPaymentMethodAccident: 'Purchase_To_Payment_Method_Accident',
  toPaymentAccident: 'Purchase_To_Payment_Accident',
  paymentSuccessAccident: 'Purchase_Payment_Success_Accident',
  toPreviousAccident: 'Purchase_To_Previous_Accident',
  iflStep1Address: 'Purchase_IFL_To_Step_1_Address',
  iflStep2Risk: 'Purchase_IFL_To_Step_2_Risk',
  iflStep3AddRisk: 'Purchase_IFL_To_Step_3_AddRisk',
  iflStep4Сoverage: 'Purchase_IFL_To_Step_4_Сoverage',
  iflStep5Period: 'Purchase_IFL_To_Step_5_Period',
  iflStep6Yourdata: 'Purchase_IFL_To_Step_6_Yourdata',
  iflStep7Confirm: 'Purchase_IFL_To_Step_7_Confirm',
  iflStep8Docs: 'Purchase_IFL_To_Step_8_Docs',
  iflStep9Pay: 'Purchase_IFL_To_Step_9_Pay',
  iflPaymentSuccess: 'Purchase_IFL_Payment_Success',
  iflPaymentDecline: 'Purchase_IFL_Payment_Decline',
  iflFlatStep1Address: 'Purchase_Flat_To_Step_1_Address',
  iflFlatStep2Risk: 'Purchase_Flat_To_Step_2_Risk',
  iflFlatStep3Risk: 'Purchase_Flat_To_Step_3_AddRisk',
  iflFlatStep4Сoverage: 'Purchase_Flat_To_Step_4_Сoverage',
  iflFlatStep5Period: 'Purchase_Flat_To_Step_5_Period',
  iflFlatStep6Yourdata: 'Purchase_Flat_To_Step_6_Yourdata',
  iflFlatStep7Confirm: 'Purchase_Flat_To_Step_7_Confirm',
  iflFlatStep8Docs: 'Purchase_Flat_To_Step_8_Docs',
  iflFlatStep9Pay: 'Purchase_Flat_To_Step_9_Pay',
  iflFlatPaymentSuccess: 'Purchase_Flat_Payment_Success',
  iflFlatPaymentDecline: 'Purchase_Flat_Payment_Decline',
  iflHouseStep1Address: 'Purchase_House_To_Step_1_Address',
  iflHouseMaterials: 'Purchase_House_To_Step_Buildingtype',
  iflHouseStep2Risk: 'Purchase_House_To_Step_2_Risk',
  iflHouseStep3Risk: 'Purchase_House_To_Step_3_AddRisk',
  iflHouseStep4Сoverage: 'Purchase_House_To_Step_4_Сoverage',
  iflHouseStep5Period: 'Purchase_House_To_Step_5_Period',
  iflHouseStep6Yourdata: 'Purchase_House_To_Step_6_Yourdata',
  iflHouseStep7Confirm: 'Purchase_House_To_Step_7_Confirm',
  iflHouseStep8Docs: 'Purchase_House_To_Step_8_Docs',
  iflHouseStep9Pay: 'Purchase_House_To_Step_9_Pay',
  iflHousePaymentSuccess: 'Purchase_House_Payment_Success',
  iflHousePaymentDecline: 'Purchase_House_Payment_Decline',
  authVIPapp: 'Auth_VIP_app',
  petToStepPetType: 'Purchase_Pet_To_Step_PetType',
  petToStepRisk: 'Purchase_Pet_To_Step_Risk',
  petToStepAddRisk: 'Purchase_Pet_To_Step_AddRisk',
  petToStepCoverage: 'Purchase_Pet_To_Step_Coverage',
  petToStepPeriod: 'Purchase_Pet_To_Step_Period',
  petToStepYourData: 'Purchase_Pet_To_Step_YourData',
  petToStepConfirm: 'Purchase_Pet_To_Step_Confirm',
  petToStepDocs: 'Purchase_Pet_To_Step_Docs',
  petToStepPay: 'Purchase_Pet_To_Step_Pay',
  petPaymentSuccess: 'Purchase_Pet_Payment_Success',
  petPaymentDecline: 'Purchase_Pet_Payment_Decline',
  webStart: 'Purchase_Web_Start',
  webHasSubsSelected: 'Purchase_Web_Has_Subs_Selected',
  webProducts: 'Purchase_Web_Products',
  webProductsSelected: 'Purchase_Web_Products_Selected',
  signupPhone: 'Auth_Signup_phone',
  enterSurnameFail: 'Auth_Enter_surname_fail',
};

export const durationDescription = {
  P1M: 'month',
  P3M: 'quarter',
  P1Y: 'year',
};

export const insurancePersonDefaultData: InsurePerson = {
  isDisabledForm: false,
  useMiddleName: false,
  isMe: false,
  ageMin: 1,
  birthDate: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
};

export const iflRoute = '/'; // TODO: корень должен измениться на ИФЛ
export const iflHouseRoute = '/house';
export const iflFlatRoute = '/apartment';
export const nsRoute = '/ns';
export const petsRoute = '/pets';

export const insuranceProductsCode = {
  house: '08.16',
  flat: '08.15',
  accident: '02.09',
  pets: '00.04',
};

export { ClientCategory } from './client-category';
export {
  PAYMENT_ROUTE,
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
} from './routes';

export enum YesNoOption {
  YES = 'Да',
  NO = 'Нет',
}

export const radioCardGroupYesNoItems = [
  {
    label: YesNoOption.NO,
    id: 'id1',
    value: YesNoOption.NO,
  },
  {
    label: YesNoOption.YES,
    id: 'id2',
    value: YesNoOption.YES,
  },
];

export enum TextAlignDirection {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum HorizontalAlignDirection {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
}

export enum Product {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  NS = 'ns',
  PETS = 'pets',
}

export const productItems = [
  {
    label: 'Защита квартиры',
    id: 'id1',
    value: Product.APARTMENT,
    icon: <FlatIcon />,
  },
  {
    label: 'Защита дома',
    id: 'id2',
    value: Product.HOUSE,
    icon: <HouseIcon />,
  },
  {
    label: 'Защита от травм',
    id: 'id3',
    value: Product.NS,
    icon: <NsIcon />,
  },
  {
    label: 'Защита питомца',
    id: 'id4',
    value: Product.PETS,
    icon: <PetsIcon />,
  },
];

export const DEFAULT_FIAS_ID = '00000000-0000-0000-0000-000000000000';

export enum UseQueryStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  IDLE = 'idle',
}

export const DONT_RECOGNIZE_YOU_TITLE = 'Не узнаем вас';
