export const numFormat = (number: number) =>
  new Intl.NumberFormat('ru-RU').format(number);

export const currencyRuLocale = (number: number) =>
  new Intl.NumberFormat('ru-RU').format(number);

export const currencyRuLocaleWithTwoFractionDigits = (number: number) =>
  new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(number);

export const currencyFormat = (number: number) => {
  if (number > 1_000_000) {
    const truncNumber = Math.floor(number / 1_000_000);
    return `${numFormat(truncNumber)} млн`;
  }
  return numFormat(number);
};
