import { useCallback, useContext, useEffect, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  HorizontalAlignDirection,
  RadioCardGroup,
} from '@pulse-web-ui/radio-card-group';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import {
  Product,
  TextAlignDirection,
  YesNoOption,
  radioCardGroupYesNoItems,
} from '@src/constants';
import { useIsDesktop } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { getProductForAnalytic } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import {
  AuthPagFullWidthWrapper,
  AuthPageTitle,
  AuthPageWrapper,
} from './authorization-pages.styles';

export const IsSubscriptionExistsPage: FC = () => {
  const {
    state: {
      stateUser: {
        selectedProduct,
        isSubscriptionExists,
        isPureSubscription,
        cachedProduct,
      },
    },
    dispatch,
  } = useContext(Store);
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const product = searchParams.get('product');
  const hasSubs = searchParams.get('has_subs')?.toLowerCase();

  const [selectedRadioValue, setSelectedRadioValue] = useState<
    YesNoOption | undefined
  >(
    isSubscriptionExists !== undefined
      ? isSubscriptionExists
        ? YesNoOption.YES
        : YesNoOption.NO
      : undefined
  );

  useEffect(() => {
    localStorage.setItem(
      'selectedProduct',
      selectedProduct ?? (product as string)
    );
  }, [product, selectedProduct]);

  useEffect(() => {
    if (hasSubs === 'skip') {
      dispatch({
        type: UserActionTypes.SetIsPureSubscription,
        payload: true,
      });

      if (!product) {
        dispatch({
          type: UserActionTypes.SetSelectedProduct,
          payload: Product.APARTMENT, //TODO: если будет другой партнер, кроме ПИК, разделить логику сохранения продукта
        });

        dispatch({
          type: AuthActionTypes.SetAuthorizeRefRoute,
          payload: `/${Product.APARTMENT}`, //TODO: если будет другой партнер, кроме ПИК, разделить логику сохранения роута продукта
        });
      }
    }
  }, [hasSubs]);

  useEffect(() => {
    if (product) {
      dispatch({
        type: UserActionTypes.SetSelectedProduct,
        payload: product as Product,
      });

      dispatch({
        type: AuthActionTypes.SetAuthorizeRefRoute,
        payload: `/${product}`,
      });
    }
  }, [product]);

  useEffect(() => {
    if (selectedRadioValue !== undefined) {
      dispatch({
        type: UserActionTypes.SetIsSubscriptionExists,
        payload: selectedRadioValue === YesNoOption.YES,
      });
    }
  }, [selectedRadioValue]);

  useEffect(() => {
    if (isPureSubscription) {
      navigate('/personal-info');
    }
  }, [isPureSubscription]);

  useEffect(() => {
    if (product) {
      sendAnalyticEvent(analyticEvents.webStart, {
        product_selected: 'y',
        product: getProductForAnalytic(product),
      });
    } else {
      sendAnalyticEvent(analyticEvents.webStart, {
        product_selected: 'n',
      });
    }
  }, []);

  const handleForwardClick = useCallback(() => {
    if (!selectedProduct) {
      sendAnalyticEvent(analyticEvents.webHasSubsSelected, {
        subs: isSubscriptionExists ? 'y' : 'n',
      });
    }

    if (selectedProduct && !cachedProduct) {
      if (isSubscriptionExists) {
        navigate('/new-login');
      } else {
        navigate('/personal-info');
      }
    } else {
      navigate('/subscription-choice');
    }
  }, [selectedProduct, isSubscriptionExists]);

  const MobileTitle = () => (
    <div>
      У вас есть подписка
      <br />
      Пульс?
    </div>
  );

  const Footer = () => (
    <Button
      disabled={isSubscriptionExists === undefined}
      themeColor="primary"
      label="Продолжить"
      onClick={handleForwardClick}
      adaptiveWidth
    />
  );

  return (
    <BaseLayout>
      {hasSubs !== 'skip' && (
        <AuthPageWrapper>
          <AuthPageTitle mobileTextAlign={TextAlignDirection.CENTER}>
            {isDesktop ? 'У вас есть подписка Пульс?' : <MobileTitle />}
          </AuthPageTitle>
          <AuthPagFullWidthWrapper marginBottom>
            <RadioCardGroup
              name="isSubscriptionCardGroup"
              value={selectedRadioValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSelectedRadioValue(event.target.value as YesNoOption)
              }
              items={radioCardGroupYesNoItems}
              desktopWidth={158}
              textAlign={TextAlignDirection.LEFT}
              horizontalAlign={HorizontalAlignDirection.CENTER}
            />
          </AuthPagFullWidthWrapper>
          <Footer />
        </AuthPageWrapper>
      )}
    </BaseLayout>
  );
};
