export enum IFLHouseActionTypes {
  SetSelectedIProduct = 'IFL_HOUSE/SET_SELECTED_I_PRODUCT',
  SetSelectedRegion = 'IFL_HOUSE/SET_SELECTED_REGION',
  SetRegions = 'IFL_HOUSE/SET_REGIONS',
  SetRisks = 'IFL_HOUSE/SET_RISKS',
  SetInsuranceSubproducts = 'IFL_HOUSE/SET_INSURANCE_SUBPRODUCTS',
  SetInsuranceSum = 'IFL_HOUSE/SET_INSURANCE_SUM',
  SetGetPrices = 'IFL_HOUSE/SET_GET_PRICES',
  SetSelectedDuration = 'IFL_HOUSE/SET_SELECTED_DURATION',
  SetSelectedDate = 'IFL_HOUSE/SET_SELECTED_DATE',
  SetDadaValue = 'IFL_HOUSE/SET_DADA_VALUE',
  SetPromoCode = 'IFL_HOUSE/SET_PROMO_CODE',
  SetRisksPage = 'IFL_HOUSE/SET_RISKS_PAGE',
  SetMaterials = 'IFL_HOUSE/SET_MATERIALS',
  SetSelectedBuildingMaterialCode = 'IFL_HOUSE/SET_SELECTED_BUILDING_MATERIAL_CODE',
  SetFieldWithEmptyDependencies = 'IFL_HOUSE/SET_FIELD_WITH_EMPTY_DEPENDENCIES',
  SetEmptyDependencies = 'IFL_HOUSE/SET_EMPTY_DEPENDENCIES',
  SetIsObjectDataError = 'IFL_HOUSE/SET_IS_OBJECT_DATA_ERROR',
  SetGetSubobjectsRefetchUid = 'IFL_HOUSE/SET_GET_SUB_OBJECTS_REFETCH_UID',
}
