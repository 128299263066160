import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { AdaptiveListItem } from '@pulse-web-ui/adaptive-list-item';

import { AdaptiveListWrapper, Container } from '@src/components';
import {
  FormHead,
  FormSubhead,
} from '@src/components/form-elements/form-elements';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { useNextStep } from '@src/hooks';
import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { Risk } from '@src/types';

export const FormInsuranceAdditionalRisks: FC = memo(() => {
  const {
    state: {
      stateFormNS: { risks, selectedAdditionalRisks },
    },
    dispatch,
  } = useContext(Store);

  const additionalRisks = useMemo(
    () => risks?.filter((elem: Risk) => !elem.binding),
    [risks]
  );

  const storeSelectedAdditionalRisks = useCallback((data: string[]) => {
    dispatch({
      type: NSActionTypes.SetSelectedAdditionalRisks,
      payload: data,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleRiskClick = (risk: Risk) => {
    const { code, name } = risk;

    setStepUpdated();

    const newSelectedRisks = selectedAdditionalRisks
      ? [...selectedAdditionalRisks]
      : [];

    if (!newSelectedRisks.includes(code)) {
      newSelectedRisks.push(code);
      sendAnalyticEvent(analyticEvents.selectProtectionAccident, {
        option: name,
      });
    } else {
      newSelectedRisks.splice(newSelectedRisks.indexOf(code), 1);
      sendAnalyticEvent(analyticEvents.deselectProtectionAccident, {
        option: name,
      });
    }

    storeSelectedAdditionalRisks(newSelectedRisks);
  };

  useNextStep(() => true);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (risks && !selectedAdditionalRisks) {
      storeSelectedAdditionalRisks(
        risks
          .filter((elem: Risk) => !elem.binding && elem.active)
          .map((elem: Risk) => elem.code)
      );
    }
  }, [risks]);

  return (
    <Container>
      <FormHead>Дополнительная защита</FormHead>
      <FormSubhead>Выберите опции, которые нужны вам</FormSubhead>
      <AdaptiveListWrapper>
        {(!additionalRisks || additionalRisks?.length === 0) && 'Нет данных'}
        {additionalRisks?.map((item) => (
          <AdaptiveListItem
            index={item.code}
            key={item.code}
            active={!!selectedAdditionalRisks?.includes(item.code)}
            label={item.name}
            subLabel={item.description}
            onClick={() => handleRiskClick(item)}
            imgSrc={item.imageUrl}
          />
        ))}
      </AdaptiveListWrapper>
    </Container>
  );
});
