import { FC } from 'react';

import type { OrderItemType } from '@src/types';

import {
  OrderItemStatusValue,
  OrderItemSubValue,
  OrderItemTitle,
  OrderItemValue,
  OrderItemValueWrapper,
  OrderItemWrapper,
} from './components.styles';

export const OrderItem: FC<OrderItemType> = (orderItem) => (
  <OrderItemWrapper>
    <OrderItemTitle isColsIdentical={orderItem.isColsIdentical}>
      {orderItem.title}
    </OrderItemTitle>
    <OrderItemValueWrapper>
      <OrderItemValue>{orderItem.value}</OrderItemValue>
      {orderItem.secondsValue ? (
        <OrderItemSubValue>{orderItem.secondsValue}</OrderItemSubValue>
      ) : null}
      {orderItem.successValue ? (
        <OrderItemStatusValue error={false}>
          {orderItem.successValue}
        </OrderItemStatusValue>
      ) : orderItem.failedValue ? (
        <OrderItemStatusValue error>
          {orderItem.failedValue}
        </OrderItemStatusValue>
      ) : null}
    </OrderItemValueWrapper>
  </OrderItemWrapper>
);
