import {
  GetPrices,
  InsuranceProductType,
  InsurePerson,
  NumberInsurePersons,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type AgeRatio = {
  ageMin: number;
  ageMax: number;
  ageCategory: string;
};

export type SubscriptionDateLimit = {
  endDate: Date;
  startDate: Date;
};

export interface NSStoreState {
  insuranceProduct: InsuranceProductType | undefined;
  ageRatios: AgeRatio[];
  numberInsurePersons: NumberInsurePersons;
  risks: undefined | Risk[];
  selectedMainRisks: undefined | string[];
  selectedAdditionalRisks: undefined | string[];
  insuranceSum: number | undefined;
  selectedDate: null | SelectedDate;
  selectedDuration: null | SelectedDuration;
  getPrices: GetPrices;
  subscriptionDateLimit: null | SubscriptionDateLimit;
  insurePersons: InsurePerson[];
  promoCode?: string;
}

export const initNSStoreState: NSStoreState = {
  insuranceProduct: undefined,
  ageRatios: [],
  numberInsurePersons: {
    numberChildren: 0,
    numberAdults: 0,
    numberElderly: 0,
  },
  risks: undefined,
  selectedMainRisks: undefined,
  selectedAdditionalRisks: undefined,
  insuranceSum: undefined,
  selectedDate: null,
  selectedDuration: null,
  getPrices: undefined,
  subscriptionDateLimit: null,
  insurePersons: [],
  promoCode: undefined,
};
