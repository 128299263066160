export type CurrentStep = number;

export type WantNextStep = boolean;

export interface WizardStoreState {
  currentStep: CurrentStep;
  wantNextStep: WantNextStep;
  updateFormState: boolean;
  fwNavDisabled: boolean;
  backNavDisabled: boolean;
  isAuth: boolean;
}

export const initWizardStoreState: WizardStoreState = {
  currentStep: 1,
  wantNextStep: false,
  updateFormState: false,
  fwNavDisabled: false,
  backNavDisabled: false,
  isAuth: false,
};
