import { useEffect } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  ConsultationsIcon,
  DocumentsIcon,
  RegulatorIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';

import {
  PageSubTitle,
  PageTitle,
  StyledFormSub,
} from '@src/common-components/headers';
import {
  GridColumnContainer,
  HeaderAdaptive4,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';
import { Product } from '@src/constants';

import { BaseLayout } from '../../layouts/base-layout';

// TODO: изменить на цвет из темы после ее обновления
const bgColor = '#F1F2F6';

export const BestToPaySuccessPage: FC = () => {
  const storedPageSubTitle = localStorage.getItem('successText');
  const storedEmail = localStorage.getItem('email');
  const selectedDate = localStorage.getItem('selectedDate') as string;

  const formatSelectedDate = new Date(JSON.parse(selectedDate))
    .toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .replace('г.', 'года.');

  const pageTitle = 'Ваш полис оформлен';
  const pageSubTitle = (
    <span>
      {`Отправили его вам на ${
        storedEmail ? `${storedEmail}.` : '.'
      } Начнет действовать ${selectedDate ? formatSelectedDate : ''}
      `}
      <br />
      Если что-то случится, свяжитесь с нами в приложении «Пульс. Жизни»
    </span>
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }
  }, []);

  const getFirstCharacter = () => {
    const currentSelectedProduct = localStorage.getItem('selectedProduct');
    if (currentSelectedProduct !== Product.PETS) {
      return 'Изменить, приостановить';
    } else {
      return 'Приостановить';
    }
  };

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={1}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{pageTitle}</PageTitle>
          <PageSubTitle>{pageSubTitle}</PageSubTitle>
        </UniversalColumn>
        <StyledFormSub>
          {isBrowser ? (
            <DisplayQRButton buttonLabel="Скачать приложение" />
          ) : (
            <Button
              adaptiveWidth
              themeColor="primary"
              label="Установить приложение"
              onClick={() =>
                window.open(
                  window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK,
                  '_blank'
                )
              }
            />
          )}
        </StyledFormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderAdaptive4>В приложении вы сможете</HeaderAdaptive4>
      </UniversalColumn>
      <UniversalColumnsWrapper>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <RegulatorIcon />
          <div>{`${getFirstCharacter()} или отменить подписку в любое время`}</div>
        </UniversalColumn>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <ConsultationsIcon />
          <div>Получить консультацию по вашему полису</div>
        </UniversalColumn>
        <UniversalColumn align="left" bgColor={bgColor} gap={16}>
          <DocumentsIcon />
          <div>Получить полезные материалы и документы по страховке</div>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
