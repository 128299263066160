/* eslint-disable indent */
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  HorizontalAlignDirection,
  RadioCardGroup,
  TextAlignDirection,
} from '@pulse-web-ui/radio-card-group';

import { ArrowLeftIcon, FullWidthWrapper } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { Product, productItems } from '@src/constants';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { getProductForAnalytic } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import {
  AuthPageTitle,
  AuthPageWrapper,
  StyledFooter,
} from './authorization-pages.styles';

export const SubscriptionChoice: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    state: {
      stateUser: {
        selectedProduct,
        isSubscriptionExists,
        isPureSubscription,
        cachedProduct,
      },
    },
    dispatch,
  } = useContext(Store);

  const [product, setProduct] = useState<Product | undefined | string>(
    selectedProduct || cachedProduct
  );

  const goBack = () => {
    if (isSubscriptionExists !== undefined) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (product) {
      dispatch({
        type: UserActionTypes.SetSelectedProduct,
        payload: product as Product,
      });

      dispatch({
        type: UserActionTypes.SetCachedProduct,
        payload: product as Product,
      });

      dispatch({
        type: AuthActionTypes.SetAuthorizeRefRoute,
        payload: `/${product}`,
      });
    }
  }, [product]);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.webProducts);

    if (isSubscriptionExists === undefined && !isPureSubscription) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'selectedProduct',
      selectedProduct ?? (product as string)
    );
  }, [product, selectedProduct]);

  const submitPage = () => {
    sendAnalyticEvent(analyticEvents.webProductsSelected, {
      product: getProductForAnalytic(selectedProduct),
    });

    if (isSubscriptionExists) {
      navigate('/new-login');
    } else {
      navigate('/personal-info');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(e.target.value);
  };

  const footer = useMemo(
    () => (
      <StyledFooter>
        <Button themeColor="gray" variant="circle" onClick={goBack}>
          <ArrowLeftIcon />
        </Button>
        <Button
          themeColor="primary"
          disabled={selectedProduct === undefined}
          label="Продолжить"
          onClick={submitPage}
        />
      </StyledFooter>
    ),
    [selectedProduct]
  );

  return (
    <BaseLayout verticalAlignment={false}>
      {isSubscriptionExists !== undefined && (
        <AuthPageWrapper>
          <AuthPageTitle>Какая подписка вас интересует</AuthPageTitle>
          <FullWidthWrapper>
            <RadioCardGroup
              value={selectedProduct}
              name="isSubscriptionCardGroup"
              onChange={handleChange}
              items={productItems}
              textAlign={TextAlignDirection.LEFT}
              horizontalAlign={HorizontalAlignDirection.CENTER}
            />
          </FullWidthWrapper>
          <FullWidthWrapper>{footer}</FullWidthWrapper>
        </AuthPageWrapper>
      )}
    </BaseLayout>
  );
};
