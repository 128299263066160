import styled from 'styled-components';

import { Checkbox } from '@pulse-web-ui/checkbox';
import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

import { CustomButtonProps } from '@src/components/form-elements/form-elements.types';

export const InsurancePersonFormHead = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'space-between',
    wrap: false,
  })}
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const InsurancePersonFormContent = styled.div``;

export const InsurancePersonFormContainer = styled.div`
  margin-bottom: 32px;

  ${media.desktop} {
    margin-bottom: 48px;
  }
`;

export const InsurancePersonFormTitleBox = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    wrap: false,
  })}
`;

export const InsurancePersonFormTitle = styled.div`
  ${typography.pulsSubhead3.bold}
  color: ${colors.darkBlue2};
  margin-left: 8px;

  ${media.desktop} {
    margin-left: 12px;
  }
`;

export const InsurancePersonFormSubTitle = styled.div`
  ${typography.pulsSubhead4.regular}
  color: ${colors.darkGray};
  margin-left: 8px;

  ${media.desktop} {
    margin-left: 12px;
  }
`;

export const InsurancePersonFormCheckbox = styled(Checkbox)`
  padding: 3px 0 20px 4px;

  ${media.desktop} {
    order: 1;
    padding: 0 0 10px 4px;
  }
`;

export const InsurancePersonFormNumber = styled.div`
  ${typography.pulsSubhead4.regular}
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'center',
  })}
  color: ${colors.white};
  width: 20px;
  height: 20px;
  background: ${colors.primary};
  border-radius: 50%;

  ${media.desktop} {
    width: 24px;
    height: 24px;
  }
`;

export const InsurancePersonFormButton = styled.button<CustomButtonProps>`
  ${typography.regular16}
  background: ${(props) => (props.active ? colors.active : 'none')};
  padding-bottom: 3px;
  width: 63px;
  height: 28px;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: 1px solid;
  border-radius: 16px;
  border-color: ${(props) => (props.active ? colors.active : colors.darkGray)};
  letter-spacing: 0.02em;
  color: ${(props) => (props.active ? colors.white : colors.darkGray)};
`;
