import { FC, useLayoutEffect } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  PageBackButton,
  PageBackButtonText,
  StyledButton,
} from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers';
import { Skeleton } from '@src/components';
import { ArrowLeftIcon2 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo, Otp } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import type { Status } from '@src/types';

const expirePeriod = 30;

export const NewAuthorizationPage: FC = () => {
  const {
    state: {
      stateAuth: {
        confirmationId,
        displayPhoneNumber,
        authenticationToken,
        phoneNumber,
        registrationToken,
        authTokens,
      },
      stateUser: {
        userRegistrationData,
        selectedProduct,
        regUtm,
        isSubscriptionExists,
        isPureSubscription,
        cachedPhoneNumber,
      },
    },
    dispatch,
  } = useContext(Store);

  const [smsCode, setSmsCode] = useState<string>('');
  const [expiredTime, setExpiredTime] = useState<number>(
    Date.now() / 1000 + expirePeriod
  );

  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'authRequest',
    'post',
    '/v1/user/confirm-authentication',
    {
      code: smsCode,
      confirmationId: confirmationId,
    },
    [phoneNumber, cachedPhoneNumber, confirmationId],
    true,
    authenticationToken
  );

  const {
    isLoading: isResendLoading,
    error: resendError,
    res: resendRes,
    refetch: resendRefetch,
  } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber,
      },
    },
    [phoneNumber],
    true
  );

  const {
    isLoading: isRegistrationLoading,
    error: registrationError,
    res: registrationRes,
    refetch: registrationRefetch,
  } = useRequest(
    'registrationRequest',
    'post',
    '/v1/user/registration',
    {
      user: {
        ...userRegistrationData,
      },
    },
    [userRegistrationData],
    true,
    registrationToken
  );

  const registrationResponseError =
    registrationError && (registrationError as unknown as any);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.requestNewOtp);

    // анкеты на нашем сайте
    if (!isPureSubscription) {
      if (isSubscriptionExists === undefined) {
        navigate('/');
      } else if (!selectedProduct) {
        navigate('/subscription-choice');
      } else if (!userRegistrationData && isSubscriptionExists === false) {
        navigate('/personal-info');
      } else if (!phoneNumber) {
        navigate('/new-login');
      }
    } else {
      if (!userRegistrationData) {
        navigate('/personal-info');
      } else if (!phoneNumber) {
        navigate('/new-login');
      }
    }
  }, []);

  useEffect(() => {
    if (!isRegistrationLoading && registrationRes) {
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: registrationRes,
      });
      sendAnalyticEvent(analyticEvents.signupCompleted, {
        registration: 'y',
      });
      if (smsCode) {
        navigate(selectedProduct ? `/${selectedProduct}` : '/');
      }
    }
  }, [isRegistrationLoading, registrationRes]);

  useEffect(() => {
    if (
      registrationError &&
      registrationResponseError?.code !== 'ERR_NETWORK'
    ) {
      const e = (registrationError as any)?.response?.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Что-то пошло не так',
            subtitle: 'Попробуйте зарегистрироваться еще раз',
            refRoute: '/personal-info',
          },
        });
      } else {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: 'Ошибка отправки',
            subtitle:
              'Попробуйте зарегистрироваться еще раз или напишите нам в поддержку',
            refRoute: '/personal-info',
          },
        });
      }

      navigate('/new-authorize-fail');
    }
  }, [registrationError]);

  useEffect(() => {
    if (!isResendLoading && resendRes) {
      setExpiredTime(Date.now() / 1000 + expirePeriod);
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: resendRes?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: resendRes?.authenticationToken,
      });
    }
  }, [resendRes]);

  useEffect(() => {
    if (!isLoading && res) {
      const { authorizationToken: aT, registrationToken: rT } = res;

      if (aT) {
        dispatch({
          type: AuthActionTypes.SetAuthorizationToken,
          payload: aT,
        });
      }
      if (rT) {
        dispatch({
          type: AuthActionTypes.SetRegistrationToken,
          payload: rT,
        });
      }

      sendAnalyticEvent(analyticEvents.enterOtpSuccess);
    }
  }, [isLoading, res]);

  const submitPage = () => {
    refetch().then(({ status, data }) => {
      if (data?.data.authorizationToken && status === 'success') {
        navigate('/new-authorize-confirm');
      }
      if (data?.data.registrationToken && status === 'success') {
        dispatch({
          type: AuthActionTypes.SetRegistrationToken,
          payload: data?.data.registrationToken,
        });

        if (!userRegistrationData) {
          dispatch({
            type: AuthActionTypes.SetAuthorizeFailState,
            payload: {
              title: 'Не узнаем вас',
              subtitle:
                'Попробуйте зарегистрироваться еще раз или напишите нам в поддержку',
              refRoute: '/personal-info',
            },
          });

          navigate('/new-authorize-fail');
        }
      }
    });
  };

  const handleOnChange = (e: string) => {
    setSmsCode(e);
  };

  const goBack = () => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });

    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetAuthenticationRefetchUid,
      payload: uuidv4(),
    });

    navigate('/new-login');
  };

  const status: Status = {
    status: error && (error as any)?.code ? 'error' : 'default',
    message: error && (error as any)?.code ? 'Неверный код' : '', // TODO: очищать ошибку когда снова начинают набирать и сделать разеый текст для разных ошибок
  };

  useEffect(() => {
    if (error) {
      sendAnalyticEvent(analyticEvents.enterOtpFail);

      const e = (error as any).response.status;

      if (e === 400) {
        if ((error as any).response.data.code === 'RATE_LIMIT_EXCEEDED') {
          dispatch({
            type: AuthActionTypes.SetConfirmationId,
            payload: undefined,
          });

          dispatch({
            type: AuthActionTypes.SetAuthenticationToken,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetCachedPhoneNumber,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetAuthenticationRefetchUid,
            payload: uuidv4(),
          });

          dispatch({
            type: AuthActionTypes.SetAuthorizeFailState,
            payload: {
              title: 'Возьмите паузу',
              subtitle:
                'Превышено количество запросов, попробуйте позже или обратитесь в поддержку',
              refRoute: '/new-login',
            },
          });

          navigate('/new-authorize-fail');
        }

        if ((error as any).response.data.code === 'UNKNOWN_USER_OR_CODE') {
          sendAnalyticEvent(analyticEvents.enterOtpFail);
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (resendError) {
      const e = (resendError as any).response.status;
      const isRateLimitExceededError =
        (resendError as any).response.data.code === 'RATE_LIMIT_EXCEEDED';

      if (e === 400) {
        const title = isRateLimitExceededError
          ? 'Возьмите паузу'
          : 'Что-то пошло не так';

        const subtitle = isRateLimitExceededError
          ? 'Превышено количество запросов, попробуйте позже или обратитесь в поддержку'
          : 'Попробуйте зарегистрироваться еще раз или напишите нам в поддержку';

        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetCachedPhoneNumber,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetAuthenticationRefetchUid,
          payload: uuidv4(),
        });

        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title,
            subtitle,
            refRoute: '/new-login',
          },
        });
        navigate('/new-authorize-fail');
      }
    }
  }, [resendError]);

  useEffect(() => {
    if (!authTokens && registrationToken && userRegistrationData) {
      registrationRefetch();
    }
  }, [authTokens, registrationToken, userRegistrationData]);

  if (registrationResponseError?.code === 'ERR_NETWORK') {
    return (
      <GlobalErrorInfo
        pending={isRegistrationLoading}
        retrayHandler={registrationRefetch}
      />
    );
  }

  if (isLoading || isResendLoading || isRegistrationLoading) {
    return <Skeleton />;
  }

  return (
    <BaseLayout>
      {phoneNumber && !isLoading && !isResendLoading && !isRegistrationLoading && (
        <PageContent>
          <PageBackButton onClick={goBack}>
            <ArrowLeftIcon2 />
            <PageBackButtonText>Назад</PageBackButtonText>
          </PageBackButton>
          <PageTitle>Введите код</PageTitle>
          <PageSubTitle>
            Мы отправили SMS на номер {displayPhoneNumber}
          </PageSubTitle>
          <Otp
            style={{ marginBottom: 32 }}
            gridStep={24}
            alignment={'flex-start'}
            onSubmit={handleOnChange}
            limit={5}
            onResend={() => resendRefetch()}
            expiryTimestamp={expiredTime}
            status={status}
            isExpiryTimestampText
          />
          <StyledButton
            disabled={!smsCode || smsCode?.length !== 5}
            themeColor="primary"
            label="Продолжить"
            onClick={submitPage}
          />
        </PageContent>
      )}
    </BaseLayout>
  );
};
