/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';

import {
  Container,
  FormLabel,
  FormSubTitle,
  InfoWrapper,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  WarnigText,
  WarnigWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  DEFAULT_FIAS_ID,
  analyticEvents,
  durationDescription,
  formPeriodLabels,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest, useRequests } from '@src/hooks';
import { subObjectConverter } from '@src/pages/ifl-form/utils';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { IFLFlatActionTypes } from '@src/store/ifl-flat';
import { WizardActionTypes } from '@src/store/wizard';
import {
  FormPromoProps,
  OrderItemType,
  OrderItemValueType,
  SelectedDuration,
} from '@src/types';
import {
  currencyRuLocaleWithTwoFractionDigits,
  getAdditionalRisksToString,
  getMainRisksToString,
  numFormat,
  setPaymentPageTexts,
} from '@src/utils';

import {
  formInsurancePeriodDefaultValues,
  formInsurancePeriodSchema,
} from '../../schemas';
import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-insurance-period.styles';
import { useIflOrderFlatData } from './hooks';

export const FormInsurancePeriod: FC = () => {
  const {
    state: {
      stateFormIFLFlat: {
        promoCode,
        risks,
        insuranceSum,
        insuranceSubproducts,
        selectedDuration,
        selectedRegion,
        selectedDate,
        selectedIProduct,
        getPrices,
        dadaValue,
      },
      stateAuth: { authTokens, displayPhoneNumber },
      stateUser: { profile },
    },
    dispatch,
  } = useContext(Store);

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormPromoProps>(
    formInsurancePeriodDefaultValues
  );
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [orderArray, setOrderArray] = useState<OrderItemType[]>([]);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep5Period);
    sendAnalyticEvent(analyticEvents.iflFlatStep5Period);
  }, []);

  const navigate = useNavigate();
  useIflOrderFlatData();

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrder,
      payload: orderArray,
    });
  }, [orderArray]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: { promoCode: promoCode },
  });

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const validatePage = useCallback(() => {
    navigate('/new-order-detail');

    return false;
  }, []);

  useNextStep(validatePage);

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: IFLFlatActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    sendAnalyticEvent(analyticEvents.dateStartRealty);
  };

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: IFLFlatActionTypes.SetSelectedDuration,
      payload: val,
    });

    sendAnalyticEvent(analyticEvents.periodSelectRealty, {
      period: durationDescription[val],
    });
  };

  const {
    isLoading: profileIsLoading,
    error: profileError,
    res: profileRes,
    refetch: profileRefetch,
  } = useRequest(
    'userGetProfile',
    'post',
    '/v1/user/get-profile',
    {},
    // [authTokens?.authorization?.accessToken, profile], // TODO: после тестирования
    [profile],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading,
    isError,
    res: [getPricesData, getInsuranceLimitStartData],
    refetchAll,
  } = useRequests([
    {
      key: 'formIFLGetPricesFull',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        risks: risksArray,
        productCode: selectedIProduct?.code,
        insuranceSum: Number(insuranceSum),
        personProperties: {
          subObjects:
            insuranceSubproducts?.subObjects &&
            subObjectConverter(insuranceSubproducts.subObjects),
          address: {
            regionCode: selectedRegion?.region,
            address: dadaValue?.value || '',
            addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
          },
        },
        returnMinDuration: false,
        promoCode: authTokens?.authorization?.accessToken && promoCode,
      },
      deps: [
        risks,
        insuranceSubproducts,
        promoCode,
        formData.promoCode,
        dadaValue?.value,
        dadaValue?.data?.fias_id,
        authTokens?.authorization?.accessToken,
      ],
      accessToken: authTokens?.authorization?.accessToken,
    },
    {
      key: 'formIFLGetInsuranceLimitStart',
      method: 'post',
      url: '/v1/references/get-insurance-limit-start',
      request: {
        productCode: selectedIProduct?.code,
      },
      deps: [risks],
    },
  ]);

  useEffect(() => {
    if (profileIsLoading || isLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [profileIsLoading, isLoading]);

  useEffect(() => {
    if (!profileIsLoading && profileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: profileRes,
      });

      dispatch({
        type: OrderActionTypes.SetOrderPageTitle,
        payload: 'Пульс. Квартира',
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [profileRes, profileIsLoading]);

  useEffect(() => {
    setPaymentPageTexts(
      'Если с вашей квартирой что-то случится, свяжитесь с нами в приложении «Пульс Жизни»',
      selectedDate
    );

    const selectedPrice = getPrices?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDelta;

    const selectedPromoPrice = getPrices?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0].premiumAndDeltaPromo;

    let label = `${
      selectedPrice
        ? currencyRuLocaleWithTwoFractionDigits(Number(selectedPrice))
        : ''
    }${formPeriodLabels.get(selectedDuration!)?.label}`;
    let subLabel = '';
    const isSuccessfulPromo = getPrices?.isSuccessfulPromo;

    if (!!selectedPromoPrice) {
      label = `${currencyRuLocaleWithTwoFractionDigits(
        Number(selectedPromoPrice)
      )}${formPeriodLabels.get(selectedDuration!)?.promoLabel}`;
      subLabel =
        `${
          formPeriodLabels.get(selectedDuration!)?.promoSubLabel
        } ${selectedPrice} ₽` || '';
    }

    const name = [
      profileRes?.profile?.lastName,
      profileRes?.profile?.firstName,
      profileRes?.profile?.middleName !== ''
        ? profileRes?.profile?.middleName
        : null,
    ].join(' ');

    const tempOrderArray: OrderItemType[] = [
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Стоимость и периодичность оплаты',
        value: label,
        secondsValue: subLabel,
        successValue: isSuccessfulPromo ? 'Промокод применен' : '',
        failedValue:
          isSuccessfulPromo === undefined || isSuccessfulPromo === null
            ? ''
            : 'Промокод не применен',
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Адрес объекта',
        value: String(dadaValue?.value),
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Основные риски',
        value: getMainRisksToString(risks),
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Дополнительные риски',
        value: getAdditionalRisksToString(risks) || 'Не выбраны',
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Стоимость покрытия',
        value: `${numFormat(insuranceSum)} ₽`,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Подписка начнёт действовать',
        value: `с ${selectedDate?.toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}`,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Страхователь',
        value: name,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: 'Номер телефона',
        value: displayPhoneNumber,
      },
    ];

    setOrderArray(tempOrderArray);
  }, [
    getPrices?.prices,
    selectedDuration,
    dadaValue,
    risks,
    insuranceSum,
    selectedDate,
    displayPhoneNumber,
    profileRes,
  ]);

  useEffect(() => {
    if (!isLoading && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: IFLFlatActionTypes.SetGetPrices,
        payload: getPricesData.data,
      });
      if (selectedDuration === null) {
        dispatch({
          type: IFLFlatActionTypes.SetSelectedDuration,
          payload: getPricesData?.data?.prices[0]?.duration,
        });
      }
      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        formData.promoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'Такого промокода нет или он больше не действует',
        });
        dispatch({
          type: IFLFlatActionTypes.SetPromoCode,
          payload: undefined,
        });
      }
    }
  }, [isLoading, getPricesData.data]);

  useEffect(() => {
    if (!isLoading && getInsuranceLimitStartData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      setMinDate(new Date(getInsuranceLimitStartData.data?.startDate));
      setMaxDate(new Date(getInsuranceLimitStartData.data?.endDate));
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate || new Date(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [isLoading, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (formData.promoCode) {
      dispatch({
        type: IFLFlatActionTypes.SetPromoCode,
        payload: formData.promoCode,
      });
      refetchAll();
    }
  }, [formData.promoCode]);

  useEffect(() => {
    setSubmitPromoDisabled(
      getPricesData.data?.isSuccessfulPromo && promoCode !== ''
    );
  }, [getPricesData.data, promoCode]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPage = handleSubmit((data) => {
    dispatch({
      type: IFLFlatActionTypes.SetPromoCode,
      payload: data.promoCode,
    });
    setFormData(data);
  });

  if (profileIsLoading || isLoading) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={refetchAll} />;
  }

  if (profileError) {
    const e = (profileError as any).response.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: 'Ошибка авторизации',
          subtitle: 'Попробуйте зарегистрироваться еще раз',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/new-authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={profileRefetch} />;
  }

  return (
    <Container>
      <FormLabel marginBottom={24}>Периодичность оплаты</FormLabel>
      <ContentWrapper>
        <NewRadioGroup
          name="duration"
          onChange={onRadioSelector}
          selectedValue={selectedDuration}
        >
          {getPrices?.prices &&
            getPrices?.prices.map((item) => (
              <NewRadio
                key={`${item.duration}-key`}
                value={item.duration}
                label={RadioLabelRender(item)}
                description={RadioDescriptionRender(item)}
              />
            ))}
        </NewRadioGroup>
      </ContentWrapper>

      <ContentWrapper>
        {authTokens?.authorization?.accessToken ? (
          <PromoWrapper>
            <PromoTextInput>
              <Controller
                control={control}
                name="promoCode"
                render={({ field: { onChange, value }, fieldState }) => (
                  <HelperText
                    status={fieldState.error ? 'error' : 'success'}
                    message={
                      errors.promoCode?.message ||
                      (getPricesData.data?.isSuccessfulPromo && promoCode !== ''
                        ? 'Скидка на первый период подписки'
                        : '')
                    }
                  >
                    <Input
                      label="Промокод"
                      value={value}
                      onChange={(val) => {
                        checkSubmitState(val);
                        return onChange(val);
                      }}
                      error={!!errors.promoCode}
                      disabled={isLoading}
                    />
                  </HelperText>
                )}
              />
            </PromoTextInput>
            <PromoSubmitButton>
              <Button
                label="Применить"
                onClick={submitPage}
                disabled={submitPromoDisabled}
                themeColor="white"
              />
            </PromoSubmitButton>
          </PromoWrapper>
        ) : (
          <WarnigWrapper>
            <Info width={24} />
            <WarnigText>
              Промокод можно ввести после авторизации на следующем шаге
            </WarnigText>
          </WarnigWrapper>
        )}
      </ContentWrapper>

      <FormSubTitle>Подписка станет активна</FormSubTitle>
      <HelperText message="" status="default" messagePosition="up">
        {isLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} />
            <WarnigText>
              Подписка начнет действовать{' '}
              {minDate.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </HelperText>
    </Container>
  );
};
