/* eslint-disable indent */
import type { FC } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { assignRef, useCallbackRef } from 'use-callback-ref';

import { Skeleton } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  nsRoute,
  petsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { Store } from '@src/store';

import { BaseLayout } from '../../layouts/base-layout';

type ConfirmPaymentType = {
  subscriptionId: string;
  paymentDocumentId: string;
  operationId: string;
  id: string;
  contractId: string;
  amount: string;
  consents: [
    {
      consentType: '001';
    }
  ];
  paymentGateOrder: string;
};

export const BestToPayStartPage: FC = () => {
  const {
    state: {
      stateOrder: { orderInitData, orderSubmitData },
      stateAuth: { authTokens, authorizeRefRoute },
    },
  } = useContext(Store);
  const [isRetry, setRetry] = useState('');
  const refCount = useRef<number>(0);

  useEffect(() => {
    switch (authorizeRefRoute) {
      case iflHouseRoute:
        sendAnalyticEvent(analyticEvents.iflStep9Pay);
        sendAnalyticEvent(analyticEvents.iflHouseStep9Pay);
        break;
      case iflFlatRoute:
        sendAnalyticEvent(analyticEvents.iflStep9Pay);
        sendAnalyticEvent(analyticEvents.iflFlatStep9Pay);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPaymentAccident);
        break;
      default:
        break;
    }
  }, []);

  const onRefRetrydUpdate = (newValue: any) => {
    if (newValue && newValue !== null && refCount.current === 0) {
      refCount.current = 1;
      setRetry(String(newValue));
    }
  };

  const onRefSOperationIdUpdate = (newValue: any) => {
    if (newValue && newValue !== null) {
      completPayment.operationId = newValue;
      refetch();
    }
  };

  const refRetry = useCallbackRef<null | string>(null, onRefRetrydUpdate);

  const refSOperationId = useCallbackRef<null | string>(
    null,
    onRefSOperationIdUpdate
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!orderInitData) {
      navigate('/');
    }
  }, [orderInitData]);

  window.addEventListener('storage', () => {
    if (
      localStorage.getItem('operationId') !== null &&
      refSOperationId.current !== localStorage.getItem('operationId')
    ) {
      switch (authorizeRefRoute) {
        case iflFlatRoute:
          sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
          sendAnalyticEvent(analyticEvents.iflFlatPaymentSuccess);
          break;
        case iflHouseRoute:
          sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
          sendAnalyticEvent(analyticEvents.iflHousePaymentSuccess);
          break;
        case iflRoute:
          sendAnalyticEvent(analyticEvents.paymentSuccessRealty);
          break;
        case nsRoute:
          sendAnalyticEvent(analyticEvents.paymentSuccessAccident);
          break;
        case petsRoute:
          sendAnalyticEvent(analyticEvents.petPaymentSuccess);
          break;
      }

      assignRef(refSOperationId, String(localStorage.getItem('operationId')));
    }

    if (
      localStorage.getItem('retryPayment') !== null &&
      refRetry.current !== String(localStorage.getItem('retryPayment'))
    ) {
      switch (authorizeRefRoute) {
        case iflFlatRoute:
          sendAnalyticEvent(analyticEvents.iflPaymentDecline);
          sendAnalyticEvent(analyticEvents.iflFlatPaymentDecline);
          break;
        case iflHouseRoute:
          sendAnalyticEvent(analyticEvents.iflPaymentDecline);
          sendAnalyticEvent(analyticEvents.iflHousePaymentDecline);
          break;
        case petsRoute:
          sendAnalyticEvent(analyticEvents.petPaymentDecline);
          break;
      }
      assignRef(refRetry, String(localStorage.getItem('retryPayment')));
    }
  });

  const sector = `sector=${orderInitData?.sector}`;
  const id = `id=${orderInitData?.id}`;
  const signature = `signature=${orderInitData?.signature}`;
  const url = `url=${orderInitData?.url}`;
  const failUrl = `failUrl=${orderInitData?.failUrl}`;
  const get_token = 1;

  const completPayment: ConfirmPaymentType = {
    subscriptionId: orderSubmitData?.subscriptionId || '',
    paymentDocumentId: orderSubmitData?.billId || '',
    operationId: '',
    id: orderInitData?.id || '',
    contractId: orderSubmitData?.contractId || '',
    amount: String(orderSubmitData?.premiumAndDelta) || '',
    paymentGateOrder: orderInitData?.paymentGateOrder || '',
    consents: [
      {
        consentType: '001',
      },
    ],
  };

  const { isLoading, error, res, refetch } = useRequest(
    'orderCompletePayment',
    'post',
    '/v1/payment/complete-payment',
    completPayment,
    [],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!isLoading && res) {
      assignRef(refSOperationId, undefined);
      localStorage.removeItem('referenceSuccess');
      localStorage.removeItem('operationId');
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (isRetry !== '') {
      setRetry('');
      refCount.current = 0;
      localStorage.removeItem('retryPayment');
    }
  }, [isRetry]);

  // TODO: доработать на этапе создания страницы выбора карты оплаты
  // const token = undefined;
  // const action = undefined;

  const propsString = [sector, id, signature, url, failUrl, get_token].join(
    '&'
  );

  const route = `Purchase?${propsString}`;

  if (error) return <GlobalErrorInfo retrayHandler={refetch} />;

  return (
    <BaseLayout>
      <Skeleton />
      {isRetry === '' ? (
        <iframe
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          src={`${window.envUrls.BEST2PAY_API_URL}/${route}`}
          allow="payment"
          sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
        />
      ) : null}
    </BaseLayout>
  );
};
