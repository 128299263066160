/* eslint-disable quotes */
import * as yup from 'yup';

import { nameRegEx, notDigital, phoneRegEx, promoEx } from '@src/constants';
import { FormInsuranceContactsProps, FormPromoProps } from '@src/types';
import { calculateAge } from '@src/utils';

export const profileFormSchema = yup.object({
  firstName: yup
    .string()
    .required('Укажите имя')
    .test(
      'len',
      'Максимальная длина 50 символов',
      (value) => !!value?.length && value.length < 50
    )
    .test(
      'validFirstLetter',
      'Имя должно начинаться не с цифры',
      (value) => !!value && notDigital.test(value)
    )
    .test(
      'validFirstName',
      'Используйте кириллицу',
      (value) => !!value && nameRegEx.test(value)
    )
    .test(
      'containSpaces',
      'Имя не должно содержать пробелов',
      (value) => !value?.includes(' ')
    ),
  lastName: yup
    .string()
    .required('Укажите фамилию')
    .test(
      'lenLn',
      'Максимальная длина 50 символов',
      (value) => !!value?.length && value.length < 50
    )
    .test(
      'validFirstLetterLN',
      'Фамилия должна начинаться не с цифры',
      (value) => !!value && notDigital.test(value)
    )
    .test(
      'validLasttName',
      'Используйте кириллицу',
      (value) => !!value && nameRegEx.test(value)
    )
    .test(
      'containSpacesLn',
      'Фамилия не должна содержать пробелов',
      (value) => !value?.includes(' ')
    ),
  middleName: yup.string().when('useMiddleName', {
    is: (useMiddleName: boolean) => !useMiddleName,
    then: yup
      .string()
      .required('Укажите отчество')
      .test(
        'lenMn',
        'Максимальная длина 50 символов',
        (value) => !!value?.length && value.length < 50
      )
      .test(
        'validFirstLetterMN',
        'Отчество должно начинаться не с цифры',
        (value) => !!value && notDigital.test(value)
      )
      .test(
        'validMidName',
        'Используйте кириллицу',
        (value) => !!value && nameRegEx.test(value)
      )
      .test(
        'containSpacesMd',
        'Отчество не должно содержать пробелов',
        (value) => !value?.includes(' ')
      ),
  }),
  useMiddleName: yup.bool(),
});

export const formInsuranceContactsSchema = profileFormSchema.shape({
  email: yup
    .string()
    .nullable()
    .email('Некорректный адрес электронной почты')
    .required('Укажите электронную почту'),
});

export const formInsuranceContactsDefaultValues: FormInsuranceContactsProps = {
  lastName: '',
  firstName: '',
  middleName: '',
  useMiddleName: false,
  email: '',
  birthDate: '',
};

export const formInsurancePeriodSchema = yup.object().shape({
  promoCode: yup
    .string()
    .nullable()
    .test('validPromo', 'Неверный промокод', (value) => {
      return !value?.length || promoEx.test(value);
    }),
});

export const formInsurancePeriodDefaultValues: FormPromoProps = {
  promoCode: '',
};

export const personSchema = profileFormSchema.shape({
  ageMin: yup.number().required(),
  birthDate: yup.string().required('Укажите дату рождения'),
  isMe: yup.boolean(),
});

export const personsFormSchema = yup.object({
  persons: yup.array(personSchema).required(),
});

export const registratiomFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Укажите имя')
    .test(
      'len',
      'Максимальная длина 50 символов',
      (value) => value!.length < 50
    )
    .test('validFirstLetter', 'Имя должно начинаться не с цифры', (value) =>
      notDigital.test(value!)
    )
    .test('validFirstName', 'Используйте кириллицу', (value) =>
      nameRegEx.test(value!)
    )
    .test(
      'containSpaces',
      'Имя не должно содержать пробелов',
      (value) => !value!.includes(' ')
    ),
  lastName: yup
    .string()
    .required('Укажите фамилию')
    .test(
      'lenLn',
      'Максимальная длина 50 символов',
      (value) => value!.length < 50
    )
    .test('validFirstLetterLN', 'Имя должно начинаться не с цифры', (value) =>
      notDigital.test(value!)
    )
    .test('validLasttName', 'Используйте кириллицу', (value) =>
      nameRegEx.test(value!)
    )
    .test(
      'containSpacesLn',
      'Фамилия не должна содержать пробелов',
      (value) => !value!.includes(' ')
    ),
  middleName: yup.string().when('useMiddleName', {
    is: (useMiddleName: boolean) => !useMiddleName,
    then: yup
      .string()
      .required('Укажите отчество')
      .test(
        'lenMn',
        'Максимальная длина 50 символов',
        (value) => value!.length < 50
      )
      .test('validFirstLetterMN', 'Имя должно начинаться не с цифры', (value) =>
        notDigital.test(value!)
      )
      .test('validMidName', 'Используйте кириллицу', (value) =>
        nameRegEx.test(value!)
      )
      .test(
        'containSpacesMd',
        'Отчество не должно содержать пробелов',
        (value) => !value!.includes(' ')
      ),
  }),
  userPoliciesCode002: yup.bool(),
  userPoliciesCode003: yup
    .bool()
    .oneOf([true], 'Без этого согласия не можем создать аккаунт'),
  useMiddleName: yup.bool(),
});

export const formOrderDetailSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email('Некорректный адрес электронной почты')
    .required('Укажите электронную почту'),
});

const lastNameSchema = yup
  .string()
  .required('Поле не заполнено')
  .test(
    'onlyChar',
    'Фамилия должна начинаться с буквы',
    (val) => !val?.startsWith('-')
  )
  .test(
    'onlyChar',
    'Фамилия должна начинаться с буквы',
    (val) => !val?.startsWith("'")
  )
  .test(
    'lenLn',
    'Максимальная длина 50 символов',
    (value) => value!.length < 50
  )
  .test('validFirstLetterLN', 'Фамилия должно начинаться не с цифры', (value) =>
    notDigital.test(value!)
  )
  .test('validLastName', 'Используйте кириллицу', (value) =>
    nameRegEx.test(value!)
  )
  .test(
    'containSpacesLn',
    'Фамилия не должна содержать пробелов',
    (value) => !value!.includes(' ')
  );

export const clientPersonalInfoSchema = yup.object().shape({
  lastName: lastNameSchema,
  firstName: yup
    .string()
    .required('Поле не заполнено')
    .test(
      'onlyChar',
      'Имя должно начинаться с буквы',
      (val) => !val?.startsWith('-')
    )
    .test(
      'onlyChar',
      'Имя должна начинаться с буквы',
      (val) => !val?.startsWith("'")
    )
    .test(
      'len',
      'Максимальная длина 50 символов',
      (value) => value!.length < 50
    )
    .test('validFirstLetter', 'Имя должно начинаться не с цифры', (value) =>
      notDigital.test(value!)
    )
    .test('validFirstName', 'Используйте кириллицу', (value) =>
      nameRegEx.test(value!)
    )
    .test(
      'containSpaces',
      'Имя не должно содержать пробелов',
      (value) => !value!.includes(' ')
    ),
  birthDay: yup
    .string()
    .required('Поле не заполнено')
    .nullable(true)
    .test(
      'notEdult',
      'Страховку можно оформить только с 18 лет',
      (value) => calculateAge(new Date(value!)) >= 18
    ),
  middleName: yup.string().when('useMiddleName', {
    is: (useMiddleName: boolean) => !useMiddleName,
    then: yup
      .string()
      .required('Поле не заполнено')
      .test(
        'lenMn',
        'Максимальная длина 50 символов',
        (value) => value!.length < 50
      )
      .test(
        'validFirstLetterMN',
        'Отчество должно начинаться не с цифры',
        (value) => notDigital.test(value!)
      )
      .test(
        'onlyChar',
        'Отчество должно начинаться с буквы',
        (val) => !val?.startsWith('-')
      )
      .test(
        'onlyChar',
        'Отчество должна начинаться с буквы',
        (val) => !val?.startsWith("'")
      )
      .test('validMidName', 'Используйте кириллицу', (value) =>
        nameRegEx.test(value!)
      )
      .test(
        'containSpacesMd',
        'Отчество не должно содержать пробелов',
        (value) => !value!.includes(' ')
      ),
  }),
  userPoliciesCode002: yup.bool(),
  userPoliciesCode003: yup
    .bool()
    .oneOf([true], 'Без этого согласия не можем создать аккаунт'),
  useMiddleName: yup.bool(),
});

export const identificationPageSchema = yup.object().shape({
  phoneNumber: yup
    .mixed()
    .required('Введите номер телефона')
    .test('validPhone', 'Неверный номер телефона', (value) =>
      phoneRegEx.test(value)
    ),
});

export const lastNameFormSchema = yup.object({
  lastName: lastNameSchema,
});
