import React, { FC } from 'react';

import { Button } from '@pulse-web-ui/button';

import { ScoreErrorIcon } from './score-error-icon';
import {
  StyledBox,
  StyledButtonBox,
  StyledErrorSubText,
  StyledText,
} from './score-error.styles';

interface ErrorPageProps {
  isObjectDataError?: boolean;
}

export const ScoreErrorContent: FC<ErrorPageProps> = ({
  isObjectDataError,
}) => {
  const errorText = 'К сожалению, оформление подписки для вас недоступно';
  const buttonText = 'Вернуться на главную';
  const handleClick = () => {
    const globalWindow = window.top as Window;
    return (globalWindow.location.href = 'https://pulse.insure/');
  };

  const objectErrorText = 'Не можем оформить подписку по выбранному адресу';
  const objectErrorSubText = 'Так как дом построен ранее 1950 года';
  //TODO: изменить текст в StyledErrorSubText. Сейчас временно убрали
  return (
    <StyledBox>
      <ScoreErrorIcon />
      <StyledText>{isObjectDataError ? objectErrorText : errorText}</StyledText>
      {/* {isObjectDataError && (
        <StyledErrorSubText>{objectErrorSubText}</StyledErrorSubText>
      )} */}
      <StyledButtonBox isObjectDataError={isObjectDataError}>
        <Button onClick={handleClick} themeColor="primary">
          {buttonText}
        </Button>
      </StyledButtonBox>
    </StyledBox>
  );
};
