import styled, { css } from 'styled-components';

import { colors, typography } from '@pulse-web-ui/theme';

export const Resend = styled.div`
  text-align: center;
  ${typography.regular12};
`;

export const ResentText = styled.div<{
  disabled?: boolean;
  seconds: number;
}>`
  width: 100%;
  ${typography.regular16};
  color: ${colors.tertiary};
  text-align: left;

  cursor: pointer;
  color: ${colors.darkGray};
  ${({ seconds }) =>
    !(seconds > 0) &&
    css`
      color: ${colors.tertiary};
    `}
`;
