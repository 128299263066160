import styled from 'styled-components';

import { media } from '@pulse-web-ui/theme';

export const AppContent = styled.div`
  padding: 0 16px;
  box-sizing: border-box;

  ${media.desktop} {
    padding: 0;
  }
`;

export const PageContent = styled.div`
  padding-top: 0;
`;
