import Axios from 'axios';
import { useQuery } from 'react-query';

import { DADATA_API_URL } from '@src/constants';
import type { Method, Url } from '@src/types';

export const useDadataRequest = <T>(
  key: string,
  method: Method,
  url: Url,
  request?: T,
  deps?: any[]
) => {
  const { isLoading, error, data, refetch } = useQuery([key, deps], () =>
    Axios[method](`${DADATA_API_URL}${url}`, request, {
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${window.envKeys.DADATA_KEY}`,
      },
    })
  );

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return { isLoading, error, res, refetch };
};
