import styled from 'styled-components';

import { Button } from '@pulse-web-ui/button';
import { colors, media, mixins, typography } from '@pulse-web-ui/theme';

export const StyledButton = styled(Button)`
  ${media.desktop} {
    width: auto;
  }
  width: 100%;
  box-sizing: border-box;
`;

export const PageBackButton = styled.div`
  ${mixins.flex({ inline: true, verticalAlignment: 'center' })};
  margin-bottom: 21px;
  cursor: pointer;
`;

export const PageBackButtonText = styled.span`
  ${typography.regular16};
  color: ${colors.secondaryGray2};
  margin-left: 9px;
`;
